import { COLORS } from '@shared/constants/colors';
import { red } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

// Create a theme instance.
const theme = createTheme({
    palette: {
        primary: {
            main: '#556cd6',
        },
        secondary: {
            main: '#19857b',
        },
        error: {
            main: red.A400,
        },
    },
    typography: {
        fontSize: 14,
        allVariants: {
            color: COLORS.Primary
        },
        fontFamily: "SFProDisplay-Regular"
    },
    components: {
        MuiCssBaseline: {
            styleOverrides: {
                body: {
                    scrollbarColor: COLORS.Gray,
                    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
                        backgroundColor: COLORS.Gray,
                        width: '8px',
                        borderRadius: '5px',
                        height: '8px'
                    },
                    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
                        borderRadius: 3,
                        backgroundColor: COLORS.YankeesBlue,
                        minHeight: 24,
                        border: "1px solid #2b2b2b",
                    },
                    "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus": {
                        backgroundColor: COLORS.Gray,
                    },
                    "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active": {
                        backgroundColor: COLORS.Gray,
                    },
                    "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover": {
                        backgroundColor: COLORS.Gray,
                    },
                    "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
                        backgroundColor: COLORS.Gray,
                    },
                },
            },
        },
    },
});

export default theme;

