import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { COLORS } from '@shared/constants/colors';

const StyledSelect = styled(Select, { shouldForwardProp: (prop) => prop !== 'disabled' })({
    height: 40,
    // boxShadow: 'none', '.MuiOutlinedInput-notchedOutline': { border: 0 },
    '& .MuiInputBase-input': {
        backgroundColor: COLORS.White,
        borderRadius: 8,
        position: 'relative',
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '8px 16px',
        outline: 'none',
        borderColor: COLORS.LightSilver,
        '&:hover': {
            borderColor: COLORS.Gray,
        },
        '&:focus': {
            borderColor: COLORS.Gray,
        },
    },
})

export const HSelect = ({ children, ...props }: any) => {
    return (
        <StyledSelect disableUnderline {...props}>
            {children}
        </StyledSelect>
    )

}