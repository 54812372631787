import { ChipStatus } from "@components/ChipStatus";
import { FormControl, Grid, SvgIcon, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import { Column, PaginatedResult } from "types/common";
import { ReactComponent as ExclamationIcon } from '@assets/icons/icon-exclamation.svg';
import { rowCustomHandlerType } from "@components/Table/constant";
import { LabelControl, Title } from "@shared/styledComponents";
import { Input } from "@components/Input";
import { HDatePicker } from "@components/DateTimePicker";
import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch } from "react-redux";
import { HTable } from "@components/Table";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { apiClient } from "@services/baseAxios";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { ApiConfig } from "@config/index";
import { errorMessages } from "@shared/constants/messages";
import { CUSTOMER_FEEDBACK_STATUS_MAPPING, customerFeedbackStatus } from "@shared/constants/common";
import { ComplainDetailForm } from "./compainDetailForm";
import { HDialog } from "@components/Dialog";
import { HButton } from "@components/Button";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const Wrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    padding: "16px 0px"
}));

const getChipType = (data: string) => {
    switch (data) {
        case customerFeedbackStatus.CREATED:
            return 1;
        case customerFeedbackStatus.PROCESSING:
            return 4;
        case customerFeedbackStatus.PROCESSED:
            return 2;
    }
}

const columns: readonly Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    {
        id: 'CustomerName',
        label: 'Tên khách hàng',
        minWidth: 130,
        transform: (data: any) => (<div style={{ fontWeight: 'bold' }}>{data.value}</div>)
    },
    {
        id: 'CustomerPhoneNumber',
        label: 'Số điện thoại',
        minWidth: 120,
        align: 'left',
    },
    {
        id: 'Title',
        label: 'Tiêu đề',
        align: 'left',
        minWidth: 120,
        // transform: (data: any) => (data.value ? <span style={{ color: COLORS.Tertiary }}>{data.value}</span> : 'Standard')
    },
    {
        id: 'Status',
        label: 'Trạng thái',
        align: 'left',
        minWidth: 110,
        transform: (data: any) => (data.value ? <ChipStatus type={getChipType(data.value)} text={CUSTOMER_FEEDBACK_STATUS_MAPPING[data.value]} /> : '---'),
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (
            <div className="flex justify-center items-center">
                {
                    value.row.Status !== customerFeedbackStatus.PROCESSED &&
                    <> <Tooltip title="Xác nhận xử lý khiếu nại" placement="left">
                        <div onClick={() => value.onCustomHandler(rowCustomHandlerType.CONFIRM)} className='flex justify-center items-center cursor-pointer'>
                            <DoneOutlinedIcon />
                        </div>
                    </Tooltip></>
                }
                <Tooltip title="Chi tiết khiếu nại" placement="top" >
                    <div onClick={() => value.onCustomHandler(rowCustomHandlerType.DETAIL)} className='flex justify-center items-center cursor-pointer'>
                        <SvgIcon sx={{ width: 16, height: 16, color: COLORS.Green }} component={ExclamationIcon} inheritViewBox />
                    </div>
                </Tooltip>
            </div >
        )
    },
];

export const ComplainAdministration = () => {
    const dispatch = useDispatch();

    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
    const [keyword, setKeyword] = useState('');
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState({} as any);
    const [page, setPage] = useState(0);
    const [isOpenDetail, setIsOpenDetail] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);

    const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getObjectFilter = () => {
        var fromDateVNTime = dayjs(fromDate).add(7, 'hours') || null;
        var toDateVNTime = dayjs(toDate).add(7, 'hours') || null;

        return {
            Keyword: keyword,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            StartTime: fromDateVNTime,
            EndTime: toDateVNTime,
        };
    };

    const getCustomerComplainPaging = async () => {
        try {
            setTableLoading(true);
            var { AppCode, Data } = await apiClient.post(`${ApiConfig.CUSTOMER_FEEDBACK}/paging`, getObjectFilter());
            setTableLoading(false);
            if (AppCode === 200) {
                setPaginatedData((prevState: PaginatedResult) => ({
                    ...prevState,
                    ...Data
                }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
        catch (e) {
            setTableLoading(false);
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const onRowCustomHandler = (row: any, handlerType: any) => {
        setSelectedRow(row);
        if (handlerType === rowCustomHandlerType.DETAIL) {
            setIsOpenDetail(true);
        }
        else if (handlerType === rowCustomHandlerType.CONFIRM) {
            setIsOpenConfirmDialog(true);
        }
    }

    const handleCloseForm = () => {
        setIsOpenDetail(false);
    }

    const closeConfirmDialog = () => {
        setIsOpenConfirmDialog(false);
    }

    const confirmFeedbackProcessing = async () => {
        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.put(`${ApiConfig.CUSTOMER_FEEDBACK}/${selectedRow.Id}/confirmation`);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                closeConfirmDialog();
                dispatch(storeOpenSnackbar({ open: true, message: "Xác nhận xử lý khiếu nại thành công", type: "success" }));
                getCustomerComplainPaging();
            }
            else if (AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: "Xác nhận xử lý khiếu nại thất bại", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Xác nhận xử lý khiếu nại thất bại", type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    useEffect(() => {
        getCustomerComplainPaging();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, keyword, fromDate, toDate])

    return (
        <Wrapper>
            <Title>Danh sách khiếu nại</Title>
            <ToolbarWrapper>
                <Grid container>
                    <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Số điện thoại hoặc tên khách hàng</LabelControl>
                            <Input onChange={handleChangeKeyword} value={keyword} fullWidth placeholder="Tìm kiếm theo số điện thoại hoặc tên khách hàng" />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Từ ngày</LabelControl>
                            <HDatePicker
                                value={fromDate}
                                ampm={false}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue: any) => {
                                    setFromDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Đến ngày</LabelControl>
                            <HDatePicker
                                value={toDate}
                                placeholder="Từ ngày"
                                inputFormat="DD/MM/YYYY"
                                ampm={false}
                                onChange={(newValue: any) => {
                                    setToDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                </Grid>
            </ToolbarWrapper>
            <HTable
                columns={columns}
                pagingInfo={{
                    rowsPerPage,
                    page,
                    totalCount: paginatedData.TotalCount,
                    handleChangePage,
                    handleChangeRowsPerPage
                }}
                loading={tableLoading}
                listData={paginatedData.ListOut}
                onRowCustomHandler={onRowCustomHandler}
            ></HTable>
            {
                isOpenDetail &&
                <ComplainDetailForm
                    isOpen={isOpenDetail}
                    title={"Chi tiết khiếu nại"}
                    data={selectedRow}
                    handleOnClose={handleCloseForm}
                >
                </ComplainDetailForm>
            }
            <HDialog
                PaperProps={{
                    sx: {
                        width: "450px",
                    }
                }}
                title="Xác nhận"
                open={isOpenConfirmDialog}
                handleClose={closeConfirmDialog}
                dialogFooter={
                    <>
                        <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeConfirmDialog}>Hủy</HButton>
                        <HButton startIcon={<DoneOutlinedIcon />} onClick={confirmFeedbackProcessing}>Xác nhận</HButton>
                    </>
                }
            >
                {
                    selectedRow.Status === customerFeedbackStatus.CREATED ?
                        "Bạn chắc chắn xác nhận muốn xử lý khiếu nại này?" :
                        "Bạn chắc chắn xác nhận đã xử lý khiếu nại này?"
                }
            </HDialog>
        </Wrapper>
    )
}