import { Input } from "@components/Input";
import { CustomRadio } from "@components/Radio";
import { HSelect } from "@components/Select";
import { HTable } from "@components/Table";
import { ApiConfig } from "@config/index";
import { FormControl, FormControlLabel, Grid, MenuItem, RadioGroup } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiClient } from "@services/baseAxios";
import { COLORS } from "@shared/constants/colors";
import { LabelControl, RadioLabel, Title } from "@shared/styledComponents";
import { Column, PaginatedResult } from "types/common";
import { useEffect, useState } from "react";
import moment from "moment";
import { ReactComponent as EditIcon } from '@assets/icons/icon-edit.svg';
import SvgIcon from '@mui/icons-material/Menu';
import { useNavigate } from "react-router-dom";
import { bookingStatus } from "@shared/constants/common";
import { ColumnAction } from "@components/Table/ColumnAction";

const BookingWrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    padding: "16px 0px"
}));

const columns: readonly Column[] = [
    {
        id: 'ServiceName',
        label: 'Tên liệu trình',
        align: 'left',
        minWidth: 180
    },
    {
        id: 'CustomerName',
        label: 'Họ tên khách hàng',
        minWidth: 160
    },
    {
        id: 'CustomerPhone',
        label: 'Số điện thoại',
        minWidth: 120,
        align: 'left',
    },
    {
        id: 'MemberShipClass',
        label: 'Hạng',
        minWidth: 50,
        align: 'left',
        transform: (data: any) => (data.value ? <span style={{ color: COLORS.Tertiary }}>{data.value}</span> : 'Standard')
    },
    {
        id: 'LocationAddress',
        label: 'Cơ sở',
        align: 'left',
        minWidth: 120
    },
    {
        id: 'BookingTime',
        label: 'Ngày hẹn hiện tại',
        align: 'center',
        minWidth: 180,
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'BookingTimeProposal',
        label: 'Ngày mong muốn đổi',
        align: 'center',
        minWidth: 180,
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 60,
        transform: (value: any) => (<ColumnAction tooltip="Cập nhật" onHandler={value.onHandler}><SvgIcon sx={{ width: 16, height: 16 }} component={EditIcon} inheritViewBox /></ColumnAction>)
    },
];

const radioValueMapping: any = {
    '-1': null,
    '1': bookingStatus.CHANGING,
}

export const Booking = () => {
    const navigate = useNavigate();
    const [keyword, setKeyword] = useState('');
    const [tableLoading, setTableLoading] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState('-1');
    const [spaLocations, setSpaLocations] = useState([]);
    const [radioValue, setRadioValue] = useState('-1');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, radioValue, keyword, selectedLocation]);

    const getData = async () => {
        setTableLoading(true);
        const res = await apiClient.post(ApiConfig.GET_BOOKING_PAGING, getObjectFilter());
        setTableLoading(false);
        if (res?.AppCode === 200) {
            setPaginatedData((prevState: PaginatedResult) => ({
                ...prevState,
                ...res.Data
            }));
        }
    }

    const getObjectFilter = () => {
        return {
            Keyword: keyword,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            LocationId: selectedLocation === "-1" ? null : selectedLocation,
            Status: radioValueMapping[radioValue]
        };
    }

    const onHandler = (item: any) => {
        const type = radioValue === '-1' ? "All" : bookingStatus.CHANGING;
        navigate(`/main/booking/${item.Id}?type=${type}`);
    }

    const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setSelectedLocation(event.target.value);
    };

    const handleChangeRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRadioValue((event.target as HTMLInputElement).value);
    };

    const getSpaLocation = async () => {
        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            setSpaLocations(res.Data);
        }
        else {
            setSpaLocations([])
        }
    }

    useEffect(() => {
        getSpaLocation();
    }, []);

    return (
        <BookingWrapper>
            <Title>Cập nhật lịch hẹn</Title>
            <ToolbarWrapper>
                <Grid container>
                    <Grid sx={{ marginRight: '12px' }} item xs={3}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Số điện thoại</LabelControl>
                            <Input onChange={handleChangeKeyword} value={keyword} fullWidth placeholder="Tìm kiếm theo số điện thoại" />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ marginRight: '12px', marginLeft: '12px' }} item xs={3}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Cơ sở</LabelControl>
                            <HSelect value={selectedLocation}
                                onChange={handleChangeLocation}>
                                <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                                {
                                    spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                }
                            </HSelect>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ marginLeft: '12px' }} item xs={5}>
                        <FormControl fullWidth variant="standard">
                            <LabelControl>Khách hàng</LabelControl>
                            <RadioGroup
                                row
                                aria-labelledby="demo-row-radio-buttons-group-label"
                                name="row-radio-buttons-group"
                                value={radioValue}
                                onChange={handleChangeRadio}
                            >
                                <FormControlLabel value="-1" control={<CustomRadio />} label={<RadioLabel>Tất cả</RadioLabel>} />
                                <FormControlLabel value="1" control={<CustomRadio />} label={<RadioLabel>Yêu cầu đổi lịch</RadioLabel>} />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            </ToolbarWrapper>
            <HTable
                columns={columns}
                pagingInfo={{
                    rowsPerPage,
                    page,
                    totalCount: paginatedData.TotalCount || 0,
                    handleChangePage,
                    handleChangeRowsPerPage
                }}
                loading={tableLoading}
                listData={paginatedData.ListOut}
                onRowHandler={onHandler}
            ></HTable>
        </BookingWrapper>
    );
}