import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Snackbar {
    open: boolean,
    message: string,
    // error, warning, info, success
    type: string
}

interface IRootState {
    globalLoading: boolean;
    globalSnackbar: Snackbar;
}

const initialState: IRootState = {
    globalLoading: false,
    globalSnackbar: {
        open: false,
        message: '',
        type: 'success'
    }
};

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        storeSetGlobalLoading: (state: IRootState, { payload }: PayloadAction<boolean>) => {
            state.globalLoading = payload;
        },
        storeOpenSnackbar: (state: IRootState, { payload }: PayloadAction<any>) => {
            state.globalSnackbar = payload;
        }
    },
});

export const { storeSetGlobalLoading, storeOpenSnackbar } = globalSlice.actions;

export const selectGlobal = (state: { global: any }) => state.global;

export default globalSlice.reducer;
