import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import { selectGlobal } from "@store/globalReducer";
import { useSelector } from "react-redux";

export const GlobalLoading = () => {
    const { globalLoading } = useSelector(selectGlobal);
    return (
        globalLoading &&
        <GlobalLoadingContainer>
            <CircularProgress sx={{ color: COLORS.YankeesBlue }} />
        </GlobalLoadingContainer>
    );
};

const GlobalLoadingContainer = styled('div')(() => ({
    top: 0,
    bottom: 0,
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center",
    position: "fixed",
    background: COLORS.Gray,
    opacity: 0.3,
    zIndex: 10000,
}));


