import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { HButton } from '@components/Button';

const Wrapper = styled('div')(() => ({
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Space = styled('div')(() => ({
    flex: 1
}));

const Forbidden = () => {
    const navigate = useNavigate();
    return (
        <Wrapper>
            <Space />
            <h1>Bạn không đủ thầm quyền để thực thi tác vụ này. Vui lòng đăng nhập với tài khoản có đủ thẩm quyền.</h1>
            <HButton sx={{ marginTop: '16px' }} onClick={() => navigate('/')}>
                Đăng nhập
            </HButton>
            <Space />
        </Wrapper>
    );
};

export default Forbidden;
