import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import { COLORS } from '@shared/constants/colors';

const CustomInput = styled(InputBase, { shouldForwardProp: (prop) => prop !== 'disabled' })(({ disabled, theme }: any) => ({
    height: 40,
    '& .MuiInputBase-input': {
        borderRadius: 8,
        position: 'relative',
        ...(disabled === true &&
        {
            backgroundColor: COLORS.Greyscale,
            cursor: 'not-allowed'
        }
        ),
        ...(
            (!disabled && {
                backgroundColor: theme.palette.mode === 'light' ? COLORS.White : '#2b2b2b',
            })
        ),
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '8px 16px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        borderColor: COLORS.LightSilver,
        '&:hover': {
            borderColor: COLORS.Gray,
        },
        '&:focus': {
            borderColor: COLORS.Gray,
        },
    },
}));

export const Input = (props: any) => {
    return (
        <CustomInput {...props} />
    );
}