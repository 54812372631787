import { IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from "@mui/material";
import { styled, Theme, CSSObject } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import React, { useEffect } from "react";
import { ReactComponent as FeedbackIcon } from '@assets/icons/icon-feedback.svg';
import { ReactComponent as SidebarEditkIcon } from '@assets/icons/icon-sidebar-edit.svg';
import { ReactComponent as ListIcon } from '@assets/icons/icon-list.svg';
import { ReactComponent as NoteIcon } from '@assets/icons/icon-note.svg';
import { ReactComponent as CreateIcon } from '@assets/icons/icon-create.svg';
import { ReactComponent as MoneyIcon } from '@assets/icons/icon-money.svg';
import { ReactComponent as MoneyOutlineIcon } from '@assets/icons/icon-money-outline.svg';

import { ReactComponent as Profile1Icon } from '@assets/icons/icon-profile-1-blue.svg';
import { ReactComponent as Profile2Icon } from '@assets/icons/icon-profile-2-blue.svg';
import { ReactComponent as LocationBlueIcon } from '@assets/icons/icon-location-blue.svg';
import { ReactComponent as ServiceBlueIcon } from '@assets/icons/icon-service-blue.svg';
import { ReactComponent as ServiceTypeBlueIcon } from '@assets/icons/icon-service-type-blue.svg';
import { ReactComponent as ProductBlueIcon } from '@assets/icons/icon-product-blue.svg';
import { ReactComponent as ProductTypeBlueIcon } from '@assets/icons/icon-product-type-blue.svg';
import { ReactComponent as BuyingServiceIcon } from '@assets/icons/icon-buying-service-blue.svg';
import { ReactComponent as BuyingProductIconBlue } from '@assets/icons/icon-buying-product-blue.svg';
import { ReactComponent as BuyingProductIcon } from '@assets/icons/icon-buying-product.svg';
import { ReactComponent as SMSBlueIcon } from '@assets/icons/icon-sms-blue.svg';
import { ReactComponent as PostBlueIcon } from '@assets/icons/icon-edit-blue.svg';
import { ReactComponent as FeedbackBlue1Icon } from '@assets/icons/icon-feedback-blue-1.svg';
import { ReactComponent as FeedbackBlue2Icon } from '@assets/icons/icon-feedback-blue-2.svg';

import { images } from "@assets/imgs";
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { COLORS } from "@shared/constants/colors";
import { SIDE_BAR_WIDTH } from "@shared/constants/layout";
import { Link } from "react-router-dom";
import { routes } from "@shared/constants/routeNames"
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import { systemRole } from "@shared/constants/common";
import { useLocation } from 'react-router-dom';

const menuForRoleCSKH = [
    {
        index: 1,
        icon: FeedbackIcon,
        title: "Cập nhật Feedback",
        route: routes.FEEDBACK
    },
    {
        index: 2,
        icon: SidebarEditkIcon,
        title: "Cập nhật lịch hẹn",
        route: routes.BOOKING
    }
];

const menuForRoleReceptionist = [
    {
        index: 1,
        icon: CreateIcon,
        title: "Tạo mới lịch hẹn",
        route: routes.BOOKING_CREATION
    },
    {
        index: 2,
        icon: ListIcon,
        title: "Quản lý lịch hẹn",
        route: routes.BOOKING_MANAGEMENT
    },
    // {
    //     index: 3,
    //     icon: ListDoneIcon,
    //     title: "Danh Sách Chưa Tạo Lịch Hẹn",
    //     route: routes.BOOKING
    // },
    // {
    //     index: 4,
    //     icon: CalendarIcon,
    //     title: "Xử Lý Yêu Cầu Đổi Lịch Hẹn",
    //     route: routes.BOOKING
    // },
    {
        index: 3,
        icon: NoteIcon,
        title: "Quản lý liệu trình",
        route: routes.TREATMENT_MANAGEMENT
    },
];

const menuForRoleAccountant = [
    {
        index: 1,
        icon: MoneyIcon,
        title: "Lưu mới thông tin thanh toán",
        route: routes.PAYMENT_UPDATE
    },
    {
        index: 2,
        icon: MoneyOutlineIcon,
        title: "Quản lý dịch vụ mua",
        route: routes.PAYMENT_LIST
    },
    {
        index: 3,
        icon: BuyingProductIcon,
        title: "Quản lý sản phẩm mua",
        route: routes.PRODUCT_PAYMENT
    }
];

const menuForRoleAdmin = [
    {
        index: 100,
        type: 'delimiter',
        title: 'Quản lý tài khoản'
    },
    {
        index: 1,
        icon: Profile1Icon,
        title: "Quản lý tất cả người dùng",
        route: routes.ALL_USER_ADMINISTRATION
    },
    {
        index: 2,
        icon: Profile2Icon,
        title: "Quản lý khách hàng",
        route: routes.CUSTOMER_ADMINISTRATION
    },
    {
        index: 101,
        type: 'delimiter',
        title: 'Master data'
    },
    {
        index: 3,
        icon: LocationBlueIcon,
        title: "Quản lý cơ sở",
        route: routes.LOCATION_ADMINISTRATION
    },
    {
        index: 4,
        icon: ServiceBlueIcon,
        title: "Quản lý dịch vụ",
        route: routes.SERVICE_ADMINISTRATION
    },
    {
        index: 5,
        icon: ServiceTypeBlueIcon,
        title: "Quản lý loại dịch vụ",
        route: routes.SERVICE_TYPE_ADMINISTRATION
    },
    {
        index: 6,
        icon: ProductBlueIcon,
        title: "Quản lý sản phẩm",
        route: routes.PRODUCT_ADMINISTRATION
    },
    {
        index: 7,
        icon: ProductTypeBlueIcon,
        title: "Quản lý loại sản phẩm",
        route: routes.PRODUCT_TYPE_ADMINISTRATION
    },
    {
        index: 102,
        type: 'delimiter',
        title: 'Business'
    },
    {
        index: 8,
        icon: BuyingServiceIcon,
        title: "Quản lý dịch vụ mua",
        route: routes.BUYING_SERVICE_ADMINISTRATION
    },
    {
        index: 9,
        icon: BuyingProductIconBlue,
        title: "Quản lý sản phẩm mua",
        route: routes.BUYING_PRODUCT_ADMINISTRATION
    },
    // {
    //     index: 10,
    //     icon: PaymentBlueIcon,
    //     title: "Quản lý thanh toán",
    //     route: routes.MAIN
    // },
    {
        index: 103,
        type: 'delimiter',
        title: 'Quản Lý thông báo - Bài viết'
    },
    {
        index: 11,
        icon: SMSBlueIcon,
        title: "Tạo SMS khuyến mãi",
        route: routes.MAIN
    },
    // {
    //     index: 12,
    //     icon: EmailIcon,
    //     title: "Tạo Email trương trình khuyến mãi",
    //     route: routes.EMAIL_ADMINISTRATION
    // },
    {
        index: 13,
        icon: PostBlueIcon,
        title: "Quản lý bài viết",
        route: routes.ARTICLE_ADMINISTRATION
    },
    {
        index: 104,
        type: 'delimiter',
        title: 'Quản lý Feedback'
    },
    {
        index: 14,
        icon: FeedbackBlue1Icon,
        title: "Feedback buổi điều trị",
        route: routes.FEEDBACK_ADMINISTRATION
    },
    {
        index: 15,
        icon: FeedbackBlue2Icon,
        title: "Khiếu nại",
        route: routes.COMPLAIN_ADMINISTRATION
    },
];

const SIDE_BAR_COLLAPSE_WIDTH = 65;

const openedMixin = (theme: Theme): CSSObject => ({
    width: SIDE_BAR_WIDTH,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `${SIDE_BAR_COLLAPSE_WIDTH}px`,
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px',
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        width: SIDE_BAR_WIDTH,
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxSizing: 'border-box',
        ...(open && {
            ...openedMixin(theme),
            '& .MuiDrawer-paper': openedMixin(theme),
        }),
        ...(!open && {
            ...closedMixin(theme),
            '& .MuiDrawer-paper': closedMixin(theme),
        }),
    }),
);

interface Props {
    onToggleSidebar: (value: boolean) => void;
}

export const Sidebar: React.FC<Props> = props => {
    const { onToggleSidebar } = props;
    const location = useLocation();
    var { tokenClaims } = useAppSelector(state => state.auth);

    const [open, setOpen] = React.useState(true);
    const [tabIndex, setTabIndex] = React.useState(1);
    const [menu, setMenu] = React.useState([] as any);

    const setTabIndexByRoute = () => {
        const currentPath = location.pathname;
        const item = menu.find((x: any) => x.route === currentPath);
        if (item) {
            setTabIndex(item.index);
        }
    }

    const handleDrawerOpen = () => {
        onToggleSidebar(true);
        setOpen(true);
    };

    const handleDrawerClose = () => {
        onToggleSidebar(false);
        setOpen(false);
    };

    const handleClickTab = (tabItem: any) => {
        setTabIndex(tabItem.index);
    }

    useEffect(() => {
        setTabIndexByRoute();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [menu])

    useEffect(() => {
        var currentMenu = [] as any;
        switch (tokenClaims.role) {
            case systemRole.CUSTOMER_CARE:
                currentMenu = menuForRoleCSKH;
                break;
            case systemRole.RECEPTIONIST:
                currentMenu = menuForRoleReceptionist;
                break;
            case systemRole.ACCOUNTANT:
                currentMenu = menuForRoleAccountant;
                break;
            case systemRole.ADMIN:
                currentMenu = menuForRoleAdmin;
                break;
        }

        setMenu(currentMenu);
    }, [tokenClaims])

    return (
        <Drawer variant="permanent" open={open}>
            <DrawerHeader sx={{ justifyContent: open ? 'space-between' : 'center', height: '68px' }}>
                <img src={images.brand} style={open ? {} : { display: 'none' }} alt="brand" />
                <IconButton onClick={open ? handleDrawerClose : handleDrawerOpen} sx={{ padding: '4px' }}>
                    {open ? <KeyboardDoubleArrowLeftIcon sx={styles.iconToggle} /> : <KeyboardDoubleArrowRightIcon sx={styles.iconToggle} />}
                </IconButton>
            </DrawerHeader>
            <List>
                {menu.map((item: any) => (
                    item.type === 'delimiter' ?
                        <div key={item.index} style={{
                            ...(styles.delimiter),
                            ...(open ? { paddingLeft: '12px' } : { textAlign: 'center' })
                        }}
                        >{open ? item.title : '---'}</div> :
                        <Link style={{ textDecoration: 'none' }} key={item.index} to={item.route}>
                            <ListItem key={item.index} disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: '24px',
                                        '&:hover': {
                                            backgroundColor: COLORS.Cultured
                                        },
                                        backgroundColor: tabIndex === item.index ? COLORS.Lavender : COLORS.White,
                                    }}
                                    onClick={() => handleClickTab(item)}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            mr: open ? 1.5 : 'auto',
                                            justifyContent: 'center',
                                        }}
                                    >
                                        <SvgIcon sx={styles.iconSideBar} component={item.icon} inheritViewBox />
                                    </ListItemIcon>
                                    <ListItemText primary={item.title} sx={{ opacity: open ? 1 : 0 }} />
                                </ListItemButton>
                            </ListItem>
                        </Link>
                ))}
            </List>
        </Drawer>
    );
}

const styles = {
    iconSideBar: {
        fontSize: '18px'
    },
    iconToggle: {
        color: COLORS.Tertiary
    },
    delimiter: {
        fontSize: '12px',
        lineHeight: '20px',
        paddingTop: '4px',
        paddingBottom: '4px',
        color: COLORS.PhilippineSilver
    }
}