export const routes = {
    LOGIN: '/login',
    MAIN: '/main',
    BOOKING: '/main/booking',
    BOOKING_MANAGEMENT: '/main/booking-management',
    TREATMENT_MANAGEMENT: '/main/treatment-management',
    BOOKING_CREATION: '/main/booking/create',
    BOOKING_DETAIL: '/main/booking/:id',
    FEEDBACK: '/main/feedback',
    TREATMENT_SESSION: '/main/feedback/:id',
    PAYMENT_UPDATE: '/main/payment/update',
    PAYMENT_LIST: '/main/payment/list',
    PAYMENT_DETAIL: '/main/payment/:id',
    PRODUCT_PAYMENT: '/main/payment/product',
    ALL_USER_ADMINISTRATION: '/main/administration/account/all',
    CUSTOMER_ADMINISTRATION: '/main/administration/account/customer',
    CUSTOMER_DETAIL_ADMINISTRATION: '/main/administration/account/customer/:id',
    LOCATION_ADMINISTRATION: '/main/administration/location',
    SERVICE_ADMINISTRATION: '/main/administration/service',
    SERVICE_TYPE_ADMINISTRATION: '/main/administration/service-type',
    PRODUCT_ADMINISTRATION: '/main/administration/product',
    PRODUCT_TYPE_ADMINISTRATION: '/main/administration/product-type',
    BUYING_SERVICE_ADMINISTRATION: '/main/administration/buying-service',
    BUYING_PRODUCT_ADMINISTRATION: '/main/administration/buying-product',
    PAYMENT_ADMINISTRATION: '/main/administration/payment',
    EMAIL_ADMINISTRATION: '/main/administration/email',
    ARTICLE_ADMINISTRATION: '/main/administration/article',
    // Phản hồi khách hàng
    FEEDBACK_ADMINISTRATION: '/main/administration/feedback',
    // Khiếu nại
    COMPLAIN_ADMINISTRATION: '/main/administration/complain',
    USER_INFO: '/user-info',
    FORBIDDEN: '/forbidden'
}