import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TokenClaims } from 'types/common';

interface IAuthInitState {
  accessToken: string;
  refreshToken: string;
  tokenClaims: TokenClaims
}

const initialState: IAuthInitState = {
  accessToken: '',
  refreshToken: '',
  tokenClaims: {} as TokenClaims
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    storeSetToken: (state: IAuthInitState, { payload }: PayloadAction<string>) => {
      state.accessToken = payload;
    },
    storeSetRefreshToken: (state: IAuthInitState, { payload }: PayloadAction<string>) => {
      state.refreshToken = payload;
    },
    storeSetTokenClaim: (state: IAuthInitState, { payload }: PayloadAction<TokenClaims>) => {
      state.tokenClaims = payload;
    },
    logout: (state: IAuthInitState,) => {
      state.accessToken = "";
      state.refreshToken = "";
      state.tokenClaims = {} as TokenClaims
    }
  },
});

export const { storeSetToken, storeSetRefreshToken, storeSetTokenClaim, logout } = authSlice.actions;

export const selectAuth = (state: { auth: any }) => state.auth;

export default authSlice.reducer;
