import { Suspense, useEffect, useLayoutEffect, useState } from "react";
import { Route, Router, Routes, Navigate, Outlet } from "react-router-dom";
import { Main } from '@pages/main'
import NotFound from "@pages/notFound";
import Forbidden from "@pages/forbidden";
import { Booking } from "@pages/booking";
import { Feedback } from "@pages/feedback";
import { Login } from "@pages/auth/Login";
import { UserInfo } from "@pages/user/userInfo";
import { routes } from "@shared/constants/routeNames"
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import { TreatmentSessionDetail } from "@pages/feedback/treatmentSessionDetail";
import { BookingDetail } from "@pages/booking/bookingDetail";
import { systemRole } from "@shared/constants/common";
import { BookingManagement } from "@pages/booking/bookingManagement";
import { TreatmentManagement } from "@pages/treatment/treatmentManagement";
import { BookingCreation } from "@pages/booking/bookingCreation";
import { PaymentUpdate } from "@pages/payment/paymentUpdate";
import { PaymentList } from "@pages/payment/paymentList";
import { PaymentServiceDetail } from "@pages/payment/paymentServiceDetail";
import { AllUserAdministration } from "@pages/administration/account/allUser";
import { CustomerAdministration } from "@pages/administration/account/customer";
import { CustomerDetailAdministration } from "@pages/administration/account/customerDetail";
import { LocationAdministration } from "@pages/administration/masterData/location";
import { ServiceAdministration } from "@pages/administration/masterData/service";
import { ServiceTypeAdministration } from "@pages/administration/masterData/serviceType";
import { ProductAdministration } from "@pages/administration/masterData/product";
import { ProductTypeAdministration } from "@pages/administration/masterData/productType";
import { BuyingProductAdministration } from "@pages/administration/business/buyingProduct";
import { BuyingServiceAdministration } from "@pages/administration/business/buyingService";
import { PaymentAdministration } from "@pages/administration/business/payment";
import { ComplainAdministration } from "@pages/administration/feedback/complain";
import { CustomerFeedbackAdministration } from "@pages/administration/feedback/customerFeedback";
import { EmailAdministration } from "@pages/administration/postAndNotification/emailAdministration";
import { ArticleAdministration } from "@pages/administration/postAndNotification/article";
import { BuyingProductPayment } from "@pages/payment/product";

const ProtectedRoute = ({
    token,
    redirectPath = routes.LOGIN,
    children,
}: any) => {
    if (!token) {
        return <Navigate to={redirectPath} replace />;
    }

    return children ? children : <Outlet />;
};

export const AppRouter = ({ history, ...props }: any) => {
    var { accessToken, tokenClaims } = useAppSelector(state => state.auth);
    const [defaultRoute, setDefaultRoute] = useState('/');

    useEffect(() => {
        switch (tokenClaims.role) {
            case systemRole.CUSTOMER_CARE:
                setDefaultRoute(routes.FEEDBACK);
                break;
            case systemRole.RECEPTIONIST:
                setDefaultRoute(routes.BOOKING_MANAGEMENT);
                break;
            case systemRole.ACCOUNTANT:
                setDefaultRoute(routes.PAYMENT_UPDATE);
                break;
            case systemRole.ADMIN:
                setDefaultRoute(routes.ALL_USER_ADMINISTRATION);
                break;
            default:
                setDefaultRoute(routes.MAIN);
                break;
        }
    }, [tokenClaims])

    return (
        <CustomRouter history={history}>
            <Suspense fallback={null}>
                <Routes>
                    <Route path={routes.LOGIN} element={<Login />}></Route>
                    <Route path='/' element={<Navigate to={defaultRoute} />}></Route>
                    {/* Route cho chăm sóc khách hàng */}
                    {
                        tokenClaims.role === systemRole.CUSTOMER_CARE &&
                        <Route element={<ProtectedRoute token={accessToken} children={<Main />}></ProtectedRoute>}>
                            <Route path={routes.FEEDBACK} element={<Feedback />}></Route>
                            <Route path={routes.TREATMENT_SESSION} element={<TreatmentSessionDetail />}></Route>
                            <Route path={routes.BOOKING} element={<Booking />}></Route>
                            <Route path={routes.BOOKING_DETAIL} element={<BookingDetail />}></Route>
                        </Route>
                    }
                    {/* Route chung khi không có role nào */}
                    {
                        (!tokenClaims.role || tokenClaims.role !== systemRole.CUSTOMER_CARE) &&
                        <Route path={routes.MAIN} element={<Main />}></Route>
                    }
                    {/* Route cho role Lễ Tân */}
                    {
                        tokenClaims.role === systemRole.RECEPTIONIST &&
                        <Route element={<ProtectedRoute token={accessToken} children={<Main />}></ProtectedRoute>}>
                            <Route path={routes.BOOKING_CREATION} element={<BookingCreation />}></Route>
                            <Route path={routes.BOOKING_MANAGEMENT} element={<BookingManagement />}></Route>
                            <Route path={routes.TREATMENT_MANAGEMENT} element={<TreatmentManagement />}></Route>
                        </Route>
                    }
                    {/* Route cho role Kế toán */}
                    {
                        tokenClaims.role === systemRole.ACCOUNTANT &&
                        <Route element={<ProtectedRoute token={accessToken} children={<Main />}></ProtectedRoute>}>
                            <Route path={routes.PAYMENT_DETAIL} element={<PaymentServiceDetail />}></Route>
                            <Route path={routes.PAYMENT_UPDATE} element={<PaymentUpdate />}></Route>
                            <Route path={routes.PAYMENT_LIST} element={<PaymentList />}></Route>
                            <Route path={routes.PRODUCT_PAYMENT} element={<BuyingProductPayment />}></Route>
                        </Route>
                    }
                    {/* Route cho role Admin */}
                    {
                        tokenClaims.role === systemRole.ADMIN &&
                        <Route element={<ProtectedRoute token={accessToken} children={<Main />}></ProtectedRoute>}>
                            <Route path={routes.ALL_USER_ADMINISTRATION} element={<AllUserAdministration />}></Route>
                            <Route path={routes.CUSTOMER_ADMINISTRATION} element={<CustomerAdministration />}></Route>
                            <Route path={routes.CUSTOMER_DETAIL_ADMINISTRATION} element={<CustomerDetailAdministration />}></Route>
                            <Route path={routes.LOCATION_ADMINISTRATION} element={<LocationAdministration />}></Route>
                            <Route path={routes.SERVICE_ADMINISTRATION} element={<ServiceAdministration />}></Route>
                            <Route path={routes.SERVICE_TYPE_ADMINISTRATION} element={<ServiceTypeAdministration />}></Route>
                            <Route path={routes.PRODUCT_ADMINISTRATION} element={<ProductAdministration />}></Route>
                            <Route path={routes.PRODUCT_TYPE_ADMINISTRATION} element={<ProductTypeAdministration />}></Route>
                            <Route path={routes.BUYING_PRODUCT_ADMINISTRATION} element={<BuyingProductAdministration />}></Route>
                            <Route path={routes.BUYING_SERVICE_ADMINISTRATION} element={<BuyingServiceAdministration />}></Route>
                            <Route path={routes.PAYMENT_ADMINISTRATION} element={<PaymentAdministration />}></Route>
                            <Route path={routes.COMPLAIN_ADMINISTRATION} element={<ComplainAdministration />}></Route>
                            <Route path={routes.FEEDBACK_ADMINISTRATION} element={<CustomerFeedbackAdministration />}></Route>
                            <Route path={routes.EMAIL_ADMINISTRATION} element={<EmailAdministration />}></Route>
                            <Route path={routes.ARTICLE_ADMINISTRATION} element={<ArticleAdministration />}></Route>
                        </Route>
                    }
                    <Route path="/user-info" element={<ProtectedRoute token={accessToken} children={<UserInfo />}></ProtectedRoute>}></Route>
                    <Route path='*' element={<NotFound />} />
                    <Route path={routes.FORBIDDEN} element={<Forbidden />} />
                </Routes>
            </Suspense>
        </CustomRouter>
    )
}

const CustomRouter = ({ history, ...props }: any) => {
    const [state, setState] = useState({
        action: history.action,
        location: history.location,
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    return <Router {...props} location={state.location} navigationType={state.action} navigator={history} />;
};