import { Tooltip } from "@mui/material"
import { COLORS } from "@shared/constants/colors"

// tooltip có thể là HTML
export const ColumnAction = ({ onHandler, tooltip = '', children, ...props }: any) => {
    return (
        <div className="flex justify-center items-center">
            <Tooltip title={tooltip} placement="left">
                <div style={{ backgroundColor: COLORS.YankeesBlue, width: 32, height: 32, borderRadius: '6px' }} onClick={onHandler} className='flex justify-center items-center cursor-pointer'>
                    <div className='w-full'>
                        {children}
                    </div>
                </div>
            </Tooltip>
        </div>

    )
}

export const ColumnTextAction = ({ onCustomHandler, tooltip = '', children, ...props }: any) => {
    return (
        <div onClick={onCustomHandler}>
            {children}
        </div>
    )
}