import { HButton } from "@components/Button";
import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import { LabelControl, Title } from "@shared/styledComponents";
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import { FormControl, Grid } from "@mui/material";
import { Input } from "@components/Input";
import { useEffect, useState } from "react";
import { HDatePicker } from "@components/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { apiClient } from "@services/baseAxios";
import { Column, PaginatedResult } from "types/common";
import { ApiConfig } from "@config/index";
import { storeOpenSnackbar } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { useDispatch } from "react-redux";
import { HTable } from "@components/Table";
import { currencyFormat } from "@shared/helpers/common";
import moment from "moment";

const Wrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    padding: "16px 0px"
}));

const columns: readonly Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    { id: 'CustomerName', label: 'Tên khách hàng', align: 'left', minWidth: 150, transform: (data: any) => (<div style={{ fontWeight: 'bold' }}>{data.value}</div>) },
    {
        id: 'Phone',
        label: 'Số điện thoại',
        align: 'left',
        minWidth: 120,
    },
    {
        id: 'ServiceName',
        label: 'Tên dịch vụ',
        minWidth: 150,
        align: 'left',
    },
    {
        id: 'PaymentDate',
        label: 'Ngày thanh toán',
        minWidth: 140,
        align: 'center',
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'Amount',
        label: 'Đã thanh toán',
        minWidth: 140,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.MagicPotion }}>{currencyFormat(data.value)}đ</span>)
    },
    {
        id: 'ActualPayment',
        label: 'Giá dịch vụ',
        minWidth: 100,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.Eucalyptus }}>{currencyFormat(data.value)}đ</span>)
    },
];

export const BuyingServiceAdministration = () => {
    const dispatch = useDispatch();

    const [keyword, setKeyword] = useState('');
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);

    const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getObjectFilter = () => {
        var fromDateVNTime = dayjs(fromDate).add(7, 'hours') || null;
        var toDateVNTime = dayjs(toDate).add(7, 'hours') || null;

        return {
            Keyword: keyword,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            DateFrom: fromDateVNTime,
            DateTo: toDateVNTime,
        };
    }

    const getListPayment = async () => {
        try {
            setTableLoading(true);
            const { AppCode, Data } = await apiClient.post(`${ApiConfig.PAYMENT}/search`, getObjectFilter());
            setTableLoading(false);
            if (AppCode === 200) {
                setPaginatedData((prevState: PaginatedResult) => ({
                    ...prevState,
                    ...Data
                }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error) {
            setTableLoading(false);
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    useEffect(() => {
        getListPayment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, keyword, fromDate, toDate]);

    return (
        <Wrapper>
            <div className="w-full flex justify-between">
                <Title>Danh sách dịch vụ mua</Title>
                <HButton startIcon={<SendToMobileIcon />} onClick={() => {
                    // setIsOpenUserForm(true);
                }}>Xuất file excel</HButton>
            </div>
            <ToolbarWrapper>
                <Grid container>
                    <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Số điện thoại hoặc tên</LabelControl>
                            <Input onChange={handleChangeKeyword} value={keyword} fullWidth placeholder="Tìm kiếm theo số điện thoại hoặc tên" />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Từ ngày</LabelControl>
                            <HDatePicker
                                value={fromDate}
                                ampm={false}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue: any) => {
                                    setFromDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                    <Grid item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Đến ngày</LabelControl>
                            <HDatePicker
                                value={toDate}
                                placeholder="Từ ngày"
                                inputFormat="DD/MM/YYYY"
                                ampm={false}
                                onChange={(newValue: any) => {
                                    setToDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                </Grid>
            </ToolbarWrapper>
            <HTable
                columns={columns}
                pagingInfo={{
                    rowsPerPage,
                    page,
                    totalCount: paginatedData.TotalCount,
                    handleChangePage,
                    handleChangeRowsPerPage
                }}
                loading={tableLoading}
                listData={paginatedData.ListOut}
            ></HTable>
        </Wrapper>
    )
}