import Radio from '@mui/material/Radio';
import { COLORS } from '@shared/constants/colors';

export const CustomRadio = (props: any) => {
    return <Radio {...props} sx={{
        color: COLORS.LightSilver,
        borderWidth: '0.5px',
        '&.Mui-checked': {
            color: COLORS.YankeesBlue,
        },
    }} />
}