import { HButton } from "@components/Button"
import { HDialog } from "@components/Dialog"
import { Input } from "@components/Input";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FormControl, Grid, MenuItem } from "@mui/material";
import { LabelControl } from "@shared/styledComponents";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { useDispatch, useSelector } from "react-redux";
import { ApiConfig } from "@config/index";
import { apiClient } from "@services/baseAxios";
import { errorMessages } from "@shared/constants/messages";
import { HSelect } from "@components/Select";
import { COLORS } from "@shared/constants/colors";
import { HTextArea } from "@components/Input/HTextArea";
import CloseIcon from '@mui/icons-material/Close';

export const ArticleForm = ({ title, isOpen, data, handleOnClose, handleOnConfirm, isCreateNew }: any) => {
    const dispatch = useDispatch();
    const { allGroupArticle } = useSelector((state: any) => state.directory);
    const [article, setArticle] = useState({} as any);
    const [selectedFile, setSelectedFile] = useState()
    const [preview, setPreview] = useState('');
    const [isUpdateFile, setIsUpdateFile] = useState(false);

    const handleTextInputChange = (event: any, field: string) => {
        const value = event.target.value;
        switch (field) {
            case 'title':
                setArticle((prevState: any) => ({
                    ...prevState,
                    ...{ Title: value }
                }));
                break;
        }
    }

    const handleSubmit = () => {
        createOrUpdateArticle();
    }

    const createOrUpdateArticle = async () => {
        // eslint-disable-next-line eqeqeq
        if (article.ArticleGroup === "-1" || (!article.ArticleGroup && article.ArticleGroup != 0) || !article.Title || !article.Content) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vùi lòng nhập đủ thông tin", type: "warning" }));
            return;
        }
        if (isCreateNew) {
            if (!selectedFile) {
                dispatch(storeOpenSnackbar({ open: true, message: "Vùi lòng chọn ảnh", type: "warning" }));
                return;
            }
        }
        else {
            if (isUpdateFile && !selectedFile) {
                dispatch(storeOpenSnackbar({ open: true, message: "Vùi lòng chọn ảnh", type: "warning" }));
                return;
            }
        }


        let formData = new FormData();
        // Nếu là sửa bài viết
        if (!isCreateNew) {
            formData.append("Id", article.Id);
        }

        formData.append("Title", article.Title);
        formData.append("Content", article.Content);
        formData.append("ArticleGroup", article.ArticleGroup);
        if (selectedFile) {
            formData.append("ArticleImg", selectedFile);
        }
        formData.append("IsUpdateFile", isUpdateFile.toString())
        formData.append("ArticleImgUrl", article.ArticleImg)

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.post(`${ApiConfig.ARTICLE}`, formData, { headers: { "Content-Type": "multipart/form-data" } });
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                handleOnConfirm();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else if (error?.response?.data?.AppCode === 400) {
                dispatch(storeOpenSnackbar({ open: true, message: "Thông tin đẩy lên sai định dạng", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const changeArticleContent = (event: any) => {
        setArticle((prevState: any) => ({
            ...prevState,
            ...{ Content: event.target.value }
        }));
    }

    const handleChangeArticleGroup = (event: { target: { value: any } }) => {
        setArticle((prevState: any) => ({
            ...prevState,
            ...{ ArticleGroup: event.target.value }
        }));
    };

    const onSelectFile = (e: any) => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedFile(undefined)
            return;
        }

        // I've kept this example simple by using the first image instead of multiple
        setSelectedFile(e.target.files[0])
    }

    const deleleFile = (event: any) => {
        event.stopPropagation()
        setSelectedFile(undefined)

        if (!isCreateNew) {
            setIsUpdateFile(true);
        }
    }

    useEffect(() => {
        if (!selectedFile) {
            setPreview('')
            return;
        }

        const objectUrl = URL.createObjectURL(selectedFile)
        setPreview(objectUrl)

        // free memory when ever this component is unmounted
        return () => URL.revokeObjectURL(objectUrl)
    }, [selectedFile])

    useEffect(() => {
        setArticle(data || {});
        if (!isCreateNew) {
            setPreview(data.ArticleImg)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <HDialog
            PaperProps={{
                sx: {
                    width: "740px",
                    maxWidth: "740px",
                }
            }}
            title={title}
            open={isOpen}
            handleClose={handleOnClose}
            dialogFooter={
                <>
                    <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={handleOnClose}>Hủy</HButton>
                    {
                        isCreateNew ?
                            <HButton startIcon={<AddIcon />} onClick={handleSubmit}>Tạo bài viết</HButton>
                            :
                            <HButton startIcon={<DoneOutlinedIcon />} onClick={handleSubmit}>Lưu</HButton>
                    }
                </>
            }
        >
            <Grid container>
                <Grid sx={{ pt: 0, pl: 0 }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Nhóm bài viết</LabelControl>
                        <HSelect value={(!article.ArticleGroup && article.ArticleGroup !== 0) ? "-1" : article.ArticleGroup}
                            onChange={handleChangeArticleGroup}>
                            <MenuItem key={-1} value={"-1"}>
                                <em style={{ color: COLORS.Gray }}>Chọn nhóm bài viết</em>
                            </MenuItem>
                            {
                                allGroupArticle.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                            }
                        </HSelect>
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Tiêu đề</LabelControl>
                        <Input onChange={(event: any) => handleTextInputChange(event, 'title')} value={article.Title} sx={{ width: '100%', marginTop: '4px' }} placeholder="Nhập tiêu đề bài viết" />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Ảnh bài viết</LabelControl>
                        <div>
                            <div style={styles.fileInput} className="relative">
                                {
                                    selectedFile || (!isCreateNew && !isUpdateFile) ?
                                        <div className="w-full h-full flex cursor-default">
                                            <div onClick={deleleFile} className="absolute cursor-pointer" style={{ right: '0px', top: '0px' }}>
                                                <CloseIcon />
                                            </div>
                                            <img className="p-3" src={preview} alt="img" />
                                        </div>
                                        :
                                        <label className="items-center justify-center flex flex-col">
                                            <input className="hidden" type='file' accept="image/*" onChange={onSelectFile} />
                                            <div><AddIcon /></div>
                                            <div>Thêm ảnh</div>
                                        </label>
                                }
                            </div>
                        </div>
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Nội dung</LabelControl>
                        <HTextArea
                            value={article.Content || ''}
                            onChange={changeArticleContent}
                            maxRows={3}
                            aria-label="maximum height"
                            placeholder="Nhập nội dung bài viết"
                        />
                    </FormControl>
                </Grid>
            </Grid>
        </HDialog>
    )
}

const styles = {
    fileInput: {
        cursor: 'pointer',
        display: "flex",
        'flex-direction': 'column',
        justifyContent: 'center',
        alignItems: 'center',
        width: '104px',
        height: '104px',
        border: '1px solid #C0C0C0',
        backgroundColor: COLORS.Greyscale,
        borderRadius: '2px'
    }
}