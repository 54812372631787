import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface IRootState {
    spaLocations: Array<any>;
    allRole: Array<any>;
    allProvince: Array<any>,
    allProductType: Array<any>,
    allServiceType: Array<any>,
    allGroupArticle: Array<any>
}

const initialState: IRootState = {
    spaLocations: [],
    allRole: [],
    allProvince: [],
    allProductType: [],
    allServiceType: [],
    allGroupArticle: []
};

const directorySlice = createSlice({
    name: 'directory',
    initialState,
    reducers: {
        storeSetSpaLocations: (state: IRootState, { payload }: PayloadAction<any>) => {
            state.spaLocations = payload;
        },
        storeSetAllRole: (state: IRootState, { payload }: PayloadAction<any>) => {
            state.allRole = payload;
        },
        storeSetAllProvince: (state: IRootState, { payload }: PayloadAction<any>) => {
            state.allProvince = payload;
        },
        storeSetAllProductType: (state: IRootState, { payload }: PayloadAction<any>) => {
            state.allProductType = payload;
        },
        storeSetAllServiceType: (state: IRootState, { payload }: PayloadAction<any>) => {
            state.allServiceType = payload;
        },
        storeSetAllGroupArticle: (state: IRootState, { payload }: PayloadAction<any>) => {
            state.allGroupArticle = payload;
        },
    },
});

export const { storeSetSpaLocations, storeSetAllRole, storeSetAllProvince, storeSetAllProductType, storeSetAllServiceType, storeSetAllGroupArticle } = directorySlice.actions;

// export const selectGlobal = (state: { global: any }) => state.global;

export default directorySlice.reducer;