import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import { COLORS } from '@shared/constants/colors';
import { styled } from "@mui/material/styles";

const StyledTextField = styled(TextField)({
    border: `1px solid ${COLORS.LightSilver}`,
    borderRadius: '8px',
    height: 40,
    '&:hover': {
        borderColor: COLORS.YankeesBlue
    },
    '&:active': {
        borderColor: COLORS.YankeesBlue,
    },
    '&:focus': {
        borderColor: COLORS.YankeesBlue
    }
});

export const HTextField = ({ iconStart, iconEnd, ...props }: any) => {
    return (
        <StyledTextField
            {...props}
            InputProps={{
                startAdornment: iconStart ? (
                    <InputAdornment position="start">{iconStart}</InputAdornment>
                ) : null,
                endAdornment: iconEnd ? (
                    <InputAdornment position="end">{iconEnd}</InputAdornment>
                ) : null,
                disableUnderline: true,
                style: { height: '100%', paddingLeft: '16px', paddingRight: '16px' }
            }}
            variant="standard"
        />
    )
}