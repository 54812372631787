import { HDatePicker } from "@components/DateTimePicker";
import { Input } from "@components/Input";
import { HSelect } from "@components/Select";
import { HTable } from "@components/Table";
import { ColumnAction, ColumnTextAction } from "@components/Table/ColumnAction";
import { ApiConfig } from "@config/index";
import { FormControl, Grid, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiClient } from "@services/baseAxios";
import { COLORS } from "@shared/constants/colors";
import { LabelControl, Title } from "@shared/styledComponents";
import { storeSetSpaLocations } from "@store/directoryReducer";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as EditIcon } from '@assets/icons/icon-edit.svg';
import SvgIcon from '@mui/icons-material/Menu';
import { Column, PaginatedResult } from "types/common";
import moment from "moment";
import { currencyFormat } from "@shared/helpers/common";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { HDialog } from "@components/Dialog";
import { HButton } from "@components/Button";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { HTextField } from "@components/Input/HTextFiled";
import { ReactComponent as VNDIcon } from '@assets/icons/icon-vnd.svg';
import { rowCustomHandlerType } from "@components/Table/constant";
import { useNavigate } from "react-router-dom";

const Wrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    padding: "16px 0px"
}));

const columns: readonly Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    { id: 'CustomerName', label: 'Họ tên khách hàng', align: 'left', minWidth: 150, transform: (data: any) => (<div style={{ fontWeight: 'bold' }}>{data.value}</div>) },
    {
        id: 'Phone',
        label: 'Số điện thoại',
        align: 'left',
        minWidth: 120,
    },
    {
        id: 'BuyerMembershipClass',
        label: 'Hạng',
        minWidth: 80,
        align: 'left',
        transform: (data: any) => (data.value ? <span style={{ color: COLORS.Tertiary }}>{data.value}</span> : 'Standard')
    },

    {
        id: 'ServiceName',
        label: 'Dịch vụ',
        minWidth: 150,
        align: 'left',
        transform: (value: any) => (
            <ColumnTextAction onCustomHandler={() => value.onCustomHandler(rowCustomHandlerType.HYPPER_LINK)}>
                <div style={{ color: COLORS.BrilliantAzure }} className="cursor-pointer hover:underline">
                    {value.value}
                </div>
            </ColumnTextAction>
        )
    },
    {
        id: 'PaymentDate',
        label: 'Ngày thanh toán',
        minWidth: 140,
        align: 'center',
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'Amount',
        label: 'Đã thanh toán',
        minWidth: 140,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.MagicPotion }}>{currencyFormat(data.value)}đ</span>)
    },
    {
        id: 'Price',
        label: 'Giá dịch vụ',
        minWidth: 100,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.Eucalyptus }}>{currencyFormat(data.value)}đ</span>)
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (<ColumnAction tooltip="Sửa thông tin thanh toán" onHandler={value.onHandler}><SvgIcon sx={{ width: 16, height: 16 }} component={EditIcon} inheritViewBox /></ColumnAction>)
    },
];

export const PaymentList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);
    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const { spaLocations } = useSelector((state: any) => state.directory);
    const [selectedLocation, setSelectedLocation] = useState("-1");
    const [tableLoading, setTableLoading] = useState(false);
    const [paymentDate, setPaymentDate] = useState<Dayjs | null>(null);
    const [moneyInput, setMoneyInput] = useState('');
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
    const [isOpenUpdatePaymentDialog, setIsOpenUpdatePaymentDialog] = useState(false);
    const [currentSelectedRow, setCurrentSelectedRow] = useState({ ServicePaymentDetailId: null, ServiceUserId: null });
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) return;

        const { AppCode, Data } = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (AppCode === 200) {
            dispatch(storeSetSpaLocations(Data));
        }
        else {
            // setSpaLocations([])
        }
    }

    const closeUpdatePaymentDialog = () => {
        setIsOpenUpdatePaymentDialog(false);
    }

    const savePaymentInfo = async () => {
        const data = {
            Id: currentSelectedRow.ServicePaymentDetailId,
            PaymentDate: paymentDate,
            Amount: parseInt(moneyInput.replace(/\D/g, '')),
            ServicePaymentId: currentSelectedRow.ServiceUserId
        }

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.post(ApiConfig.PAYMENT, data);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                dispatch(storeOpenSnackbar({ open: true, message: "Sửa thông tin thanh toán thành công", type: "success" }));
                closeConfirmDialog();
                getListPayment();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Sửa thông tin thanh toán thất bại", type: "error" }));
            }
        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleTextInputChange = (event: any, field: string) => {
        const value = event.target.value;
        switch (field) {
            case 'money':
                var a = value.replace(/\D/g, "");
                var data = currencyFormat(parseInt(a)).toString();

                setMoneyInput(data);
                break;
        }
    }

    const getObjectFilter = () => {
        var fromDateVNTime = dayjs(fromDate).add(7, 'hours') || null;
        var toDateVNTime = dayjs(toDate).add(7, 'hours') || null;

        return {
            Keyword: keyword,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            DateFrom: fromDateVNTime,
            DateTo: toDateVNTime,
            LocationId: selectedLocation === "-1" ? null : selectedLocation,
        };
    }

    const onHandler = (item: any) => {
        setCurrentSelectedRow(item);
        setIsOpenUpdatePaymentDialog(true);
        setPaymentDate(dayjs(new Date()));
        setMoneyInput(currencyFormat(item.Amount).toString());
    }

    const getListPayment = async () => {
        try {
            setTableLoading(true);
            const { AppCode, Data } = await apiClient.post(`${ApiConfig.PAYMENT}/search`, getObjectFilter());
            setTableLoading(false);
            if (AppCode === 200) {
                setPaginatedData((prevState: PaginatedResult) => ({
                    ...prevState,
                    ...Data
                }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error) {
            setTableLoading(false);
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const closeConfirmDialog = () => {
        setIsOpenConfirmDialog(false);
    }

    const openConfirmDialog = () => {
        if (!paymentDate || !moneyInput) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng nhập đủ thông tin", type: "warning" }));
            return;
        }

        setIsOpenUpdatePaymentDialog(false);
        setIsOpenConfirmDialog(true);
    }

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setSelectedLocation(event.target.value);
    };

    const onRowCustomHandler = (row: any, handlerType: any) => {
        navigate(`/main/payment/${row.ServiceUserId}`);
    }

    useEffect(() => {
        getListPayment();
        getSpaLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getListPayment();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, keyword, selectedLocation, fromDate, toDate]);

    return (
        <Wrapper>
            <Title>Danh sách dịch vụ mua</Title>
            <ToolbarWrapper>
                <Grid container>
                    {/* Row 1 */}
                    <Grid sx={{ pt: 0, pl: 0 }} item xs={3}>
                        <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl>Từ khóa</LabelControl>
                            <Input onChange={handleChangeKeyword} value={keyword} sx={{ width: '100%' }} placeholder="Tìm kiếm theo từ khoá" />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ pt: 0, pl: 0, display: 'flex' }} item xs={6}>
                        <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl>Từ ngày</LabelControl>
                            <HDatePicker
                                value={fromDate}
                                ampm={false}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue: any) => {
                                    setFromDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                        <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl>Đến ngày</LabelControl>
                            <HDatePicker
                                value={toDate}
                                placeholder="Từ ngày"
                                inputFormat="DD/MM/YYYY"
                                ampm={false}
                                onChange={(newValue: any) => {
                                    setToDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ pt: 0, pl: 0 }} item xs={3}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Chọn địa điểm</LabelControl>
                            <HSelect fullWidth value={selectedLocation}
                                onChange={handleChangeLocation}>
                                <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                                {/* <MenuItem key={-1} disabled value="-1">
                                    <em>Chọn địa điểm</em>
                                </MenuItem> */}
                                {
                                    spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                }
                            </HSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            </ToolbarWrapper>
            <HTable
                columns={columns}
                pagingInfo={{
                    rowsPerPage,
                    page,
                    totalCount: paginatedData.TotalCount,
                    handleChangePage,
                    handleChangeRowsPerPage
                }}
                loading={tableLoading}
                listData={paginatedData.ListOut}
                onRowHandler={onHandler}
                onRowCustomHandler={onRowCustomHandler}
            ></HTable>
            {/* Popup Cập nhật thông tin thanh toán */}
            <HDialog
                PaperProps={{
                    sx: {
                        width: "450px",
                    }
                }}
                title="Cập nhật thông tin thanh toán"
                open={isOpenUpdatePaymentDialog}
                handleClose={closeUpdatePaymentDialog}
                dialogFooter={
                    <>
                        <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeUpdatePaymentDialog}>Hủy</HButton>
                        <HButton startIcon={<DoneOutlinedIcon />} onClick={openConfirmDialog}>Xác nhận</HButton>
                    </>
                }
            >
                <FormControl fullWidth sx={{ paddingRight: '8px', marginTop: '16px' }} variant="standard">
                    <LabelControl required>Số tiền</LabelControl>
                    <HTextField
                        fullWidth
                        value={moneyInput}
                        onChange={(event: any) => handleTextInputChange(event, 'money')}
                        placeholder="Nhập số tiền thanh toán"
                        iconEnd={<VNDIcon />}
                    />
                </FormControl>
                <FormControl sx={{ width: '100%', paddingRight: '8px', marginTop: '16px' }} variant="standard">
                    <LabelControl required>Ngày thanh toán</LabelControl>
                    <HDatePicker
                        value={paymentDate}
                        ampm={false}
                        inputFormat="DD/MM/YYYY"
                        disablePast
                        sx={{ width: '100%' }}
                        onChange={(newValue: any) => {
                            setPaymentDate(newValue);
                        }}>
                    </HDatePicker>
                </FormControl>
            </HDialog>
            {/* Popup confirm */}
            <HDialog
                PaperProps={{
                    sx: {
                        width: "450px",
                    }
                }}
                title="Xác nhận"
                open={isOpenConfirmDialog}
                handleClose={closeConfirmDialog}
                dialogFooter={
                    <>
                        <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeConfirmDialog}>Hủy</HButton>
                        <HButton startIcon={<DoneOutlinedIcon />} onClick={savePaymentInfo}>Xác nhận</HButton>
                    </>
                }
            >
                <div style={{ color: COLORS.Gray }}>Bạn có chắc chắn muốn cập nhật lại khoản thanh toán này không?</div>
            </HDialog>
        </Wrapper>
    )
}