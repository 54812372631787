import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { HButton } from '@components/Button';

const Wrapper = styled('div')(() => ({
    width: '100vw',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Space = styled('div')(() => ({
    flex: 1
}));

const NotFound = () => {
    const navigate = useNavigate();
    return (
        <Wrapper>
            <Space />
            <h1>404</h1>
            <HButton onClick={() => navigate('/')}>
                Quay về trang chủ
            </HButton>
            <Space />
        </Wrapper>
    );
};

export default NotFound;
