// import { KEY_STORE } from '@common/keyStore';
import { combineReducers } from '@reduxjs/toolkit';
import localStorage from 'redux-persist/es/storage';
import persistReducer from 'redux-persist/es/persistReducer';
import authReducer from './authReducer';
import globalReducer from './globalReducer';
import directoryReducer from './directoryReducer';

export const hallPersistConfig = {
    key: "TOKEN",
    storage: localStorage,
    whitelist: ['accessToken', 'tokenClaims'],
};
const rootReducer = combineReducers({
    auth: persistReducer(hallPersistConfig, authReducer),
    global: globalReducer,
    directory: directoryReducer
});

export default rootReducer;
