import { Checkbox } from "@mui/material"
import { COLORS } from "@shared/constants/colors"


export const HCheckbox = (props: any) => {
    return (
        <Checkbox
            {...props}
            inputProps={{ 'aria-label': 'controlled' }}
            sx={{
                color: COLORS.LightSilver,
                '&.Mui-checked': {
                    color: COLORS.YankeesBlue,
                },
            }}
        />
    )
}