import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import { LabelControl, Title } from "@shared/styledComponents";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { useNavigate, useParams } from "react-router-dom";
import { Box, FormControl, Grid, SvgIcon, Tooltip } from "@mui/material";
import { Input } from "@components/Input";
import { useEffect, useState } from "react";
import moment from "moment";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { apiClient } from "@services/baseAxios";
import { errorMessages } from "@shared/constants/messages";
import { ApiConfig } from "@config/index";
import { useDispatch } from "react-redux";
import { HTextArea } from "@components/Input/HTextArea";
import { a11yProps, currencyFormat } from "@shared/helpers/common";
import { HTab, HTabs } from "@components/Tab";
import { Column } from "types/common";
import { HTable } from "@components/Table";
import { rowCustomHandlerType } from "@components/Table/constant";
import { ReactComponent as ExclamationIcon } from '@assets/icons/icon-exclamation.svg';
import { HDialog } from "@components/Dialog";

const WrapperCustomerInfo = styled('div')(() => ({
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: COLORS.White
}));

const WrapperService = styled('div')(() => ({
    width: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const productColumns: Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    {
        id: 'ProductName',
        label: 'Tên sản phẩm',
        minWidth: 140,
    },
    {
        id: 'PaymentDate',
        label: 'Ngày mua',
        minWidth: 120,
        align: 'center',
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'Price',
        label: 'Giá bán',
        align: 'right',
        minWidth: 120,
        transform: (data: any) => (data.value ? <span>{currencyFormat(data.value)}đ</span> : '0đ')
    }
];

const servivceColumns: Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    {
        id: 'ServiceName',
        label: 'Tên dịch vụ',
        minWidth: 180,

    },
    {
        id: 'ActualPaidAmount',
        label: 'Tiền thanh toán',
        minWidth: 150,
        align: 'right',
        transform: (data: any) => (data.value ? <span>{currencyFormat(data.value)}đ</span> : '0đ')
    },
    {
        id: 'Price',
        label: 'Giá dịch vụ',
        align: 'right',
        minWidth: 120,
        transform: (data: any) => (data.value ? <span>{currencyFormat(data.value)}đ</span> : '0đ')
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (
            <div className="flex justify-center items-center">
                <Tooltip title="Chi tiết" placement="top">
                    <div onClick={() => value.onCustomHandler(rowCustomHandlerType.DETAIL)} className='flex justify-center items-center cursor-pointer'>
                        <SvgIcon sx={{ width: 16, height: 16, color: COLORS.Green }} component={ExclamationIcon} inheritViewBox />
                    </div>
                </Tooltip>
            </div>
        )
    },
];

const serviceUserProcessColumn: Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    {
        id: 'NoOfProcess',
        label: 'Buổi',
        align: 'right',
        minWidth: 30,
    },
    {
        id: 'DoctorName',
        label: 'Bác sĩ',
        minWidth: 120,
        align: 'left',
    },
    {
        id: 'TreatmentRegimen',
        label: 'Phác đồ điều trị',
        align: 'left',
        minWidth: 180,
    },
    {
        id: 'ConsultingInformation',
        label: 'Tư vấn',
        align: 'left',
        minWidth: 180,
    },
    {
        id: 'CustomerFeedback',
        label: 'Feedback',
        align: 'left',
        minWidth: 180,
    }
];

export const CustomerDetailAdministration = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { id } = useParams();
    const [tabValue, setTabValue] = useState(0);
    const [data, setData] = useState({} as any);
    const [dataTable, setDataTable] = useState([]);
    const [selectedUserService, setSelectedUserService] = useState({} as any);
    const [lstServiceUserProcessById, setLstServiceUserProcessById] = useState([]);
    const [isOpenDetailServiceDialog, setIsOpenDetailServiceDialog] = useState(false);
    const [columns, setColumns] = useState<Array<Column>>(productColumns);

    const handleChangeTabValue = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
        if (newValue === 0) {
            setColumns(productColumns);
            setDataTable(data.ProductUsers)
        }
        else {
            setColumns(servivceColumns);
            setDataTable(data.ServiceUsers)
        }
    };

    const getListServiceUserProcessById = async () => {
        var serviceUserId = selectedUserService.Id;
        if (!serviceUserId) return;

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode, Data, Message } = await apiClient.get(`${ApiConfig.SERVICE_USER_PROCESS}/service-user/${serviceUserId}`);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                setLstServiceUserProcessById(Data);
            }
            else if (AppCode === 500) {
                setLstServiceUserProcessById([]);
                dispatch(storeOpenSnackbar({ open: true, message: Message, type: "error" }));
            }
            else {
                setLstServiceUserProcessById([]);
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const closeDetailServiceDialog = () => {
        setIsOpenDetailServiceDialog(false);
    }

    const onRowCustomHandler = async (row: any, handlerType: any) => {
        setSelectedUserService(row);
        if (handlerType === rowCustomHandlerType.DETAIL) {
            await getListServiceUserProcessById();
            setIsOpenDetailServiceDialog(true);
        }
    }

    const getCustomerById = async () => {
        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode, Data, Message } = await apiClient.get(`${ApiConfig.USER}/customers/${id}`);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                setData(Data);
                setDataTable(Data.ProductUsers);
            }
            else if (AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const displayGender = (genderCode: any) => {
        if (genderCode === 0) {
            return "Nữ";
        }
        else if (genderCode === 1) {
            return "Name";
        }
        return "";
    }

    useEffect(() => {
        getCustomerById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <WrapperCustomerInfo>
                <div className='flex'>
                    <ChevronLeftOutlinedIcon onClick={() => navigate(-1)} className='cursor-pointer' />
                    <Title className='ml-1'>Thông tin khách hàng</Title>
                </div>
                <div className="mt-4">
                    <Grid container>
                        {/* Row 1 */}
                        <Grid sx={{ pt: 0, pl: 0 }} item xs={4}>
                            <FormControl sx={{ width: '100%' }} variant="standard">
                                <LabelControl>Họ và tên</LabelControl>
                                <Input value={data.Fullname || ''} disabled fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                                <LabelControl>Ngày sinh</LabelControl>
                                <Input value={data.DateOfBirth ? moment(data.DateOfBirth).format("DD/MM/YYYY") : '--/--/----'} disabled fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                                <LabelControl>Giới tính</LabelControl>
                                <Input value={displayGender(data.Gender) || ''} disabled fullWidth />
                            </FormControl>
                        </Grid>
                        {/* Row 2 */}
                        <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={4}>
                            <FormControl sx={{ width: '100%' }} variant="standard">
                                <LabelControl>Số điện thoại</LabelControl>
                                <Input value={data.PhoneNumber || ''} disabled fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ marginTop: '16px' }} item xs={4}>
                            <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                                <LabelControl>Email</LabelControl>
                                <Input value={data.Email || ''} disabled fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ marginTop: '16px' }} item xs={4}>
                            <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                                <LabelControl>Hạng khách hàng</LabelControl>
                                <Input value={data.MembershipClass || ''} disabled fullWidth />
                            </FormControl>
                        </Grid>
                        {/* Row 2 */}
                        <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={4}>
                            <FormControl sx={{ width: '100%' }} variant="standard">
                                <LabelControl>Tổng bills đã mua</LabelControl>
                                <Input value={data.TotalValueOfBills ? `${currencyFormat(data.TotalValueOfBills)}đ` : '0đ'} disabled fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ marginTop: '16px' }} item xs={4}>
                            <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                                <LabelControl>Tổng đã thanh toán</LabelControl>
                                <Input value={data.TotalPaid ? `${currencyFormat(data.TotalPaid)}đ` : '0đ'} disabled fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ marginTop: '16px' }} item xs={4}>
                            <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                                <LabelControl>Công nợ</LabelControl>
                                <Input value={data.TotalPaid ? `${currencyFormat(data.TotalValueOfBills - data.TotalPaid)}đ` : '0đ'} disabled fullWidth />
                            </FormControl>
                        </Grid>
                        {/* Row 3 */}
                        <Grid sx={{ marginTop: '16px' }} item xs={4}>
                            <FormControl sx={{ width: '100%' }} variant="standard">
                                <LabelControl>Tỉnh/ Thành phố</LabelControl>
                                <Input value={data.Province?.Name || ''} disabled fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ marginTop: '16px' }} item xs={4}>
                            <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                                <LabelControl>Quận/Huyện</LabelControl>
                                <Input value={data.District?.Name || ''} disabled fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ marginTop: '16px' }} item xs={4}>
                            <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                                <LabelControl>Xã/Phường</LabelControl>
                                <Input value={data.Ward?.Name || ''} disabled fullWidth />
                            </FormControl>
                        </Grid>
                        {/* Row 4 */}
                        <Grid sx={{ marginTop: '16px' }} item xs={12}>
                            <FormControl disabled sx={{ width: '100%' }} variant="standard">
                                <LabelControl>Địa chỉ</LabelControl>
                                <HTextArea
                                    value={data.DetailAddress || ''}
                                    disabled
                                    maxrows={3}
                                    aria-label="maximum height"
                                    readOnly
                                // placeholder="Ý kiến khách hàng"
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </WrapperCustomerInfo>
            <WrapperService className="mt-4">
                <Box sx={{ width: '100%' }}>
                    <HTabs
                        value={tabValue}
                        onChange={handleChangeTabValue}
                        textColor="secondary"
                        indicatorColor="secondary"
                        aria-label="secondary tabs example"
                    >
                        <HTab {...a11yProps(0)} label={`Sản phẩm (${data.ProductUsers ? data.ProductUsers.length : 0})`} />
                        <HTab {...a11yProps(1)} label={`Dịch vụ (${data.ServiceUsers ? data.ServiceUsers.length : 0})`} />
                    </HTabs>
                </Box>
                <div className="mt-4">
                    <HTable
                        keyProperty="Id"
                        columns={columns}
                        onRowCustomHandler={onRowCustomHandler}
                        listData={dataTable}
                    ></HTable>
                </div>
            </WrapperService>
            {/* Popup thông tin chi tiết dịch vụ */}
            <HDialog
                PaperProps={{
                    sx: {
                        width: "1200px",
                        maxWidth: "1200px"
                    }
                }}
                title="Thông tin chi tiết dịch vụ"
                open={isOpenDetailServiceDialog}
                handleClose={closeDetailServiceDialog}
                dialogFooter={
                    <>
                    </>
                }
            >
                <HTable
                    keyProperty="Id"
                    columns={serviceUserProcessColumn}
                    listData={lstServiceUserProcessById}
                ></HTable>
            </HDialog>
        </>
    )
}