import { HAutocomplete } from "@components/Autocomplete";
import { ApiConfig } from "@config/index";
import { Box, FormControl, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiClient } from "@services/baseAxios";
import { COLORS } from "@shared/constants/colors";
import { errorMessages } from "@shared/constants/messages";
import { LabelControl, Title } from "@shared/styledComponents";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { User } from "types/user";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { HTable } from "@components/Table";
import { Column } from "types/common";
import { ReactComponent as DateTimeIcon } from '@assets/icons/icon-datetime.svg';
import SvgIcon from '@mui/icons-material/Menu';
import moment from "moment";
import DoneIcon from '@mui/icons-material/Done';
import { HDialog } from "@components/Dialog";
import { currencyFormat } from "@shared/helpers/common";
import CloseIcon from '@mui/icons-material/Close'
import { HButton } from "@components/Button";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { HDatePicker } from "@components/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { HSelect } from "@components/Select";
import { storeSetSpaLocations } from "@store/directoryReducer";
import { ColumnAction } from "@components/Table/ColumnAction";
import { serviceUserStatus } from "@shared/constants/common";

const Wrapper = styled('div')(() => ({
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: COLORS.White
}));

const TableWrapper = styled('div')(() => ({
    width: '100%',
    padding: '16px',
    marginTop: '16px',
    borderRadius: '8px',
    backgroundColor: COLORS.GhostWhite
}));

const RowFlex = styled('div')(() => ({
    display: "flex",
    alignItems: "center",
    marginTop: '8px'
}));

const columns: readonly Column[] = [
    { id: 'ServiceName', label: 'Tên liệu trình', align: 'left', minWidth: 50 },
    {
        id: 'ActualPaidAmount',
        label: 'Đã thanh toán',
        align: 'right',
        minWidth: 100,
        transform: (data: any) => (data.value ? <span>{currencyFormat(data.value)}đ</span> : '0đ')
    },
    {
        id: 'Price',
        label: 'Giá bán',
        minWidth: 160,
        align: 'right',
        transform: (data: any) => (data.value ? <span>{currencyFormat(data.value)}đ</span> : '0đ')
    },
    {
        id: 'TimeStart',
        label: 'Ngày mua',
        minWidth: 120,
        align: 'center',
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'NoOfServiceUserProcesses',
        label: 'Số buổi đã thực hiện',
        minWidth: 50,
        align: 'right',
        transform: (data: any) => (data.value ?? 0)
    },
    {
        id: 'HaveBooking',
        label: 'Đã có lịch hẹn',
        align: 'center',
        minWidth: 130,
        transform: (data: any) => (data.value ? <DoneIcon sx={{ color: COLORS.YankeesBlue }}></DoneIcon> : <CloseIcon sx={{ color: COLORS.YankeesBlue }}></CloseIcon>)
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 60,
        transform: (value: any) => (<ColumnAction tooltip="Tạo lịch hẹn" onHandler={value.onHandler}><SvgIcon sx={{ width: 16, height: 16 }} component={DateTimeIcon} inheritViewBox /></ColumnAction>)
    },
];

export const BookingCreation = ({ handleCreateSuccess }: any) => {
    const dispatch = useDispatch();
    const { spaLocations } = useSelector((state: any) => state.directory);

    const [selectedLocation, setSelectedLocation] = useState("-1");
    const [bookingDate, setBookingDate] = useState<Dayjs | null>(null);
    const [isOpenBookingDialog, setIsOpenBookingDialog] = useState(false);
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
    const [currentSelectedRow, setCurrentSelectedRow] = useState({ Id: null });
    const [listCustomer, setListCustomer] = useState([]);
    const [listServiceUser, setListServiceUser] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState<User | null>(null);

    useEffect(() => {
        getCustomer("");
        getSpaLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setSelectedLocation(event.target.value);
    };

    const createBooking = async () => {
        if (!bookingDate || !selectedLocation || selectedLocation === '-1') {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng chọn ngày và địa điểm", type: "warning" }));
            return;
        }

        var bookingDateVNTime = dayjs(bookingDate).add(7, 'hours') || null;

        var obj = {
            BookingTime: bookingDateVNTime,
            IsDirty: false,
            ServiceUserId: currentSelectedRow.Id,
            LocationId: selectedLocation
        };

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode, Message } = await apiClient.post(ApiConfig.BOOKING, obj);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                setIsOpenBookingDialog(false);
                searchServiceUser(selectedCustomer!.UserId);
                dispatch(storeOpenSnackbar({ open: true, message: "Tạo lịch hẹn thành công", type: "success" }));
            }
            else if (AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }

    }

    const onHandler = (item: any) => {
        setCurrentSelectedRow(item);
        if (item.HaveBooking) {
            setIsOpenConfirmDialog(true)
        }
        else {
            setIsOpenBookingDialog(true);
        }
    }

    const getCustomer = async (phoneNumber: String) => {
        var res = await apiClient.get(`${ApiConfig.GET_CUSTOMER}?phoneNumber=${phoneNumber}`);
        if (res?.AppCode === 200) {
            setListCustomer(res.Data);
        }
        else {
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const searchServiceUser = async (userId: String) => {
        try {
            var res = await apiClient.post(ApiConfig.SEARCH_SERVICE_USER, {
                UserId: userId,
                Status: serviceUserStatus.DOING.code
            });
            if (res?.AppCode === 200) {
                setListServiceUser(res.Data);
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error) {
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const handleOnChange = (e: React.SyntheticEvent, value: any) => {
        setSelectedCustomer(value);
        searchServiceUser(value.UserId);
    }

    const closeBookingDialog = () => {
        setIsOpenBookingDialog(false);
    }

    const closeConfirmDialog = () => {
        setIsOpenConfirmDialog(false);
    }

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            dispatch(storeSetSpaLocations(res.Data));
        }
        else {
            // setSpaLocations([])
        }
    }

    const renderOption = (props: any, option: any) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.PhoneNumber} {option.Fullname}
        </Box>
    );

    return (
        <>
            <Wrapper>
                <Title className="mb-4">Tạo mới lịch hẹn</Title>
                <FormControl sx={{ width: '450px' }} variant="standard">
                    <LabelControl>Khách hàng</LabelControl>
                    <HAutocomplete
                        placeholder="Chọn số điện thoại khách hàng dể tạo lịch hẹn"
                        options={listCustomer}
                        getOptionLabel={(option: any) => `${option.PhoneNumber} ${option.Fullname}`}
                        renderOption={renderOption}
                        onChange={handleOnChange}
                    />
                </FormControl>
                {
                    selectedCustomer &&
                    <div className="mt-4">
                        <RowFlex>
                            <div style={styles.textLeft}>Họ tên khách hàng:</div>
                            <div style={{ color: COLORS.YankeesBlue, fontWeight: '500' }} className="ml-3">{selectedCustomer?.Fullname}</div>
                        </RowFlex>
                        <RowFlex>
                            <div style={styles.textLeft}>Số điện thoại:</div>
                            <div className="ml-3">{selectedCustomer?.PhoneNumber}</div>
                        </RowFlex>
                        <RowFlex>
                            <div style={styles.textLeft}>Hạng khách hàng:</div>
                            <div style={selectedCustomer?.MembershipClass ? { color: COLORS.Tertiary } : {}} className="ml-3">{selectedCustomer?.MembershipClass || "Standard"}</div>
                        </RowFlex>
                    </div>
                }
            </Wrapper >
            {
                selectedCustomer &&
                <>
                    <TableWrapper>
                        <Title className="mb-4">Liệu trình Của Khách hàng</Title>
                        <HTable
                            columns={columns}
                            // loading={tableLoading}
                            listData={listServiceUser}
                            onRowHandler={onHandler}
                        ></HTable>
                    </TableWrapper>
                    {/* Popup tạo lịch hẹn */}
                    <HDialog
                        PaperProps={{
                            sx: {
                                width: "450px",
                            }
                        }}
                        title="Tạo lịch hẹn"
                        open={isOpenBookingDialog}
                        handleClose={closeBookingDialog}
                        dialogFooter={
                            <>
                                <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeBookingDialog}>Hủy</HButton>
                                <HButton startIcon={<DoneOutlinedIcon />} onClick={createBooking}>Xác nhận</HButton>
                            </>
                        }
                    >
                        <FormControl sx={{ width: '100%', paddingRight: '8px' }} variant="standard">
                            <LabelControl required>Chọn ngày</LabelControl>
                            <HDatePicker
                                value={bookingDate}
                                ampm={false}
                                inputFormat="DD/MM/YYYY"
                                disablePast
                                sx={{ width: '100%' }}
                                onChange={(newValue: any) => {
                                    setBookingDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                        <FormControl fullWidth sx={{ paddingRight: '8px', marginTop: '16px' }} variant="standard">
                            <LabelControl required>Chọn địa điểm</LabelControl>
                            <HSelect fullWidth value={selectedLocation}
                                onChange={handleChangeLocation}>
                                {/* <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem> */}
                                <MenuItem key={-1} disabled value="-1">
                                    <em>Chọn địa điểm</em>
                                </MenuItem>
                                {
                                    spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                }
                            </HSelect>
                        </FormControl>
                    </HDialog>
                    {/* Popup confirm */}
                    <HDialog
                        PaperProps={{
                            sx: {
                                width: "450px",
                            }
                        }}
                        title="Xác nhận"
                        open={isOpenConfirmDialog}
                        handleClose={closeConfirmDialog}
                        dialogFooter={
                            <>
                                <HButton startIcon={<DoneOutlinedIcon />} onClick={closeConfirmDialog}>Xác nhận</HButton>
                            </>
                        }
                    >
                        Dịch vụ này đang tồn tại 1 lịch hẹn, vui lòng kiểm tra lại danh sách lịch hẹn
                    </HDialog>
                </>
            }
        </>
    )
}

const styles = {
    textLeft: {
        width: '125px',
        color: COLORS.Gray,
        fontSize: '14px',
        lineHeight: '20px'
    }
}