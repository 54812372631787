import { Input } from "@components/Input";
import { HSelect } from "@components/Select";
import { HTable } from "@components/Table";
import { FormControl, Grid, ListItem, ListItemButton, ListItemText, MenuItem } from "@mui/material"
import { styled } from '@mui/material/styles';
import { COLORS } from "@shared/constants/colors";
import { LabelControl } from "@shared/styledComponents";
import { Column, PaginatedResult } from "types/common";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { apiClient } from "@services/baseAxios";
import { storeSetSpaLocations } from "@store/directoryReducer";
import { ApiConfig } from "@config/index";
import { ReactComponent as ThreeDotIcon } from '@assets/icons/icon-three-dot.svg';
import SvgIcon from '@mui/icons-material/Menu';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { BOOKING_STATUS_MAPPING, bookingStatus } from "@shared/constants/common";
import { HDialog } from "@components/Dialog";
import { HButton } from "@components/Button";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { eventBus } from "@shared/eventBus";
import { HDatePicker } from "@components/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { TableWrapper, Wrapper } from "./common";

export enum BookingStatusEnum {
    Processing,
    Changing,
    Confirmed,
    Completed
}

const actionMode = {
    EDIT: '1',
    CONFIRM: '2',
    COMPLETE: '3',
    DELETE: '4'
}

const actionProcessingStatus = [
    {
        mode: actionMode.EDIT,
        title: "Sửa lịch hẹn",
    },
    {
        mode: actionMode.CONFIRM,
        title: "Xác nhận lịch hẹn",
    },
    {
        mode: actionMode.DELETE,
        title: "Xóa lịch hẹn",
    }
];

const actionChangingStatus = [
    {
        mode: actionMode.EDIT,
        title: "Sửa lịch hẹn",
    },
    {
        mode: actionMode.CONFIRM,
        title: "Xác nhận lịch hẹn",
    },
    {
        mode: actionMode.DELETE,
        title: "Xóa lịch hẹn",
    }
];

const actionConfirmStatus = [
    {
        mode: actionMode.COMPLETE,
        title: "Hoàn thành lịch hẹn",
    },
    {
        mode: actionMode.DELETE,
        title: "Xóa lịch hẹn",
    }
];

const actionCompletedStatus = [
    {
        mode: actionMode.DELETE,
        title: "Xóa lịch hẹn",
    }
];

const columns: readonly Column[] = [
    {
        id: 'ServiceName',
        label: 'Tên liệu trình',
        align: 'left',
        minWidth: 180
    },
    {
        id: 'CustomerName',
        label: 'Họ tên khách hàng',
        minWidth: 160,
        transform: (data: any) => (<div style={{ fontWeight: 'bold' }}>{data.value}</div>)
    },
    {
        id: 'CustomerPhone',
        label: 'Số điện thoại',
        minWidth: 120,
        align: 'left',
    },
    {
        id: 'MemberShipClass',
        label: 'Hạng khách hàng',
        minWidth: 140,
        align: 'left',
        transform: (data: any) => (data.value ? <span style={{ color: COLORS.Tertiary }}>{data.value}</span> : 'Standard')
    },
    {
        id: 'LocationAddress',
        label: 'Cơ sở',
        align: 'left',
        minWidth: 180
    },
    {
        id: 'BookingTime',
        label: 'Ngày hẹn hiện tại',
        align: 'center',
        minWidth: 180,
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'BookingStatusName',
        label: 'Trạng thái',
        minWidth: 120,
        align: 'left',
        transform: (data: any) => (BOOKING_STATUS_MAPPING[data.value])
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (<ColumnAction row={value.row} onHandler={value.onHandler} />)
    },
];

const Actions = ({ row }: any) => {
    var data = [{}];
    switch (row.BookingStatusName) {
        case bookingStatus.PROCESSING:
            data = actionProcessingStatus;
            break;
        case bookingStatus.CHANGING:
            data = actionChangingStatus;
            break;
        case bookingStatus.CONFIRM:
            data = actionConfirmStatus;
            break;
        case bookingStatus.COMPLETE:
            data = actionCompletedStatus;
            break;
    }

    const onClickItem = (item: any) => {
        eventBus.dispatch("onActionOnRow", { item: item, hoveredRow: row });
    }

    return (
        <div>
            {data.map((item: any, i) => (
                <ListItem key={item.title} disablePadding sx={{ display: 'block' }}>
                    <ListItemButton
                        sx={{
                            minHeight: 48,
                            '&:hover': {
                                backgroundColor: COLORS.Cultured,
                            },
                            borderBottom: i < data.length - 1 ? `1px solid pink` : 'none'
                        }}
                        onClick={() => onClickItem(item)}
                    >
                        <ListItemText primary={item.title} />
                    </ListItemButton>
                </ListItem>
            ))}
        </div>
    );
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: COLORS.YankeesBlue,
        boxShadow: theme.shadows[1],
        fontSize: 11,
        borderRadius: '12px'
    },
}));

const ColumnAction = ({ row, onHandler, ...props }: any) => {
    return (
        <div className="flex justify-center items-center">
            <LightTooltip disableFocusListener title={<Actions row={row} />} placement="left">
                <div style={{ backgroundColor: COLORS.YankeesBlue, width: 32, height: 32, borderRadius: '6px' }} onClick={onHandler} className='flex justify-center items-center cursor-pointer'>
                    <div className='w-full'>
                        <SvgIcon sx={{ width: 16, height: 16 }} component={ThreeDotIcon} inheritViewBox />
                    </div>
                </div>
            </LightTooltip>
        </div>
    )
}

export const AllBookingTab = ({ isReload }: any) => {
    const dispatch = useDispatch();
    const { spaLocations } = useSelector((state: any) => state.directory);

    const [tableLoading, setTableLoading] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [selectedLocation, setSelectedLocation] = useState("-1");
    const [updatedBookingDate, setUpdatedBookingDate] = useState<Dayjs | null>(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);

    const [isOpenDialog, setisOpenDialog] = useState(false);
    const [isOpenUpdateBookingDialog, setIsOpenUpdateBookingDialog] = useState(false);
    const [hoveredRow, setHoveredRow] = useState({} as any);
    const [dialog, setDialog] = useState({
        title: "Tiêu đề",
        content: {} as any,
        footer: <></>
    });

    const handleAfterUpdateSucess = () => {
        closeDialog();
        getData();
    }

    const confirmBooking = async (hoveredRow: any) => {
        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.put(`${ApiConfig.BOOKING}/${hoveredRow.Id}/status`, { BookingStatus: BookingStatusEnum.Confirmed });
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                dispatch(storeOpenSnackbar({
                    open: true, message: "Xác nhận lịch hẹn thành công, tin nhắn xác nhận đã được gửi tới khách hàng", type: "success"
                }));
                handleAfterUpdateSucess();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Xác nhận lịch hẹn thất bại, vui lòng thử lại", type: "error" }));
            }
        }
        catch (e) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const completeBooking = async (hoveredRow: any) => {
        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.put(`${ApiConfig.BOOKING}/${hoveredRow.Id}/status`, { BookingStatus: BookingStatusEnum.Completed });
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                dispatch(storeOpenSnackbar({
                    open: true, message: "Xác nhận hoàn thành liệu trình thành công", type: "success"
                }));
                handleAfterUpdateSucess();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Xác nhận hoàn thành liệu trình thất bại, vui lòng thử lại", type: "error" }));
            }
        }
        catch (e) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const deleteBooking = async (hoveredRow: any) => {
        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.delete(`${ApiConfig.BOOKING}/${hoveredRow.Id}`);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                dispatch(storeOpenSnackbar({
                    open: true, message: "Xoá lịch hẹn thành công", type: "success"
                }));
                handleAfterUpdateSucess();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Xoá lịch hẹn  thất bại, vui lòng thử lại sau", type: "error" }));
            }
        }
        catch (e) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const updateBooking = async () => {
        if (!updatedBookingDate) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng chọn ngày", type: "warning" }));
            return;
        }

        var updatedBookingDateVNTime = dayjs(updatedBookingDate).add(7, 'hours') || null;

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.put(`${ApiConfig.BOOKING}/save/${hoveredRow.Id}`, { BookingTime: updatedBookingDateVNTime });
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                dispatch(storeOpenSnackbar({
                    open: true, message: "Cập nhật lịch hẹn thành công", type: "success"
                }));
                closeUpdateBookingDialog();
                getData();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Cập nhật lịch hẹn thất bại, vui lòng thử lại sau", type: "error" }));
            }
        }
        catch (e) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }


    const closeDialog = () => {
        setisOpenDialog(false);
    }

    const closeUpdateBookingDialog = () => {
        setIsOpenUpdateBookingDialog(false);
        setUpdatedBookingDate(null);
    }

    const onHandleActionOnRow = (data: any) => {
        setHoveredRow(data.hoveredRow);
        switch (data.item.mode) {
            case actionMode.EDIT:
                setIsOpenUpdateBookingDialog(true);
                setUpdatedBookingDate(data.hoveredRow.BookingTime);
                setDialog({
                    title: "Sửa lịch hẹn",
                    content: <></>,
                    footer: <>
                    </>
                })
                break;
            case actionMode.CONFIRM:
                setisOpenDialog(true);
                setDialog({
                    title: "Xác nhận lịch hẹn",
                    content: <div style={{ color: COLORS.OldSilver }}>Xác nhận lịch hẹn của khách hàng và gửi tin nhắn xác nhận lịch tới khách hàng?</div>,
                    footer: <>
                        <HButton startIcon={< CloseOutlinedIcon />} btnType="secondary" onClick={closeDialog}>Hủy</HButton >
                        <HButton startIcon={<DoneOutlinedIcon />} onClick={() => { confirmBooking(data.hoveredRow) }}>Xác nhận</HButton>
                    </>
                })
                break;
            case actionMode.COMPLETE:
                setisOpenDialog(true);
                setDialog({
                    title: "Xác nhận hoàn thành lịch hẹn",
                    content: <div style={{ color: COLORS.OldSilver }}>Xác nhận lịch hẹn đã thực hiện xong?</div>,
                    footer: <>
                        <HButton startIcon={< CloseOutlinedIcon />} btnType="secondary" onClick={closeDialog}>Hủy</HButton >
                        <HButton startIcon={<DoneOutlinedIcon />} onClick={() => { completeBooking(data.hoveredRow) }}>Xác nhận</HButton>
                    </>
                })
                break;
            case actionMode.DELETE:
                setisOpenDialog(true);
                setDialog({
                    title: "Xác nhận xóa",
                    content: <div style={{ color: COLORS.OldSilver }}>Bạn có chắc muốn xoá lịch hẹn này?</div>,
                    footer: <>
                        <HButton startIcon={< CloseOutlinedIcon />} btnType="secondary" onClick={closeDialog}>Hủy</HButton >
                        <HButton startIcon={<DoneOutlinedIcon />} onClick={() => { deleteBooking(data.hoveredRow) }}>Xác nhận</HButton>
                    </>
                })
                break;
        }
    }

    const handleChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value);
    };

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setSelectedLocation(event.target.value);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            dispatch(storeSetSpaLocations(res.Data));
        }
        else {
            // setSpaLocations([])
        }
    }

    const getData = async () => {
        setTableLoading(true);
        const res = await apiClient.post(ApiConfig.GET_BOOKING_PAGING, getObjectFilter());
        setTableLoading(false);
        if (res?.AppCode === 200) {
            setPaginatedData((prevState: PaginatedResult) => ({
                ...prevState,
                ...res.Data
            }));
        }
    }

    const getObjectFilter = () => {
        return {
            Keyword: phoneNumber,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            LocationId: selectedLocation === "-1" ? null : selectedLocation,
        };
    }

    useEffect(() => {
        getSpaLocation();

        eventBus.on("onActionOnRow", (data: any) => {
            onHandleActionOnRow(data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onHandler = () => { }

    useEffect(() => {
        getData();

        eventBus.on("onActionOnRow", (data: any) => {
            onHandleActionOnRow(data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, phoneNumber, selectedLocation, isReload]);

    return (
        <Wrapper>
            {/* Toolbar */}
            <Grid container>
                {/* Row 1 */}
                <Grid item xs={6}>
                    <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                        <LabelControl>Số điện thoại</LabelControl>
                        <Input onChange={handleChangePhoneNumber} value={phoneNumber} sx={{ width: '100%' }} placeholder="Tìm kiếm theo số điện thoại" />
                    </FormControl>
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth variant="standard">
                        <LabelControl>Cơ sở</LabelControl>
                        <HSelect fullWidth value={selectedLocation}
                            onChange={handleChangeLocation}>
                            <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                            {
                                spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                            }
                        </HSelect>
                    </FormControl>
                </Grid>
            </Grid>
            {/* Table */}
            <TableWrapper>
                <HTable
                    columns={columns}
                    pagingInfo={{
                        rowsPerPage,
                        page,
                        totalCount: paginatedData.TotalCount || 0,
                        handleChangePage,
                        handleChangeRowsPerPage
                    }}
                    loading={tableLoading}
                    listData={paginatedData.ListOut}
                    onRowHandler={onHandler}
                ></HTable>
            </TableWrapper>
            {/* Dialog */}
            <HDialog
                PaperProps={{
                    sx: {
                        width: "450px",
                    }
                }}
                title={dialog.title}
                open={isOpenDialog}
                handleClose={closeDialog}
                dialogFooter={
                    dialog.footer
                }
            >
                {dialog.content}
            </HDialog>
            <HDialog
                PaperProps={{
                    sx: {
                        width: "450px",
                    }
                }}
                title={dialog.title}
                open={isOpenUpdateBookingDialog}
                handleClose={closeUpdateBookingDialog}
                dialogFooter={
                    <>
                        <HButton startIcon={< CloseOutlinedIcon />} btnType="secondary" onClick={closeUpdateBookingDialog}>Hủy</HButton >
                        <HButton startIcon={<DoneOutlinedIcon />} onClick={updateBooking}>Xác nhận</HButton>
                    </>
                }
            >
                <FormControl sx={{ width: '100%', paddingRight: '8px' }} variant="standard">
                    <LabelControl required>Chọn ngày</LabelControl>
                    <HDatePicker
                        value={updatedBookingDate}
                        ampm={false}
                        inputFormat="DD/MM/YYYY"
                        disablePast
                        sx={{ width: '100%' }}
                        onChange={(newValue: any) => {
                            setUpdatedBookingDate(newValue);
                        }}>
                    </HDatePicker>
                </FormControl>
            </HDialog>
        </Wrapper>
    )
}