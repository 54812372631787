import { Input } from "@components/Input";
import { FormControl, Grid, MenuItem, SvgIcon } from "@mui/material";
import { LabelControl } from "@shared/styledComponents";
import { Column, PaginatedResult } from "types/common";
import { useEffect, useState } from "react";
import { COLORS } from "@shared/constants/colors";
import { HTable } from "@components/Table";
import { ReactComponent as DateTimeIcon } from '@assets/icons/icon-datetime.svg';
import moment from "moment";
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import { HDialog } from "@components/Dialog";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { HButton } from "@components/Button";
import { HDatePicker } from "@components/DateTimePicker";
import { HSelect } from "@components/Select";
import dayjs, { Dayjs } from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { storeSetSpaLocations } from "@store/directoryReducer";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { TableWrapper, Wrapper } from "./common";
import { ColumnAction } from "@components/Table/ColumnAction";

const columns: readonly Column[] = [
    { id: 'BuyerName', label: 'Họ tên khách hàng', align: 'left', minWidth: 150, transform: (data: any) => (<div style={{ fontWeight: 'bold' }}>{data.value}</div>) },
    {
        id: 'BuyerPhoneNumber',
        label: 'Số điện thoại',
        align: 'right',
        minWidth: 100,
    },
    {
        id: 'BuyerMembershipClass',
        label: 'Hạng khách hàng',
        minWidth: 140,
        align: 'left',
        transform: (data: any) => (data.value ? <span style={{ color: COLORS.Tertiary }}>{data.value}</span> : 'Standard')
    },
    {
        id: 'TimeStart',
        label: 'Ngày mua',
        minWidth: 100,
        align: 'center',
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'ServiceName',
        label: 'Tên dịch vụ',
        minWidth: 150,
        align: 'left',
        transform: (data: any) => (data.value ?? 0)
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (<ColumnAction tooltip="Tạo lịch hẹn" onHandler={value.onHandler}><SvgIcon sx={{ width: 16, height: 16 }} component={DateTimeIcon} inheritViewBox /></ColumnAction>)
    },
];

export const NotYetBookingTab = () => {
    const dispatch = useDispatch();

    const { spaLocations } = useSelector((state: any) => state.directory);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [bookingDate, setBookingDate] = useState<Dayjs | null>(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState("-1");
    const [currentSelectedRow, setCurrentSelectedRow] = useState({ Id: null });
    const [isOpenBookingDialog, setIsOpenBookingDialog] = useState(false);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);

    const handleChangePhoneNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhoneNumber(event.target.value);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            dispatch(storeSetSpaLocations(res.Data));
        }
        else {
            // setSpaLocations([])
        }
    }

    const createBooking = async () => {
        if (!bookingDate || !selectedLocation || selectedLocation === '-1') {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng chọn ngày và địa điểm", type: "warning" }));
            return;
        }

        var bookingDateVNTime = dayjs(bookingDate).add(7, 'hours') || null;

        var obj = {
            BookingTime: bookingDateVNTime,
            IsDirty: false,
            ServiceUserId: currentSelectedRow.Id,
            LocationId: selectedLocation
        };

        try {
            dispatch(storeSetGlobalLoading(true));
            var res = await apiClient.post(ApiConfig.BOOKING, obj);
            dispatch(storeSetGlobalLoading(false));
            if (res?.AppCode === 200) {
                closeBookingDialog();
                dispatch(storeOpenSnackbar({ open: true, message: "Tạo lịch hẹn thành công", type: "success" }));
                getData();
            }
            else {
                dispatch(storeSetGlobalLoading(false));
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }

        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const getObjectFilter = () => {
        return {
            Keyword: phoneNumber,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            IsHaveBooking: false
        };
    }

    const getData = async () => {
        setTableLoading(true);
        const res = await apiClient.post(ApiConfig.GET_SERVICE_USER_PAGING, getObjectFilter());
        setTableLoading(false);
        if (res?.AppCode === 200) {
            setPaginatedData((prevState: PaginatedResult) => ({
                ...prevState,
                ...res.Data
            }));
        }
    }

    const closeBookingDialog = () => {
        setIsOpenBookingDialog(false);
    }

    const onHandler = (item: any) => {
        setCurrentSelectedRow(item);
        setIsOpenBookingDialog(true);
    }

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setSelectedLocation(event.target.value);
    };

    useEffect(() => {
        getData();
        getSpaLocation();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, phoneNumber]);

    return (
        <Wrapper>
            {/* Toolbar */}
            <Grid container>
                {/* Row 1 */}
                <Grid item xs={6}>
                    <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                        <LabelControl>Số điện thoại</LabelControl>
                        <Input onChange={handleChangePhoneNumber} value={phoneNumber} sx={{ width: '100%' }} placeholder="Tìm kiếm theo số điện thoại" />
                    </FormControl>
                </Grid>
            </Grid>
            {/* Table */}
            <TableWrapper>
                <HTable
                    columns={columns}
                    pagingInfo={{
                        rowsPerPage,
                        page,
                        totalCount: paginatedData.TotalCount || 0,
                        handleChangePage,
                        handleChangeRowsPerPage
                    }}
                    loading={tableLoading}
                    listData={paginatedData.ListOut}
                    onRowHandler={onHandler}
                ></HTable>
            </TableWrapper>
            {/* Popup tạo lịch hẹn */}
            <HDialog
                PaperProps={{
                    sx: {
                        width: "450px",
                    }
                }}
                title="Tạo lịch hẹn"
                open={isOpenBookingDialog}
                handleClose={closeBookingDialog}
                dialogFooter={
                    <>
                        <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeBookingDialog}>Hủy</HButton>
                        <HButton startIcon={<DoneOutlinedIcon />} onClick={createBooking}>Xác nhận</HButton>
                    </>
                }
            >
                <FormControl sx={{ width: '100%', paddingRight: '8px' }} variant="standard">
                    <LabelControl required>Chọn ngày</LabelControl>
                    <HDatePicker
                        value={bookingDate}
                        ampm={false}
                        inputFormat="DD/MM/YYYY"
                        disablePast
                        sx={{ width: '100%' }}
                        onChange={(newValue: any) => {
                            setBookingDate(newValue);
                        }}>
                    </HDatePicker>
                </FormControl>
                <FormControl fullWidth sx={{ paddingRight: '8px', marginTop: '16px' }} variant="standard">
                    <LabelControl required>Chọn địa điểm</LabelControl>
                    <HSelect fullWidth value={selectedLocation}
                        onChange={handleChangeLocation}>
                        {/* <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem> */}
                        <MenuItem key={-1} disabled value="-1">
                            <em>Chọn địa điểm</em>
                        </MenuItem>
                        {
                            spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                        }
                    </HSelect>
                </FormControl>
            </HDialog>
        </Wrapper>
    );
}