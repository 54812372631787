import { Input } from "@components/Input";
import { HTable } from "@components/Table";
import { FormControl, Grid, SvgIcon, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import { LabelControl, Title } from "@shared/styledComponents";
import { Column, PaginatedResult } from "types/common";
import { useEffect, useState } from "react";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import { errorMessages } from "@shared/constants/messages";
import { useDispatch } from "react-redux";
import { rowCustomHandlerType } from "@components/Table/constant";
import { ReactComponent as GrayEditIcon } from '@assets/icons/icon-edit-gray.svg';
import { ReactComponent as GrayBucketIcon } from '@assets/icons/icon-bucket-gray.svg';
import { HButton } from "@components/Button";
import AddIcon from '@mui/icons-material/Add';
import { LocationForm } from "./locationForm";
import { HDialog } from "@components/Dialog";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';

const Wrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    padding: "16px 0px"
}));

const columns: Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    {
        id: 'Name',
        label: 'Tên',
        align: 'left',
        minWidth: 100,
    },
    {
        id: 'Code',
        label: 'Mã',
        align: 'left',
        minWidth: 40,
    },
    {
        id: 'Address',
        label: 'Địa chỉ',
        minWidth: 200,
        align: 'left',
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (
            <div className="flex justify-center items-center">
                <Tooltip title="Sửa" placement="left">
                    <div onClick={() => value.onCustomHandler(rowCustomHandlerType.EDIT)} className='flex justify-center items-center cursor-pointer'>
                        <SvgIcon sx={{ width: 16, height: 16, color: COLORS.Green }} component={GrayEditIcon} inheritViewBox />
                    </div>
                </Tooltip>
                <Tooltip className="ml-2" title="Xóa" placement="top">
                    <div onClick={() => value.onCustomHandler(rowCustomHandlerType.DELETE)} className='flex justify-center items-center cursor-pointer'>
                        <SvgIcon sx={{ width: 16, height: 16, color: COLORS.Green }} component={GrayBucketIcon} inheritViewBox />
                    </div>
                </Tooltip>
            </div>
        )
    },
];

export const LocationAdministration = () => {
    const dispatch = useDispatch();

    const [keyword, setKeyword] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableLoading, setTableLoading] = useState(false);
    const [isCreateLocation, setIsCreateLocation] = useState(false);
    const [locationFormTitle, setLocationFormTitle] = useState("Sửa cơ sở");
    const [selectedRow, setSelectedRow] = useState({} as any);
    const [isOpenLocationForm, setIsOpenLocationForm] = useState(false);
    const [isOpenConfirmDeleteLocationDialog, setIsOpenConfirmDeleteLocationDialog] = useState(false);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);

    const onRowCustomHandler = (row: any, handlerType: any) => {
        setSelectedRow(row);
        if (handlerType === rowCustomHandlerType.EDIT) {
            setLocationFormTitle("Sửa cơ sở");
            setIsCreateLocation(false);
            setIsOpenLocationForm(true);
        }
        else if (handlerType === rowCustomHandlerType.DELETE) {
            setIsOpenConfirmDeleteLocationDialog(true);
        }
    }

    const closeConfirmDeleteDialog = () => {
        setIsOpenConfirmDeleteLocationDialog(false);
    }

    const deleteLocation = async () => {
        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.delete(`${ApiConfig.LOCAION}/${selectedRow.Id}`);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                closeConfirmDeleteDialog();
                dispatch(storeOpenSnackbar({ open: true, message: "Xóa cơ sở thành công", type: "success" }));
                getLocationPaging();
            }
            else if (AppCode === 409) {
                dispatch(storeOpenSnackbar({ open: true, message: "Dữ liệu về cơ sở đã được phát sinh ở nhiều nơi, không thể xóa cơ sở này", type: "error" }));
            }
            else if (AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: "Xóa cơ sở thất bại", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Xóa người dùng thất bại", type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const getObjectFilter = () => {
        return {
            Keyword: keyword,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
        };
    }

    const getLocationPaging = async () => {
        try {
            setTableLoading(true);
            var { AppCode, Data } = await apiClient.post(ApiConfig.GET_SPA_LOCATION_PAGING, getObjectFilter());
            setTableLoading(false);
            if (AppCode === 200) {
                setPaginatedData((prevState: PaginatedResult) => ({
                    ...prevState,
                    ...Data
                }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const handleSubmitSuccess = () => {
        handleCloseForm();
        var msg = isCreateLocation ? "Tạo cơ sở thành công" : "Sửa cơ sở thành công";
        dispatch(storeOpenSnackbar({ open: true, message: msg, type: "success" }));
        getLocationPaging();
    }

    const handleCloseForm = () => {
        setIsOpenLocationForm(false);
        setSelectedRow({})
    }

    const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    useEffect(() => {
        getLocationPaging();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, keyword]);

    return (
        <Wrapper>
            <div className="w-full flex justify-between">
                <Title>Danh sách cơ sở</Title>
                <HButton startIcon={<AddIcon />} onClick={() => {
                    setLocationFormTitle("Tạo cơ sở")
                    setIsCreateLocation(true);
                    setIsOpenLocationForm(true);
                }}>Tạo cơ sở</HButton>
            </div>
            <ToolbarWrapper>
                <Grid container>
                    <Grid sx={{ pt: 0, pl: 0 }} item xs={6}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Tên hoặc mã</LabelControl>
                            <Input onChange={handleChangeKeyword} value={keyword} sx={{ width: '100%', marginTop: '4px' }} placeholder="Tìm kiếm theo tên hoặc mã" />
                        </FormControl>
                    </Grid>
                </Grid>
            </ToolbarWrapper>
            <HTable
                columns={columns}
                pagingInfo={{
                    rowsPerPage,
                    page,
                    totalCount: paginatedData.TotalCount,
                    handleChangePage,
                    handleChangeRowsPerPage
                }}
                loading={tableLoading}
                listData={paginatedData.ListOut}
                onRowCustomHandler={onRowCustomHandler}
            ></HTable>
            {
                isOpenLocationForm &&
                <LocationForm
                    isOpen={isOpenLocationForm}
                    isCreateNew={isCreateLocation}
                    title={locationFormTitle}
                    locationData={selectedRow}
                    handleOnClose={handleCloseForm}
                    handleOnConfirm={handleSubmitSuccess}
                >
                </LocationForm>
            }
            {/* Popup confirm xóa user */}
            <HDialog
                PaperProps={{
                    sx: {
                        width: "450px",
                    }
                }}
                title="Xóa cơ sở"
                open={isOpenConfirmDeleteLocationDialog}
                handleClose={closeConfirmDeleteDialog}
                dialogFooter={
                    <>
                        <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeConfirmDeleteDialog}>Hủy</HButton>
                        <HButton startIcon={<DeleteOutlineIcon />} onClick={deleteLocation}>Xóa</HButton>
                    </>
                }
            >
                Bạn chắc chắn muốn cơ sở này chứ?
            </HDialog>
        </Wrapper>
    )
}