import { HButton } from "@components/Button";
import { HDatePicker } from "@components/DateTimePicker";
import { Input } from "@components/Input";
import { HSelect } from "@components/Select";
import { ApiConfig } from "@config/index";
import { FormControl, Grid, MenuItem } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiClient } from "@services/baseAxios";
import { COLORS } from "@shared/constants/colors";
import { MONTH_DATA } from "@shared/constants/common";
import { errorMessages } from "@shared/constants/messages";
import { LabelControl, Title } from "@shared/styledComponents";
import { storeSetAllRole } from "@store/directoryReducer";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { Editor } from "@tinymce/tinymce-react";
import { Dayjs } from "dayjs";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import RefreshIcon from '@mui/icons-material/Refresh';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';

const Wrapper = styled('div')(() => ({
  width: '100%',
  backgroundColor: COLORS.GhostWhite,
  padding: '16px',
  display: 'flex',
  flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
  paddingTop: "16px"
}));

export const EmailAdministration = () => {
  const dispatch = useDispatch();
  const { allRole } = useSelector((state: any) => state.directory);
  const editorRef = useRef({} as any);
  const [fromDate, setFromDate] = useState<Dayjs | null>(null);
  const [toDate, setToDate] = useState<Dayjs | null>(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [selectedReceiver, setSelectedReceiver] = useState('');
  const [selectedBirthMonth, setSelectedBirthMonth] = useState('-1');

  // const log = () => {
  //   if (editorRef.current) {
  //     console.log(editorRef.current.getContent());
  //   }
  // };

  const handleChangeBirthMonth = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedBirthMonth(event.target.value);
  };

  const getAllRole = async () => {
    if (allRole?.length > 0) return;

    const { AppCode, Data } = await apiClient.get(ApiConfig.GET_ALL_ROLE);
    if (AppCode === 200) {
      dispatch(storeSetAllRole(Data));
    }
    else {
      dispatch(storeSetAllRole([]));
    }
  }

  const handleChangeReceiver = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedReceiver(event.target.value);
  };

  const handleChangeTitle = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(event.target.value);
  };

  const uploadFileToService = async (file: any) => {
    if (!file) {
      dispatch(storeOpenSnackbar({ open: true, message: "Thông tin đẩy lên sai định dạng", type: "error" }));
    }

    let formData = new FormData();
    formData.append("file", file);
    try {
      dispatch(storeSetGlobalLoading(true));
      var { AppCode, Data } = await apiClient.post(`${ApiConfig.FILE}`, formData, { headers: { "Content-Type": "multipart/form-data" } });
      dispatch(storeSetGlobalLoading(false));
      if (AppCode === 200) {
        return Data;
      }
      else {
        dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
      }
    } catch (error: any) {
      dispatch(storeSetGlobalLoading(false));
      if (error?.response?.data?.AppCode === 500) {
        dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
      }
      else if (error?.response?.data?.AppCode === 400) {
        dispatch(storeOpenSnackbar({ open: true, message: "Thông tin đẩy lên sai định dạng", type: "error" }));
      }
      else {
        dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
      }
      return '';
    }
    return "";
  }

  const handleOnRefresh = () => {
    if (editorRef.current) {
      editorRef.current.setContent('');
      setContent('');
    }
    setTitle('');
  }

  const handleSendEmail = () => {
    if (!title || !content) {
      dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng nhập đủ thông tin", type: "warning" }));
    }

    const obj = {
      Filter: {
        ReceiverRoleId: selectedReceiver || null,
        BirthMonth: selectedBirthMonth === "-1" ? null : selectedBirthMonth,
        FromDate: fromDate,
        ToDate: toDate
      },
      Title: title,
      Content: content
    }

    console.log("handleSendEmail", obj);
  }

  useEffect(() => {
    getAllRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Wrapper>
        <Title>Tạo Email chương trình khuyến mãi</Title>
        <ToolbarWrapper>
          <Grid container>
            <Grid sx={{ paddingRight: '8px' }} item xs={3}>
              <FormControl sx={{ width: '100%' }} variant="standard">
                <LabelControl>Đối tượng nhận</LabelControl>
                <HSelect value={selectedReceiver || "-1"}
                  onChange={handleChangeReceiver}>
                  <MenuItem key={-1} disabled value="-1">
                    <em style={{ color: COLORS.Gray }}>Chọn đối tượng nhận</em>
                  </MenuItem>
                  {
                    allRole.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                  }
                </HSelect>
              </FormControl>
            </Grid>
            <Grid sx={{ paddingRight: '8px' }} item xs={3}>
              <FormControl sx={{ width: '100%' }} variant="standard">
                <LabelControl>Tháng sinh nhật</LabelControl>
                <HSelect value={selectedBirthMonth || "-1"}
                  onChange={handleChangeBirthMonth}>
                  <MenuItem key={-1} disabled value="-1">
                    <em style={{ color: COLORS.Gray }}>Chọn tháng sinh nhật</em>
                  </MenuItem>
                  {
                    MONTH_DATA.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                  }
                </HSelect>
              </FormControl>
            </Grid>
            <Grid sx={{ paddingRight: '8px' }} item xs={3}>
              <FormControl sx={{ width: '100%' }} variant="standard">
                <LabelControl>Từ ngày</LabelControl>
                <HDatePicker
                  value={fromDate}
                  ampm={false}
                  inputFormat="DD/MM/YYYY"
                  onChange={(newValue: any) => {
                    setFromDate(newValue);
                  }}>
                </HDatePicker>
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl sx={{ width: '100%' }} variant="standard">
                <LabelControl>Đến ngày</LabelControl>
                <HDatePicker
                  value={toDate}
                  placeholder="Từ ngày"
                  inputFormat="DD/MM/YYYY"
                  ampm={false}
                  onChange={(newValue: any) => {
                    setToDate(newValue);
                  }}>
                </HDatePicker>
              </FormControl>
            </Grid>
          </Grid>
        </ToolbarWrapper>
      </Wrapper>
      <div className="w-full p-4 pt-8" style={{ backgroundColor: COLORS.White }}>
        <Grid container>
          <Grid item xs={12}>
            <FormControl sx={{ width: '100%' }} variant="standard">
              <LabelControl required>Tiêu đề</LabelControl>
              <Input onChange={handleChangeTitle} value={title} placeholder="Nhập nội dung muốn thông báo tới khách hàng" />
            </FormControl>
          </Grid>
          <Grid sx={{ marginTop: '16px' }} item xs={12}>
            <FormControl sx={{ width: '100%' }} variant="standard">
              <LabelControl required>Nội dung</LabelControl>
              <Editor
                apiKey="0cy0uuk80sn7ui4d4slz4pak7048ffxfyyooq02fjap8ra7b"
                onInit={(evt, editor) => (editorRef.current = editor)}
                init={{
                  height: 500,
                  menubar: true,
                  plugins: [
                    "advlist",
                    "autolink",
                    "lists",
                    "link",
                    "image",
                    "charmap",
                    "preview",
                    "image",
                    "anchor",
                    "searchreplace",
                    "visualblocks",
                    "code",
                    "fullscreen",
                    "insertdatetime",
                    "media",
                    "table",
                    "code",
                    "help",
                    "wordcount",
                  ],
                  toolbar:
                    "undo redo | blocks | " +
                    "link image " +
                    "code " +
                    "bold italic forecolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                  image_title: true,
                  file_picker_types: 'image',
                  setup: function (editor) {
                    editor.on('init', function (e) {
                      console.log('The Editor has initialized.');
                    });
                    editor.on('keyup', function (e) {
                      setContent(editor.getContent())
                    });
                    editor.on('change', function (e) {
                      setContent(editor.getContent())
                    });
                  },
                  placeholder: "Nhập nội dung muốn thông báo tới khách hàng",
                  automatic_uploads: true,
                  file_picker_callback: (cb, value, meta) => {
                    const input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');

                    input.addEventListener('change', (e: any) => {
                      const file = e.target.files[0];

                      const reader = new FileReader();
                      reader.addEventListener('load', async () => {
                        /*
                          Note: Now we need to register the blob in TinyMCEs image blob
                          registry. In the next release this part hopefully won't be
                          necessary, as we are looking to handle it internally.
                        */
                        // const id = 'blobid' + (new Date()).getTime();
                        // const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                        // const base64 = reader.result.split(',')[1];
                        // const blobInfo = blobCache.create(id, file, base64);
                        // blobCache.add(blobInfo);

                        // /* call the callback and populate the Title field with the file name */
                        // cb(blobInfo.blobUri(), { title: file.name });
                        var imageUrl = await uploadFileToService(file);
                        if (!imageUrl) {
                          dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
                        }
                        else {
                          cb(imageUrl, { title: file.name })
                        }
                      });
                      reader.readAsDataURL(file);
                    });

                    input.click();
                  },
                }}
              />
            </FormControl>
          </Grid>
          <Grid sx={{ marginTop: '16px' }} item xs={12}>
            <div className="flex justify-end">
              <HButton startIcon={<RefreshIcon />} btnType="secondary" onClick={handleOnRefresh}>Làm mới</HButton>
              <HButton sx={{ marginLeft: '16px' }} startIcon={<SendOutlinedIcon />} onClick={handleSendEmail}>Gửi</HButton>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
};
