import { Box, Tab, Tabs } from '@mui/material';
import { styled } from '@mui/material/styles';
import { COLORS } from '@shared/constants/colors';

export const HTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        backgroundColor: COLORS.YankeesBlue,
    },
});

export const HTab = styled((props: any) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
        // textTransform: 'none',
        minWidth: 0,
        [theme.breakpoints.up('sm')]: {
            minWidth: 0,
        },
        borderBottomColor: COLORS.YankeesBlue,
        fontWeight: theme.typography.fontWeightRegular,
        marginRight: theme.spacing(1),
        '&:hover': {
            color: COLORS.Independence,
            opacity: 1,
        },
        '&.Mui-selected': {
            color: COLORS.YankeesBlue,
            fontWeight: theme.typography.fontWeightMedium,
        },
        '&.Mui-focusVisible': {
            backgroundColor: '#d1eaff',
        },
    }),
);

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    {children}
                </Box>
            )}
        </div>
    );
}