import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FlexEndWrapper, LabelControl, Title } from "@shared/styledComponents";
import { FormControl, Grid } from "@mui/material";
import { Input } from "@components/Input";
import { HButton } from "@components/Button";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages, successMessages } from "@shared/constants/messages";
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import moment from "moment";
import dayjs, { Dayjs } from 'dayjs';
import { HDatePicker } from "@components/DateTimePicker";
import { bookingStatus } from "@shared/constants/common";

const Wrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: COLORS.White
}));

export const BookingDetail = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [data, setData] = useState({} as any);
    const [bookingTime, setBookingTime] = useState<Dayjs | null>(null);
    const { id } = useParams();
    let [searchParams] = useSearchParams();

    useEffect(() => {
        getBookingById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getBookingById = async () => {
        dispatch(storeSetGlobalLoading(true));
        const res = await apiClient.get(`${ApiConfig.BOOKING}/${id}`);
        dispatch(storeSetGlobalLoading(false));
        if (res?.AppCode === 200) {
            setData(res.Data);
            setBookingTime(res.Data.BookingTime);
        }
        else {
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const handleUpdate = async () => {
        if (!bookingTime) {
            dispatch(storeOpenSnackbar({ open: true, message: "Bạn phải chọn giờ", type: "warning" }));
        }

        dispatch(storeSetGlobalLoading(true));
        const vnTime = dayjs(bookingTime).add(7, 'hours');

        const url = searchParams.get('type') === bookingStatus.CHANGING ? `${ApiConfig.BOOKING}/save-changing/${id}` : `${ApiConfig.BOOKING}/save/${id}`
        const res = await apiClient.put(url, {
            BookingTime: vnTime
        });
        dispatch(storeSetGlobalLoading(false));
        if (res?.AppCode === 200) {
            dispatch(storeOpenSnackbar({ open: true, message: successMessages.UPDATE_COMMON, type: "success" }));
            navigate(-1);
        }
        else {
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.UPDATE_FAIL, type: "error" }));
        }
    };

    return (
        <Wrapper>
            <div className='flex w-full'>
                <ChevronLeftOutlinedIcon onClick={() => navigate(-1)} className='cursor-pointer' />
                <Title className='ml-1'>Cập Nhật Lịch Hẹn</Title>
            </div>
            <div className="mt-4">
                <Grid container>
                    {/* Row 1 */}
                    <Grid sx={{ paddingRight: '8px' }} item xs={6}>
                        <FormControl disabled fullWidth variant="standard">
                            <LabelControl>Tên khách hàng</LabelControl>
                            <Input value={data.CustomerName || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingLeft: '8px' }} item xs={3}>
                        <FormControl disabled fullWidth variant="standard">
                            <LabelControl>Số điện thoại</LabelControl>
                            <Input value={data.CustomerPhone || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingLeft: '16px' }} item xs={3}>
                        <FormControl disabled fullWidth variant="standard">
                            <LabelControl>Hạng khách hàng</LabelControl>
                            <Input value={data.MemberShipClass || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    {/* Row 2 */}
                    <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={6}>
                        <FormControl disabled fullWidth variant="standard">
                            <LabelControl>Tên liệu trình</LabelControl>
                            <Input value={data.ServiceName || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingLeft: '8px', marginTop: '16px' }} item xs={6}>
                        <FormControl disabled fullWidth variant="standard">
                            <LabelControl>Cơ sở</LabelControl>
                            <Input value={data.LocationAddress || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    {/* Row 3 */}
                    <Grid sx={{ paddingRight: data.BookingStatusName === bookingStatus.CHANGING ? '8px' : '0', marginTop: '16px' }} item xs={data.BookingStatusName === bookingStatus.CHANGING ? 6 : 12}>
                        <FormControl fullWidth variant="standard">
                            <LabelControl>Ngày hẹn thực tế</LabelControl>
                            <HDatePicker
                                value={bookingTime}
                                ampm={false}
                                disablePast
                                onChange={(newValue: any) => {
                                    setBookingTime(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                    {
                        data.BookingStatusName === bookingStatus.CHANGING &&
                        <Grid sx={{ paddingLeft: '8px', marginTop: '16px' }} item xs={6}>
                            <FormControl fullWidth variant="standard">
                                <LabelControl>Ngày hẹn mong muốn đổi</LabelControl>
                                <Input value={data.BookingTimeProposal ? moment(data.BookingTimeProposal).format("DD/MM/YYYY") : ''} disabled fullWidth />
                            </FormControl>
                        </Grid>
                    }
                </Grid>
                <FlexEndWrapper className="mt-4">
                    <HButton startIcon={<ArrowBackIcon />} btnType="secondary" onClick={() => navigate(-1)}>Quay lại</HButton>
                    <HButton sx={{ marginLeft: '16px' }} startIcon={<CheckIcon />} onClick={handleUpdate}>Cập nhật</HButton>
                </FlexEndWrapper>
            </div>
        </Wrapper>
    )
}