import { HDialog } from "@components/Dialog"
import { Input } from "@components/Input"
import { HTextArea } from "@components/Input/HTextArea"
import { FormControl, Grid } from "@mui/material"
import { CUSTOMER_FEEDBACK_DATA } from "@shared/constants/common"
import { LabelControl } from "@shared/styledComponents"

export const FeedbackDetailForm = ({ title, isOpen, data, handleOnClose }: any) => {
    const getCustomerRateName = (data: Number) => {
        return CUSTOMER_FEEDBACK_DATA.find(x => x.Id === data)?.Name || "";
    }
    return (
        <HDialog
            PaperProps={{
                sx: {
                    width: "750px",
                    maxWidth: "750px",
                }
            }}
            title={title}
            open={isOpen}
            handleClose={handleOnClose}
            dialogFooter={
                <>
                </>
            }
        >
            <Grid container>
                <Grid sx={{ pt: 0, pl: 0 }} item xs={12}>
                    <FormControl disabled sx={{ width: '100%' }} variant="standard">
                        <LabelControl>Tên khách hàng</LabelControl>
                        <Input disabled value={data.CustomerName} />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl disabled sx={{ width: '100%' }} variant="standard">
                        <LabelControl>Số điện thoại</LabelControl>
                        <Input disabled value={data.CustomerPhone} />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl disabled sx={{ width: '100%' }} variant="standard">
                        <LabelControl>Hạng khách hàng</LabelControl>
                        <Input disabled value={data.MemberShipClass} />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl disabled sx={{ width: '100%' }} variant="standard">
                        <LabelControl>Tên dịch vụ</LabelControl>
                        <Input disabled value={data.ServiceName} />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl disabled sx={{ width: '100%' }} variant="standard">
                        <LabelControl>Buổi</LabelControl>
                        <Input disabled value={data.NoOfProcess} />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl disabled sx={{ width: '100%' }} variant="standard">
                        <LabelControl>Nội dung Feedback</LabelControl>
                        {/* <Input disabled value={data.CustomerFeedback} /> */}
                        <HTextArea
                            disabled
                            defaultValue={data.CustomerFeedback || ""}
                        />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl disabled sx={{ width: '100%' }} variant="standard">
                        <LabelControl>Mức độ hài lòng</LabelControl>
                        <Input disabled value={getCustomerRateName(data.CustomerRate)} />
                    </FormControl>
                </Grid>
            </Grid>
        </HDialog>
    )
}