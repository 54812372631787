import { HButton } from "@components/Button";
import { HDialog } from "@components/Dialog";
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FormControl, Grid, MenuItem } from "@mui/material";
import { LabelControl } from "@shared/styledComponents";
import { Input } from "@components/Input";
import { useEffect, useState } from "react";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { useDispatch, useSelector } from "react-redux";
import { ApiConfig } from "@config/index";
import { apiClient } from "@services/baseAxios";
import { HDatePicker } from "@components/DateTimePicker";
import { HSelect } from "@components/Select";
import { GENDER, MEMBERSHIP_CLASS } from "@shared/constants/common";
import { storeSetAllProvince, storeSetAllRole, storeSetSpaLocations } from "@store/directoryReducer";
import dayjs from "dayjs";
import { HTextField } from "@components/Input/HTextFiled";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

export const UserForm = ({ title, userId, isOpen, handleOnClose, handleOnConfirm, isCreateUser }: any) => {
    const dispatch = useDispatch();
    const [user, setUser] = useState({} as any);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordAgain, setShowPasswordAgain] = useState(false);
    const [districtsByProvince, setDistrictsByProvince] = useState([]);
    const [wardsByDistrict, setWardsByDistrict] = useState([]);
    const { spaLocations, allProvince, allRole } = useSelector((state: any) => state.directory);

    const getAllRole = async () => {
        if (allRole?.length > 0) return;

        const { AppCode, Data } = await apiClient.get(ApiConfig.GET_ALL_ROLE);
        if (AppCode === 200) {
            dispatch(storeSetAllRole(Data));
        }
        else {
            dispatch(storeSetAllRole([]));
        }
    }

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            dispatch(storeSetSpaLocations(res.Data));
        }
        else {
            dispatch(storeSetSpaLocations([]));
        }
    }

    const handleChangeFullname = (event: any) => {
        setUser((prevState: any) => ({
            ...prevState,
            ...{ Fullname: event.target.value }
        }));
    }

    const handleChangeEmail = (event: any) => {
        setUser((prevState: any) => ({
            ...prevState,
            ...{ Email: event.target.value }
        }));
    }

    const handleChangeGender = (event: { target: { value: string } }) => {
        setUser((prevState: any) => ({
            ...prevState,
            ...{ Gender: event.target.value }
        }));
    };

    const handleChangeMembership = (event: { target: { value: string } }) => {
        setUser((prevState: any) => ({
            ...prevState,
            ...{ MembershipClass: event.target.value }
        }));
    };

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setUser((prevState: any) => ({
            ...prevState,
            ...{ LocationId: event.target.value }
        }));
    };

    const getAllProvice = async () => {
        if (allProvince?.length > 0) return;

        const { AppCode, Data } = await apiClient.get(ApiConfig.GET_ALL_PROVINCE);
        if (AppCode === 200) {
            dispatch(storeSetAllProvince(Data));
        }
        else {
            dispatch(storeSetAllProvince([]));
        }
    }

    const getUserById = async () => {
        try {
            if (!userId || isCreateUser) {
                return;
            }

            dispatch(storeSetGlobalLoading(true));
            var { AppCode, Data } = await apiClient.get(`${ApiConfig.USER}/${userId}`);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                Data.ProvinceId = Data.Province?.Id || null;
                Data.DistrictId = Data.District?.Id || null;
                Data.WardId = Data.Ward?.Id || null;
                setUser(Data);
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const getDistrictByProvinceId = async (provinceId: string) => {
        try {
            var { AppCode, Data } = await apiClient.get(`${ApiConfig.GET_DISTRICT}?provinceId=${provinceId}`);
            if (AppCode === 200) {
                // dispatch(storeSetDistrictsByProvince(Data));
                setDistrictsByProvince(Data);
            }
            else {
                setDistrictsByProvince([]);
            }
        } catch (error) {

        }
    }

    const getWardByDistrictId = async (districtId: string) => {
        try {
            var { AppCode, Data } = await apiClient.get(`${ApiConfig.GET_WARD}?districtId=${districtId}`);
            if (AppCode === 200) {
                setWardsByDistrict(Data);
            }
            else {
                setWardsByDistrict([]);
            }
        } catch (error) {

        }
    }

    const handleChangePhoneNumber = (event: any) => {
        setUser((prevState: any) => ({
            ...prevState,
            ...{ PhoneNumber: event.target.value }
        }));
    }

    const handleChangePassword = (event: any) => {
        setUser((prevState: any) => ({
            ...prevState,
            ...{ Password: event.target.value }
        }));
    }

    const handleChangePasswordAgain = (event: any) => {
        setUser((prevState: any) => ({
            ...prevState,
            ...{ PasswordAgain: event.target.value }
        }));
    }

    const setBirthDay = (newValue: any) => {
        setUser((prevState: any) => ({
            ...prevState,
            ...{ DateOfBirth: newValue }
        }));
    }

    const handleChangeProvice = (event: { target: { value: string } }) => {
        setUser((prevState: any) => ({
            ...prevState,
            ...{ ProvinceId: event.target.value, DistrictId: null, WardId: null }
        }));
    }

    const handleChangeDistrict = (event: { target: { value: string } }) => {
        setUser((prevState: any) => ({
            ...prevState,
            ...{ DistrictId: event.target.value, WardId: null }
        }));
    }

    const handleChangeWard = (event: { target: { value: string } }) => {
        setUser((prevState: any) => ({
            ...prevState,
            ...{ WardId: event.target.value }
        }));
    }

    const handleChangeRole = (event: { target: { value: string } }) => {
        setUser((prevState: any) => ({
            ...prevState,
            ...{ RoleId: event.target.value }
        }));
    };

    const setup = async () => {
        await getUserById();
        getAllProvice();
        getAllRole();
        getSpaLocation();
    }

    const handleSubmit = () => {
        if (isCreateUser) {
            createUser();
        }
        else {
            updateUser();
        }
    }

    const createUser = async () => {
        var isValidated = true;
        // let errorMsg = "Vui lòng điền ";
        let listFieldName = [];
        if (!user.Fullname) {
            listFieldName.push("'họ và tên'");
            isValidated = false;
        }
        if (!user.PhoneNumber) {
            listFieldName.push("'số điện thoại'");
            isValidated = false;
        }
        if (!user.Password) {
            listFieldName.push("'mật khẩu'");
            isValidated = false;
        }
        if (!user.RoleId) {
            listFieldName.push("'vai trò'");
            isValidated = false;
        }

        if (!isValidated) {
            let errorMsg = "Vui lòng điền " + listFieldName.join(", ");
            dispatch(storeOpenSnackbar({ open: true, message: errorMsg, type: "warning" }));
            return;
        }

        if (user.Password !== user.PasswordAgain) {
            dispatch(storeOpenSnackbar({ open: true, message: "Mật khẩu nhập lại không trùng khớp", type: "warning" }));
            return;
        }

        var obj = {
            FullName: user.Fullname,
            PhoneNumber: user.PhoneNumber,
            Password: user.Password,
            DateOfBirth: user.DateOfBirth ? dayjs(user.DateOfBirth).add(7, 'hours') : null,
            RoleIds: [user.RoleId],
            Gender: user.Gender,
            Email: user.Email,
            ProvinceId: user.ProvinceId || null,
            DistrictId: user.DistrictId || null,
            WardId: user.WardId || null,
            LocationId: user.LocationId || null
        }

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.post(`${ApiConfig.USER}?isForCustomer=false`, obj);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                handleOnConfirm();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else if (error?.response?.data?.AppCode === 400) {
                dispatch(storeOpenSnackbar({ open: true, message: "Thông tin đẩy lên sai định dạng", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const updateUser = async () => {
        if (!user.Fullname) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng điền họ và tên", type: "warning" }));
            return;
        }

        var obj = {
            UserId: user.UserId,
            Fullname: user.Fullname,
            DateOfBirth: user.DateOfBirth ? dayjs(user.DateOfBirth).add(7, 'hours') : null,
            Gender: user.Gender,
            Email: user.Email,
            ProvinceId: user.ProvinceId || null,
            DistrictId: user.DistrictId || null,
            WardId: user.WardId || null,
            MembershipClass: user.MembershipClass
        }

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.put(`${ApiConfig.USER}`, obj);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                handleOnConfirm();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else if (error?.response?.data?.AppCode === 400) {
                dispatch(storeOpenSnackbar({ open: true, message: "Thông tin đẩy lên sai định dạng", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const toggleDisplayPassword = () => {
        setShowPassword(!showPassword);
    }

    const toggleDisplayPasswordAgain = () => {
        setShowPasswordAgain(!showPasswordAgain);
    }

    useEffect(() => {
        setup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (user.ProvinceId != null) {
            getDistrictByProvinceId(user.ProvinceId);
        }
    }, [user.ProvinceId])

    useEffect(() => {
        if (user.DistrictId != null) {
            getWardByDistrictId(user.DistrictId);
        }
    }, [user.DistrictId])

    return (
        <HDialog
            PaperProps={{
                sx: {
                    width: "800px",
                    maxWidth: '800px'
                }
            }}
            title={title}
            open={isOpen}
            handleClose={handleOnClose}
            dialogFooter={
                <>
                    <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={handleOnClose}>Hủy</HButton>
                    <HButton startIcon={<DoneOutlinedIcon />} onClick={handleSubmit}>Xác nhận</HButton>
                </>
            }
        >
            <Grid container>
                {/* Row 1 */}
                <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Họ và tên</LabelControl>
                        <Input onChange={handleChangeFullname} value={user.Fullname} fullWidth placeholder="Nhập họ và tên" />
                    </FormControl>
                </Grid>
                <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                    <FormControl disabled={isCreateUser ? false : true} sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Số điện thoại</LabelControl>
                        <Input placeholder="Nhập số điện thoại" onChange={handleChangePhoneNumber} disabled={isCreateUser ? false : true} value={user.PhoneNumber} fullWidth />
                    </FormControl>
                </Grid>
                <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required={isCreateUser ? true : false}>Email</LabelControl>
                        <Input onChange={handleChangeEmail} value={user.Email} fullWidth placeholder="Nhập email" />
                    </FormControl>
                </Grid>
                {/* Row for create user */}
                {
                    isCreateUser &&
                    <>
                        <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={6}>
                            <LabelControl required className="mb-2">Mật khẩu</LabelControl>
                            <HTextField
                                fullWidth
                                value={user.Password}
                                onChange={handleChangePassword}
                                type={showPassword ? "text" : "password"}
                                placeholder="Nhập mật khẩu"
                                iconEnd={showPassword ? <VisibilityIcon onClick={toggleDisplayPassword}
                                    className="cursor-pointer" /> : <VisibilityOffIcon
                                    onClick={toggleDisplayPassword}
                                    className="cursor-pointer" />}
                            />
                        </Grid>
                        <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={6}>
                            <LabelControl required className="mb-2">Xác nhận lại mật khẩu</LabelControl>
                            <HTextField
                                fullWidth
                                value={user.PasswordAgain}
                                onChange={handleChangePasswordAgain}
                                type={showPasswordAgain ? "text" : "password"}
                                placeholder="Nhập lại mật khẩu"
                                iconEnd={showPasswordAgain ? <VisibilityIcon onClick={toggleDisplayPasswordAgain}
                                    className="cursor-pointer" /> : <VisibilityOffIcon
                                    onClick={toggleDisplayPasswordAgain}
                                    className="cursor-pointer" />}
                            />
                        </Grid>
                    </>
                }
                {/* Row 2 */}
                <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={6}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl>Ngày sinh</LabelControl>
                        <HDatePicker
                            value={user.DateOfBirth || null}
                            ampm={false}
                            inputFormat="DD/MM/YYYY"
                            sx={{ width: '100%' }}
                            onChange={(newValue: any) => {
                                setBirthDay(newValue);
                            }}
                        >
                        </HDatePicker>
                    </FormControl>
                </Grid>
                <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={6}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl>Giới tính</LabelControl>
                        <HSelect fullWidth value={user.Gender ?? "-1"}
                            onChange={handleChangeGender}>
                            {/* <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem> */}
                            <MenuItem key={-1} disabled value="-1">
                                <em>Chọn giới tính</em>
                            </MenuItem>
                            {
                                GENDER.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                            }
                        </HSelect>
                    </FormControl>
                </Grid>
                {/* Row 3 */}
                <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={6}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        {isCreateUser ?
                            <>
                                <LabelControl required>Vai trò</LabelControl>
                                <HSelect value={user.RoleId || "-1"}
                                    onChange={handleChangeRole}>
                                    <MenuItem key={-1} disabled value="-1">
                                        <em>Chọn vai trò</em>
                                    </MenuItem>
                                    {
                                        allRole.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                    }
                                </HSelect>
                            </>
                            :
                            <>
                                <LabelControl>Hạng khách hàng</LabelControl>
                                <HSelect fullWidth value={user.MembershipClass ?? "-1"}
                                    onChange={handleChangeMembership}>
                                    <MenuItem key={-1} disabled value="-1">
                                        <em>Chọn hạng khách hàng</em>
                                    </MenuItem>
                                    {
                                        MEMBERSHIP_CLASS.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                    }
                                </HSelect>
                            </>
                        }
                    </FormControl>
                </Grid>
                <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={6}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl>Cơ sở</LabelControl>
                        <HSelect value={user.LocationId ?? "-1"}
                            onChange={handleChangeLocation}>
                            <MenuItem key={-1} disabled value="-1">
                                <em>Chọn cơ sở</em>
                            </MenuItem>
                            {
                                spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                            }
                        </HSelect>
                    </FormControl>
                </Grid>
                {/* Row 4 */}
                <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={4}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl>Tỉnh/Thành Phố</LabelControl>
                        <HSelect value={user.ProvinceId ?? "-1"}
                            onChange={handleChangeProvice}>
                            <MenuItem key={-1} disabled value="-1">
                                <em>Chọn Tỉnh/Thành Phố</em>
                            </MenuItem>
                            {
                                allProvince.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                            }
                        </HSelect>
                    </FormControl>
                </Grid>
                <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={4}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl>Quận/Huyện</LabelControl>
                        <HSelect value={user.DistrictId ?? "-1"}
                            onChange={handleChangeDistrict}>
                            <MenuItem key={-1} disabled value="-1">
                                <em>Chọn Quận/Huyện</em>
                            </MenuItem>
                            {
                                districtsByProvince.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                            }
                        </HSelect>
                    </FormControl>
                </Grid>
                <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={4}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl>Xã/Phường</LabelControl>
                        <HSelect value={user.WardId ?? "-1"}
                            onChange={handleChangeWard}>
                            <MenuItem key={-1} disabled value="-1">
                                <em>Chọn Xã/Phường</em>
                            </MenuItem>
                            {
                                wardsByDistrict.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                            }
                        </HSelect>
                    </FormControl>
                </Grid>
            </Grid>
        </HDialog>
    );
}