import { COLORS } from "@shared/constants/colors"

export const ChipStatus = ({ type, text, ...props }: any) => {
    return (
        <span
            className='flex content-center justify-center'
            style={{
                borderRadius: '8px',
                paddingLeft: '8px',
                paddingRight: '8px',
                fontWeight: 'bold',
                // Xanh nước biển
                ...(type === 1 && {
                    backgroundColor: COLORS.Water,
                    color: COLORS.BrilliantAzure
                }),
                // Xanh lá cây
                ...((type === 2) && {
                    backgroundColor: COLORS.Bubbles,
                    color: COLORS.Eucalyptus
                }),
                // Đỏ
                ...((type === 3) && {
                    backgroundColor: COLORS.LavenderBlush,
                    color: COLORS.MagicPotion
                }),
                // Vàng
                ...((type === 4) && {
                    backgroundColor: COLORS.BlanchedAlmond,
                    color: COLORS.Maize
                }),
            }}>
            {text}
        </span>
    )
}