import { ApiConfig } from "@config/index";
import { styled } from "@mui/material/styles";
import { apiClient } from "@services/baseAxios";
import { COLORS } from "@shared/constants/colors";
import { errorMessages } from "@shared/constants/messages";
import { LabelControl, Title } from "@shared/styledComponents";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { FormControl, Grid } from "@mui/material";
import { Input } from "@components/Input";
import { currencyFormat } from "@shared/helpers/common";
import { SERVICE_USER_STATUS_MAPPING } from "@shared/constants/common";
import moment from "moment";
import { HTable } from "@components/Table";
import { Column } from "types/common";
import { ColumnAction } from "@components/Table/ColumnAction";
import SvgIcon from '@mui/icons-material/Menu';
import { ReactComponent as EditIcon } from '@assets/icons/icon-edit.svg';
import { HTextField } from "@components/Input/HTextFiled";
import { HDialog } from "@components/Dialog";
import { HDatePicker } from "@components/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { HButton } from "@components/Button";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { ReactComponent as VNDIcon } from '@assets/icons/icon-vnd.svg';

const ServiceWrapper = styled('div')(() => ({
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: COLORS.White
}));

const TableWrapper = styled('div')(() => ({
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: COLORS.GhostWhite,
}));

const columns: readonly Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    {
        id: 'PaymentDate',
        label: 'Ngày thanh toán',
        align: 'left',
        minWidth: 150,
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'Amount',
        label: 'Đã thanh toán',
        align: 'right',
        minWidth: 150,
        transform: (data: any) => (<span style={{ color: COLORS.MagicPotion }}>{currencyFormat(data.value)}đ</span>)
    },
    {
        id: 'Price',
        label: 'Giá dịch vụ',
        minWidth: 150,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.Eucalyptus }}>{currencyFormat(data.value)}đ</span>)
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (<ColumnAction tooltip="Sửa thông tin thanh toán" onHandler={value.onHandler}><SvgIcon sx={{ width: 16, height: 16 }} component={EditIcon} inheritViewBox /></ColumnAction>)
    },
];

export const PaymentServiceDetail = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const [paymentData, setPaymentData] = useState({} as any);
    const [listPaymentDetail, setListPaymentDetail] = useState([] as any);
    const [isOpenConfirmDialog, setIsOpenConfirmDialog] = useState(false);
    const [isOpenUpdatePaymentDialog, setIsOpenUpdatePaymentDialog] = useState(false);
    const [paymentDate, setPaymentDate] = useState<Dayjs | null>(null);
    const [moneyInput, setMoneyInput] = useState('');
    const [currentSelectedRow, setCurrentSelectedRow] = useState({ ServicePaymentDetailId: null });
    const navigate = useNavigate();

    const getPaymentDetailById = async () => {
        try {
            dispatch(storeSetGlobalLoading(true));
            const { AppCode, Data } = await apiClient.get(`${ApiConfig.PAYMENT}/${id}`);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                setPaymentData(Data)
                let paymentDetails = Data.ListDetailPayment;
                paymentDetails.forEach((e: any) => {
                    e.Price = Data.Price
                });
                setListPaymentDetail(Data.ListDetailPayment);
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const handleTextInputChange = (event: any, field: string) => {
        const value = event.target.value;
        switch (field) {
            case 'money':
                var a = value.replace(/\D/g, "");
                var data = currencyFormat(parseInt(a)).toString();

                setMoneyInput(data);
                break;
        }
    }

    const onHandler = (item: any) => {
        setCurrentSelectedRow(item);
        setIsOpenUpdatePaymentDialog(true);
        setPaymentDate(dayjs(new Date()));
        setMoneyInput(currencyFormat(item.Amount).toString());
    }

    const savePaymentInfo = async () => {
        const data = {
            Id: currentSelectedRow.ServicePaymentDetailId,
            PaymentDate: paymentDate,
            Amount: parseInt(moneyInput.replace(/\D/g, '')),
            ServicePaymentId: id
        }

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.post(ApiConfig.PAYMENT, data);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                dispatch(storeOpenSnackbar({ open: true, message: "Sửa thông tin thanh toán thành công", type: "success" }));
                closeConfirmDialog();
                getPaymentDetailById();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Sửa thông tin thanh toán thất bại", type: "error" }));
            }
        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const closeConfirmDialog = () => {
        setIsOpenConfirmDialog(false);
    }

    const openConfirmDialog = () => {
        if (!paymentDate || !moneyInput) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng nhập đủ thông tin", type: "warning" }));
            return;
        }

        setIsOpenUpdatePaymentDialog(false);
        setIsOpenConfirmDialog(true);
    }

    const closeUpdatePaymentDialog = () => {
        setIsOpenUpdatePaymentDialog(false);
    }

    useEffect(() => {
        getPaymentDetailById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            <ServiceWrapper>
                <div className='flex w-full'>
                    <ChevronLeftOutlinedIcon onClick={() => navigate(-1)} className='cursor-pointer' />
                    <Title className='ml-1'>Thông tin dịch vụ khách hàng</Title>
                </div>
                <div className="mt-4">
                    <Grid container>
                        {/* Row 1 */}
                        <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                            <FormControl disabled fullWidth variant="standard">
                                <LabelControl>Tên khách hàng</LabelControl>
                                <Input value={paymentData.CustomerName || ''} disabled fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                            <FormControl disabled fullWidth variant="standard">
                                <LabelControl>Số điện thoại</LabelControl>
                                <Input disabled value={paymentData.Phone || ''} fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl disabled fullWidth variant="standard">
                                <LabelControl>Hạng khách hàng</LabelControl>
                                <Input disabled value={paymentData.MembershipClass || ''} fullWidth />
                            </FormControl>
                        </Grid>
                        {/* Row 2 */}
                        <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={4}>
                            <FormControl disabled fullWidth variant="standard">
                                <LabelControl>Tên liệu trình</LabelControl>
                                <Input disabled value={paymentData.ServiceName || ''} fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={4}>
                            <FormControl disabled fullWidth variant="standard">
                                <LabelControl>Cở sở</LabelControl>
                                <Input disabled value={paymentData.LocationName || ''} fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ marginTop: '16px' }} item xs={4}>
                            <FormControl disabled fullWidth variant="standard">
                                <LabelControl>Trạng thái liệu trình</LabelControl>
                                <Input disabled value={SERVICE_USER_STATUS_MAPPING[paymentData.ServiceUserStatusName] || ''} fullWidth />
                            </FormControl>
                        </Grid>
                        {/* Row 3 */}
                        <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={3}>
                            <FormControl disabled fullWidth variant="standard">
                                <LabelControl>Ngày bắt đầu</LabelControl>
                                <Input disabled value={paymentData.TimeStart ? moment(paymentData.TimeStart).format("DD/MM/YYYY") : ''} fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ paddingRight: '8px', marginTop: '16px' }} item xs={3}>
                            <FormControl disabled fullWidth variant="standard">
                                <LabelControl>Ngày kết thúc</LabelControl>
                                <Input disabled value={paymentData.TimeEnd ? moment(paymentData.TimeEnd).format("DD/MM/YYYY") : ''} fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ marginTop: '16px', paddingRight: '8px' }} item xs={3}>
                            <FormControl disabled fullWidth variant="standard">
                                <LabelControl>Đã thanh toán</LabelControl>
                                <Input disabled value={`${currencyFormat(paymentData.ActualPayment)}đ` || ''} fullWidth />
                            </FormControl>
                        </Grid>
                        <Grid sx={{ marginTop: '16px' }} item xs={3}>
                            <FormControl disabled fullWidth variant="standard">
                                <LabelControl>Giá dịch vụ</LabelControl>
                                <Input disabled value={`${currencyFormat(paymentData.Price)}đ` || ''} fullWidth />
                            </FormControl>
                        </Grid>
                    </Grid>
                </div>
            </ServiceWrapper>
            <TableWrapper className="mt-4">
                <div className="flex mb-4">
                    <Title>Thanh toán cho liệu trình </Title><span style={{ color: COLORS.Tertiary }} className="ml-1">{paymentData.ServiceName}</span>
                </div>
                <HTable
                    columns={columns}
                    keyProperty="ServicePaymentDetailId"
                    listData={listPaymentDetail}
                    onRowHandler={onHandler}
                ></HTable>
                {/* Popup Cập nhật thông tin thanh toán */}
                <HDialog
                    PaperProps={{
                        sx: {
                            width: "450px",
                        }
                    }}
                    title="Cập nhật thông tin thanh toán"
                    open={isOpenUpdatePaymentDialog}
                    handleClose={closeUpdatePaymentDialog}
                    dialogFooter={
                        <>
                            <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeUpdatePaymentDialog}>Hủy</HButton>
                            <HButton startIcon={<DoneOutlinedIcon />} onClick={openConfirmDialog}>Xác nhận</HButton>
                        </>
                    }
                >
                    <FormControl fullWidth sx={{ paddingRight: '8px', marginTop: '16px' }} variant="standard">
                        <LabelControl required>Số tiền</LabelControl>
                        <HTextField
                            fullWidth
                            value={moneyInput}
                            onChange={(event: any) => handleTextInputChange(event, 'money')}
                            placeholder="Nhập số tiền thanh toán"
                            iconEnd={<VNDIcon />}
                        />
                    </FormControl>
                    <FormControl sx={{ width: '100%', paddingRight: '8px', marginTop: '16px' }} variant="standard">
                        <LabelControl required>Ngày thanh toán</LabelControl>
                        <HDatePicker
                            value={paymentDate}
                            ampm={false}
                            inputFormat="DD/MM/YYYY"
                            disablePast
                            sx={{ width: '100%' }}
                            onChange={(newValue: any) => {
                                setPaymentDate(newValue);
                            }}>
                        </HDatePicker>
                    </FormControl>
                </HDialog>
                {/* Popup confirm */}
                <HDialog
                    PaperProps={{
                        sx: {
                            width: "450px",
                        }
                    }}
                    title="Xác nhận"
                    open={isOpenConfirmDialog}
                    handleClose={closeConfirmDialog}
                    dialogFooter={
                        <>
                            <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeConfirmDialog}>Hủy</HButton>
                            <HButton startIcon={<DoneOutlinedIcon />} onClick={savePaymentInfo}>Xác nhận</HButton>
                        </>
                    }
                >
                    <div style={{ color: COLORS.Gray }}>Bạn có chắc chắn muốn cập nhật lại khoản thanh toán này không?</div>
                </HDialog>
            </TableWrapper>
        </>

    )
}