import { styled } from '@mui/material/styles';
import { ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar } from "@mui/material";
import { COLORS } from '@shared/constants/colors';
import { images } from '@assets/imgs';
import { ReactComponent as Logo } from '@assets/icons/logo.svg';
import SvgIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as BellIcon } from '@assets/icons/icon-bell.svg';
import { useRef, useState } from 'react';
import { ReactComponent as UserIcon } from '@assets/icons/icon-user.svg';
import { ReactComponent as KeyIcon } from '@assets/icons/icon-key.svg';
import { ReactComponent as LogoutIcon } from '@assets/icons/icon-logout.svg';
import { routes } from '@shared/constants/routeNames';
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from '@hooks/useDispatch/useAppDispatch';
import { useAppSelector } from "@hooks/useSelector/useAppSelector";
import { logout } from '@store/authReducer';
import { useOutside } from '@hooks/useClickOut';
import { roleNameMapping } from '@shared/constants/common';

const ToolbarWrapper = styled(Toolbar, { shouldForwardProp: (prop) => prop !== 'open' })(
    () => ({
        backgroundColor: COLORS.White,
        boxShadow: 'none',
        height: '68px',
        justifyContent: 'space-between',
    }),
);

const UserInfor = styled('div')(() => ({
    color: COLORS.Primary,
    float: 'right',
}));

const RightSection = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const ToolbarDropdown = ({ toggleHandler, ...props }: any) => {
    const StyledDropdown = styled('div')(() => ({
        position: 'absolute',
        backgroundColor: COLORS.White,
        top: 40,
        right: 20,
        borderRadius: '12px',
        zIndex: 5,
        boxShadow: '0px 2px 15px rgba(51, 51, 51, 0.15)'
    }));

    const wrapperRef = useRef(null);
    useOutside(wrapperRef, toggleHandler);

    return <StyledDropdown ref={wrapperRef}>{props.children}</StyledDropdown>
}

const logoutItemIndex = 3;
const menu = [
    {
        index: 1,
        title: "Thông tin tài khoản",
        icon: UserIcon,
        route: routes.USER_INFO
    },
    {
        index: 2,
        title: "Đổi mật khẩu",
        icon: KeyIcon,
        route: routes.USER_INFO
    },
    {
        index: logoutItemIndex,
        title: "Đăng xuất",
        icon: LogoutIcon,
    }
];

export const AppToolbar = (props: any) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    var { tokenClaims } = useAppSelector(state => state.auth);
    const [open, setOpen] = useState(false);


    const toggleDropdown = () => {
        setOpen(!open)
    }

    const onClickItem = (item: any) => {
        setOpen(!open);

        if (item.index === logoutItemIndex) {
            dispatch(logout());
            localStorage.clear();
            navigate(routes.LOGIN);
        }
        else if (item.route) {
            navigate(item.route);
        }
    }

    return (
        <ToolbarWrapper {...props}>
            <div className='flex'>
                <SvgIcon sx={{ width: 24, height: 24, marginRight: '8px' }} component={Logo} inheritViewBox />
                <img src={images.brand} alt="brand" />
            </div>
            <RightSection>
                <SvgIcon sx={{ width: '18x', height: '18px', marginRight: '8px', cursor: 'pointer' }} component={BellIcon} inheritViewBox />
                <img src={images.avatar} style={{ marginRight: '8px' }} alt="" />
                <UserInfor>
                    <div style={styles.contentHorizontalCenter}>
                        <span>{tokenClaims.full_name}</span>
                        <ExpandMoreIcon onClick={() => toggleDropdown()} sx={{ cursor: 'pointer', marginLeft: '4px', color: COLORS.Secondary }} />
                        {open &&
                            <ToolbarDropdown toggleHandler={() => toggleDropdown()}>
                                {menu.map((item) => (
                                    <ListItem key={item.title} disablePadding sx={{ display: 'block' }}>
                                        <ListItemButton
                                            sx={{
                                                minHeight: 48,
                                                px: '24px',
                                                '&:hover': {
                                                    backgroundColor: COLORS.Cultured,
                                                },
                                                borderBottom: item.index < menu.length ? `1px solid pink` : 'none'
                                            }}
                                            onClick={() => onClickItem(item)}
                                        >
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 0,
                                                    justifyContent: 'center',
                                                }}
                                            >
                                                <SvgIcon sx={styles.iconMenu} component={item.icon} inheritViewBox />
                                            </ListItemIcon>
                                            <ListItemText className='pl-3' primary={item.title} />
                                        </ListItemButton>
                                    </ListItem>
                                ))}
                            </ToolbarDropdown>
                        }
                    </div>
                    <div style={{ color: COLORS.Gray, fontSize: '12px', display: 'flex', justifyContent: 'flex-start', marginTop: '2px' }}>{tokenClaims.phone_number} - {roleNameMapping[tokenClaims.role]}</div>
                </UserInfor>
            </RightSection>
        </ToolbarWrapper>
    )
}

const styles = {
    contentHorizontalCenter: {
        display: 'flex',
        justifyContent: 'center'
    },
    iconMenu: {
        fontSize: '16px'
    }
}

