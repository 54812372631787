import { Dialog, DialogActions, DialogTitle, Slide } from "@mui/material"
import { TransitionProps } from "@mui/material/transitions";
import { forwardRef } from "react";
import { AppBar, IconButton, Toolbar } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { COLORS } from "@shared/constants/colors";
import { styled } from "@mui/material/styles";
import { HDialogTitle } from "@shared/styledComponents";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogFooter = styled('div')(() => ({
    position: 'absolute',
    bottom: 0,
    width: '100vw',
    height: '60px',
    borderTop: `1px solid ${COLORS.YankeesBlue}`,
}));

const DialogContent = styled('div')(() => ({
    flexGrow: 1,
    overflow: 'auto',
}));


export const HFullScreenDialog = ({
    open,
    title,
    handleClose,
    dialogHeader,
    dialogFooter,
    children,
    isHaveFooter,
    ...props }: any) => {
    return (
        <Dialog
            {...props}
            open={open}
            fullScreen
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            {/* Header */}
            <AppBar sx={{ height: `${dialogHeaderHeight}px`, position: 'relative', backgroundColor: COLORS.White }}>
                <Toolbar sx={{}} className="flex justify-between">
                    <div style={styles.dialogTitle}>{title}</div>
                    <IconButton
                        edge="start"
                        sx={{ color: COLORS.YankeesBlue }}
                        aria-label="close"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            {/* Content */}
            <DialogContent style={isHaveFooter ? { marginBottom: '60px' } : {}}>
                {children}
            </DialogContent>
            {/* Footer */}
            {
                isHaveFooter &&
                <DialogFooter>
                    {dialogFooter}
                </DialogFooter>
            }
        </Dialog>
    )
}

export const HDialog = ({ title, open, children, handleClose, dialogFooter, ...props }: any) => {
    return (
        <Dialog
            {...props}
            TransitionComponent={Transition}
            open={open}
            onClose={handleClose}
        >
            <DialogTitle sx={{ padding: '16px' }}>
                <div className="flex items-center justify-between">
                    <HDialogTitle>
                        {title}
                    </HDialogTitle>
                    <IconButton
                        edge="start"
                        sx={{ color: COLORS.YankeesBlue }}
                        aria-label="close"
                        onClick={handleClose}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent sx={{ padding: '16px', paddingTop: 0 }}>
                {children}
            </DialogContent>
            <DialogActions sx={{ px: 3, pb: 2 }} >
                {dialogFooter}
            </DialogActions>
        </Dialog>
    )
}

const dialogHeaderHeight = 64;

const styles = {
    dialogTitle: {
        fontSize: '20px',
        fontWeight: '500',
        lineHeight: '24px',
        color: COLORS.YankeesBlue
    }
}