import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";

export const Wrapper = styled('div')(() => ({
    padding: "16px 0px",
    overflow: 'auto'
}));

export const TableWrapper = styled('div')(() => ({
    marginTop: '16px',
    backgroundColor: COLORS.GhostWhite,
    flexGrow: 1,
    overflow: "auto"
}));