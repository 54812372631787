import { useNavigate, useParams } from "react-router-dom";
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import { FlexEndWrapper, LabelControl, Title } from "@shared/styledComponents";
import { COLORS } from "@shared/constants/colors";
import { styled } from "@mui/material/styles";
import { FormControl, Grid } from "@mui/material";
import { Input } from '@components/Input';
import { HTextArea } from "@components/Input/HTextArea";
import { HButton } from "@components/Button";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CheckIcon from '@mui/icons-material/Check';
import { apiClient } from "@services/baseAxios";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { ApiConfig } from "@config/index";
import { errorMessages, successMessages } from "@shared/constants/messages";
import { STATUS_MAPPING } from "@shared/constants/common";
import moment from 'moment';

const Wrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: COLORS.White
}));

export const TreatmentSessionDetail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();
    const [feedback, setFeedback] = useState("");
    const [data, setData] = useState({} as any);

    useEffect(() => {
        getServiceUserProcess();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getServiceUserProcess = async () => {
        dispatch(storeSetGlobalLoading(true));
        const res = await apiClient.get(`${ApiConfig.SERVICE_USER_PROCESS}/${id}`);
        dispatch(storeSetGlobalLoading(false));
        if (res?.AppCode === 200) {
            setData(res.Data);
            setFeedback(res.Data.CustomerFeedback);
        }
        else {
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const changeFeedback = (event: any) => {
        setFeedback(event.target.value);
    }
    const handleUpdate = async () => {
        dispatch(storeSetGlobalLoading(true));
        try {
            var res = await apiClient.put(`/service-user-processes/${id}/feedback`, { CustomerFeedBack: feedback });
            dispatch(storeSetGlobalLoading(false));

            if (res?.AppCode === 200) {
                dispatch(storeOpenSnackbar({ open: true, message: successMessages.UPDATE_COMMON, type: "success" }));
                navigate(-1);
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.UPDATE_FAIL, type: "error" }));
            }
        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    return (
        <Wrapper>
            <div className='flex'>
                <ChevronLeftOutlinedIcon onClick={() => navigate(-1)} className='cursor-pointer' />
                <Title className='ml-1'>Chi Tiết Buổi Điều Trị</Title>
            </div>
            <div className="mt-4">
                <Grid container>
                    {/* Row 1 */}
                    <Grid sx={{ pt: 0, pl: 0 }} item xs={6}>
                        <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl>Tên khách hàng</LabelControl>
                            <Input value={data.CustomerName || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                            <LabelControl>Số điện thoại</LabelControl>
                            <Input value={data.CustomerPhone || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                            <LabelControl>Hạng khách hàng</LabelControl>
                            <Input value={data.MemberShipClass || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    {/* Row 2 */}
                    <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={6}>
                        <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl>Tên dịch vụ</LabelControl>
                            <Input value={data.ServiceName || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ marginTop: '16px' }} item xs={6}>
                        <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                            <LabelControl>Cơ sở</LabelControl>
                            <Input value={data.LocationName || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    {/* Row 3 */}
                    <Grid sx={{ marginTop: '16px' }} item xs={6}>
                        <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl>Ngày thực hiện</LabelControl>
                            <Input value={data.TreatmentDate ? moment(data.TreatmentDate).format("DD/MM/YYYY") : ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ marginTop: '16px' }} item xs={6}>
                        <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                            <LabelControl>Trạng thái liệu trình</LabelControl>
                            <Input value={data.Status ? STATUS_MAPPING[data.Status] : ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    {/* Row 4 */}
                    <Grid sx={{ marginTop: '16px' }} item xs={6}>
                        <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl>Bác sĩ thực hiện</LabelControl>
                            <Input value={data.DoctorName || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ marginTop: '16px' }} item xs={6}>
                        <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                            <LabelControl>Buổi</LabelControl>
                            <Input value={data.NoOfProcess || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    {/* Row 5 */}
                    <Grid sx={{ marginTop: '16px' }} item xs={6}>
                        <FormControl sx={{ width: '100%', paddingRight: '12px' }} variant="standard">
                            <LabelControl>Tên phác đồ điệu trị</LabelControl>
                            <Input value={data.TreatmentRegimen || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ marginTop: '16px' }} item xs={6}>
                        <FormControl sx={{ width: '100%', paddingLeft: '12px' }} variant="standard">
                            <LabelControl>Tư vấn thêm</LabelControl>
                            <Input value={data.ConsultingInformation || ''} disabled fullWidth />
                        </FormControl>
                    </Grid>
                    {/* Row 6 */}
                    <Grid sx={{ marginTop: '16px' }} item xs={12}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Ý kiến khách hàng</LabelControl>
                            <HTextArea
                                value={feedback || ''}
                                onChange={changeFeedback}
                                maxRows={3}
                                aria-label="maximum height"
                                placeholder="Ý kiến khách hàng"
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <FlexEndWrapper className="mt-4">
                    <HButton startIcon={<ArrowBackIcon />} btnType="secondary" onClick={() => navigate(-1)}>Quay lại</HButton>
                    <HButton sx={{ marginLeft: '16px' }} startIcon={<CheckIcon />} onClick={handleUpdate}>Cập nhật</HButton>
                </FlexEndWrapper>
            </div>
        </Wrapper>
    )
}