import { Input } from "@components/Input";
import { HSelect } from "@components/Select";
import { HTable } from "@components/Table";
import { ApiConfig } from "@config/index";
import { FormControl, Grid, MenuItem, SvgIcon, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { apiClient } from "@services/baseAxios";
import { COLORS } from "@shared/constants/colors";
import { LabelControl, Title } from "@shared/styledComponents";
import { storeSetAllRole, storeSetSpaLocations } from "@store/directoryReducer";
import { Column, PaginatedResult } from "types/common";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { rowCustomHandlerType } from "@components/Table/constant";
import { ReactComponent as GrayEditIcon } from '@assets/icons/icon-edit-gray.svg';
import { ReactComponent as GrayBucketIcon } from '@assets/icons/icon-bucket-gray.svg';
import { roleNameMapping } from "@shared/constants/common";
import { UserForm } from "./userForm";
import { HDialog } from "@components/Dialog";
import { HButton } from "@components/Button";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';


const AllUserAdministrationWrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    padding: "16px 0px"
}));

const columns: readonly Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    {
        id: 'Fullname',
        label: 'Họ và tên',
        minWidth: 130,
        transform: (data: any) => (<div style={{ fontWeight: 'bold' }}>{data.value}</div>)
    },
    {
        id: 'PhoneNumber',
        label: 'Số điện thoại',
        minWidth: 120,
        align: 'left',
    },
    {
        id: 'Roles',
        label: 'Vai trò',
        minWidth: 100,
        align: 'left',
        transform: (data: any) => (data.value[0] ? roleNameMapping[data.value[0]] : '')
    },
    {
        id: 'LocationName',
        label: 'Cơ sở',
        align: 'left',
        minWidth: 150
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (
            <div className="flex justify-center items-center">
                <Tooltip title="Sửa" placement="left">
                    <div onClick={() => value.onCustomHandler(rowCustomHandlerType.EDIT)} className='flex justify-center items-center cursor-pointer'>
                        <SvgIcon sx={{ width: 16, height: 16, color: COLORS.Green }} component={GrayEditIcon} inheritViewBox />
                    </div>
                </Tooltip>
                <Tooltip className="ml-2" title="Xóa" placement="top">
                    <div onClick={() => value.onCustomHandler(rowCustomHandlerType.DELETE)} className='flex justify-center items-center cursor-pointer'>
                        <SvgIcon sx={{ width: 16, height: 16, color: COLORS.Green }} component={GrayBucketIcon} inheritViewBox />
                    </div>
                </Tooltip>
            </div>
        )
    },
];

export const AllUserAdministration = () => {
    const dispatch = useDispatch();
    const { spaLocations, allRole } = useSelector((state: any) => state.directory);

    const [tableLoading, setTableLoading] = useState(false);
    const [isOpenConfirmDeleteUserDialog, setIsOpenConfirmDeleteUserDialog] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [keyword, setKeyword] = useState('');
    const [userFormTitle, setUserFormTitle] = useState('Sửa thông tin cá nhân người dùng');
    const [isCreateUser, setIsCreateUser] = useState(false);
    const [isOpenUserForm, setIsOpenUserForm] = useState(false);
    const [selectedLocation, setSelectedLocation] = useState("-1");
    const [selectedRole, setSelectedRole] = useState("-1");
    const [selectedRow, setSelectedRow] = useState({} as any);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);

    const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const getSpaLocation = async () => {
        if (spaLocations?.length > 0) return;

        const res = await apiClient.get(ApiConfig.GET_ALL_SPA_LOCATION);
        if (res?.AppCode === 200) {
            dispatch(storeSetSpaLocations(res.Data));
        }
        else {
            dispatch(storeSetSpaLocations([]));
        }
    }

    const handleCloseUserForm = () => {
        setIsOpenUserForm(false);
    }

    const handleSubmitSuccess = () => {
        handleCloseUserForm();
        var msg = isCreateUser ? "Tạo tài khoản thành công" : "Cập nhật thông tin người dùng thành công";
        dispatch(storeOpenSnackbar({ open: true, message: msg, type: "success" }));
        getListUserPaging();
    }

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getAllRole = async () => {
        if (allRole?.length > 0) return;

        const { AppCode, Data } = await apiClient.get(ApiConfig.GET_ALL_ROLE);
        if (AppCode === 200) {
            dispatch(storeSetAllRole(Data));
        }
        else {
            dispatch(storeSetAllRole([]));
        }
    }

    const handleChangeLocation = (event: { target: { value: string } }) => {
        setSelectedLocation(event.target.value);
    };

    const handleChangeRole = (event: { target: { value: string } }) => {
        setSelectedRole(event.target.value);
    };

    const getObjectFilter = () => {
        return {
            Keyword: keyword,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            RoleId: selectedRole === "-1" ? null : selectedRole,
            LocationId: selectedLocation === "-1" ? null : selectedLocation,
        };
    }

    const onRowCustomHandler = (row: any, handlerType: any) => {
        setSelectedRow(row);
        if (handlerType === rowCustomHandlerType.EDIT) {
            setUserFormTitle("Sửa thông tin cá nhân người dùng")
            setIsCreateUser(false);
            setIsOpenUserForm(true);
        }
        else if (handlerType === rowCustomHandlerType.DELETE) {
            setIsOpenConfirmDeleteUserDialog(true);
        }
    }

    const closeConfirmDeleteDialog = () => {
        setIsOpenConfirmDeleteUserDialog(false);
    }

    const deleteUser = async () => {
        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.delete(`${ApiConfig.USER}/${selectedRow.UserId}`);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                closeConfirmDeleteDialog();
                dispatch(storeOpenSnackbar({ open: true, message: "Xóa người dùng thành công", type: "success" }));
                getListUserPaging();
            }
            else if (AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: "Xóa người dùng thất bại", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Xóa người dùng thất bại", type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    const getListUserPaging = async () => {
        try {
            setTableLoading(true);
            var { AppCode, Data } = await apiClient.post(`${ApiConfig.GET_USER_PAGING}`, getObjectFilter());
            setTableLoading(false);
            if (AppCode === 200) {
                setPaginatedData((prevState: PaginatedResult) => ({
                    ...prevState,
                    ...Data
                }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
        catch (e) {
            setTableLoading(false);
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    useEffect(() => {
        getSpaLocation();
        getAllRole();
        // getListUserPaging();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getListUserPaging();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, keyword, selectedLocation, selectedRole]);

    return (
        <AllUserAdministrationWrapper>
            <div className="w-full flex justify-between">
                <Title>Danh sách tất cả người dùng</Title>
                <HButton startIcon={<AddIcon />} onClick={() => {
                    setUserFormTitle("Tạo tài khoản")
                    setIsCreateUser(true);
                    setIsOpenUserForm(true);
                }}>Tạo tài khoản</HButton>
            </div>
            <ToolbarWrapper>
                <Grid container>
                    <Grid sx={{ paddingRight: '8px' }} item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Số điện thoại hoặc tên</LabelControl>
                            <Input onChange={handleChangeKeyword} value={keyword} fullWidth placeholder="Tìm kiếm theo số điện thoại hoặc tên" />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingRight: '8px', paddingLeft: '8px' }} item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Vai trò</LabelControl>
                            <HSelect value={selectedRole}
                                onChange={handleChangeRole}>
                                <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                                {
                                    allRole.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                }
                            </HSelect>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingLeft: '8px' }} item xs={4}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Cơ sở</LabelControl>
                            <HSelect value={selectedLocation}
                                onChange={handleChangeLocation}>
                                <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                                {
                                    spaLocations.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                }
                            </HSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            </ToolbarWrapper>
            <HTable
                keyProperty="UserId"
                columns={columns}
                pagingInfo={{
                    rowsPerPage,
                    page,
                    totalCount: paginatedData.TotalCount,
                    handleChangePage,
                    handleChangeRowsPerPage
                }}
                loading={tableLoading}
                listData={paginatedData.ListOut}
                onRowCustomHandler={onRowCustomHandler}
            ></HTable>
            {/* Form cập nhật người dùng */}
            {
                isOpenUserForm && <UserForm
                    isOpen={isOpenUserForm}
                    isCreateUser={isCreateUser}
                    handleOnClose={handleCloseUserForm}
                    handleOnConfirm={handleSubmitSuccess}
                    userId={selectedRow.UserId}
                    title={userFormTitle}
                />
            }
            {/* Popup confirm xóa user */}
            <HDialog
                PaperProps={{
                    sx: {
                        width: "450px",
                    }
                }}
                title="Xóa người dùng"
                open={isOpenConfirmDeleteUserDialog}
                handleClose={closeConfirmDeleteDialog}
                dialogFooter={
                    <>
                        <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeConfirmDeleteDialog}>Hủy</HButton>
                        <HButton startIcon={<DeleteOutlineIcon />} onClick={deleteUser}>Xóa</HButton>
                    </>
                }
            >
                Bạn chắc chắn muốn xóa người dùng này chứ?
            </HDialog>
        </AllUserAdministrationWrapper >
    )
}