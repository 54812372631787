import { Alert, Snackbar } from "@mui/material";
import React, { useEffect } from "react";
import { selectGlobal } from "@store/globalReducer";
import { useSelector } from "react-redux";

export const GlobalSnackbar = () => {
    const { globalSnackbar } = useSelector(selectGlobal);
    const [open, setOpen] = React.useState(false);
    const vertical = "top", horizontal = "right";

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    useEffect(() => {
        setOpen(globalSnackbar.open);
    }, [globalSnackbar])

    return (
        <Snackbar anchorOrigin={{ vertical, horizontal }} open={open} autoHideDuration={3000} onClose={handleClose}>
            <Alert severity={globalSnackbar.type} onClose={handleClose}>
                {globalSnackbar.message}
            </Alert>
        </Snackbar>
    )
}