import emotionStyled from '@emotion/styled'
import MainContent from '@pages/main/mainContent'

import { COLORS } from "@shared/constants/colors";
export const Main = () => {
    return (
        <MainWrapper>
            <MainContent />
        </MainWrapper>
    );
};

const MainWrapper = emotionStyled.div`
    background-color: ${COLORS.MainBackground};
    width: 100vw;
    height: 100vh;
`;