import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from "@mui/material";
import { styled } from '@mui/material/styles';
import { COLORS } from "@shared/constants/colors";
import { ContentCenterWrapper } from "@shared/styledComponents";
import { Column } from "types/common";
// import { rowCustomHandlerType } from "./constant";

const TableWrapper = styled('div')(() => ({
    flexGrow: 1,
    overflow: 'auto',
}));

export const HTable = ({ columns, pagingInfo, loading, listData, onRowHandler, onRowCustomHandler, keyProperty, ...props }: any) => {
    return (
        <TableWrapper>
            {
                loading ?
                    <ContentCenterWrapper><CircularProgress sx={{ color: COLORS.Primary, height: '100%' }} /></ContentCenterWrapper> :
                    <>
                        <TableContainer sx={{ height: `calc(100% - 52px)`, overflowX: 'auto' }}>
                            <Table
                                stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column: Column) => (
                                            <TableCell
                                                key={column.id}
                                                align={column.align}
                                                style={{ minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {!listData ? <div>"Không có dữ liệu"</div> :
                                        listData.map((row: any, i: number) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={keyProperty ? row[keyProperty] : row.Id}>
                                                    {columns.map((column: any) => {
                                                        const value = row[column.id];
                                                        const index = pagingInfo ? (pagingInfo.page * pagingInfo.rowsPerPage) + i : i;
                                                        return (
                                                            <TableCell key={column.id} align={column.align}>
                                                                {
                                                                    column.transform ? column.transform({
                                                                        value: value,
                                                                        onHandler: () => onRowHandler(row),
                                                                        row: row,
                                                                        onCustomHandler: (handlerType: any) => onRowCustomHandler(row, handlerType),
                                                                        index: index
                                                                    }) : value
                                                                }
                                                            </TableCell>
                                                        );
                                                    })}
                                                </TableRow>
                                            );
                                        })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {
                            pagingInfo &&
                            <TablePagination
                                labelRowsPerPage="Hàng trên trang"
                                rowsPerPageOptions={[10, 25, 100]}
                                component="div"
                                count={pagingInfo.totalCount}
                                rowsPerPage={pagingInfo.rowsPerPage}
                                page={pagingInfo.page}
                                onPageChange={pagingInfo.handleChangePage}
                                onRowsPerPageChange={pagingInfo.handleChangeRowsPerPage}
                            />
                        }
                    </>
            }
        </TableWrapper>

    )
}