import { HAutocomplete } from "@components/Autocomplete";
import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import { LabelControl, Title } from "@shared/styledComponents";
import { User } from "types/user";
import { useEffect, useState } from "react";
import { Box, FormControl } from "@mui/material";
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { useDispatch } from "react-redux";
import { HTable } from "@components/Table";
import { Column } from "types/common";
import { currencyFormat } from "@shared/helpers/common";
import moment from "moment";
import { ColumnAction } from "@components/Table/ColumnAction";
import { ReactComponent as MoneyIcon2 } from '@assets/icons/icon-money-2.svg';
import { ReactComponent as VNDIcon } from '@assets/icons/icon-vnd.svg';
import SvgIcon from '@mui/icons-material/Menu';
import { HDialog } from "@components/Dialog";
import { HButton } from "@components/Button";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { HDatePicker } from "@components/DateTimePicker";
import dayjs, { Dayjs } from "dayjs";
import { HTextField } from "@components/Input/HTextFiled";

const Wrapper = styled('div')(() => ({
    width: '100%',
    padding: '16px',
    borderRadius: '8px',
    backgroundColor: COLORS.White
}));

const RowFlex = styled('div')(() => ({
    display: "flex",
    alignItems: "center",
    marginTop: '8px'
}));

const TableWrapper = styled('div')(() => ({
    width: '100%',
    padding: '16px',
    marginTop: '16px',
    borderRadius: '8px',
    backgroundColor: COLORS.GhostWhite
}));

const columns: readonly Column[] = [
    { id: 'ServiceName', label: 'Tên liệu trình', align: 'left', minWidth: 100 },
    {
        id: 'ActualPaidAmount',
        label: 'Đã thanh toán',
        align: 'right',
        minWidth: 100,
        transform: (data: any) => (data.value ? <span>{currencyFormat(data.value)}đ</span> : '0đ')
    },
    {
        id: 'Price',
        label: 'Giá bán',
        minWidth: 160,
        align: 'right',
        transform: (data: any) => (data.value ? <span>{currencyFormat(data.value)}đ</span> : '0đ')
    },
    {
        id: 'TimeStart',
        label: 'Ngày mua',
        minWidth: 120,
        align: 'center',
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 60,
        transform: (value: any) => (<ColumnAction tooltip="Cập nhật thanh toán" onHandler={value.onHandler}><SvgIcon sx={{ width: 16, height: 16 }} component={MoneyIcon2} inheritViewBox /></ColumnAction>)
    },
];

export const PaymentUpdate = () => {
    const dispatch = useDispatch();
    const [listCustomer, setListCustomer] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState<User | null>(null);
    const [listServiceUser, setListServiceUser] = useState([]);
    const [currentSelectedRow, setCurrentSelectedRow] = useState({ Id: null });
    const [isOpenUpdatePaymentDialog, setIsOpenUpdatePaymentDialog] = useState(false);
    const [paymentDate, setPaymentDate] = useState<Dayjs | null>(null);
    const [moneyInput, setMoneyInput] = useState('');

    const getCustomer = async (phoneNumber: String) => {
        var res = await apiClient.get(`${ApiConfig.GET_CUSTOMER}?phoneNumber=${phoneNumber}`);
        if (res?.AppCode === 200) {
            setListCustomer(res.Data);
        }
        else {
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const searchServiceUser = async (userId: String) => {
        try {
            var res = await apiClient.post(ApiConfig.SEARCH_SERVICE_USER, { UserId: userId });
            if (res?.AppCode === 200) {
                setListServiceUser(res.Data);
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error) {
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const handleOnChange = (e: React.SyntheticEvent, value: any) => {
        setSelectedCustomer(value);
        searchServiceUser(value.UserId);
    }

    const renderOption = (props: any, option: any) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            {option.PhoneNumber} {option.Fullname}
        </Box>
    );

    const closeUpdatePaymentDialog = () => {
        setIsOpenUpdatePaymentDialog(false);
    }

    const savePaymentInfo = async () => {
        const serviceUserId = currentSelectedRow.Id;
        const data = {
            Id: null,
            PaymentDate: paymentDate,
            Amount: parseInt(moneyInput.replace(/\D/g, '')),
            ServicePaymentId: serviceUserId
        }

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.post(ApiConfig.PAYMENT, data);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                dispatch(storeOpenSnackbar({ open: true, message: "Lưu thông tin thanh toán thành công", type: "success" }));
                closeUpdatePaymentDialog();
                searchServiceUser(selectedCustomer!.UserId);
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Lưu thông tin thanh toán thất bại", type: "error" }));
            }
        } catch (error) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const handleTextInputChange = (event: any, field: string) => {
        const value = event.target.value;
        switch (field) {
            case 'money':
                var a = value.replace(/\D/g, "");
                var data = currencyFormat(parseInt(a)).toString();

                setMoneyInput(data);
                break;
        }
    }

    const onHandler = (item: any) => {
        setCurrentSelectedRow(item);
        setIsOpenUpdatePaymentDialog(true);
        setPaymentDate(dayjs(new Date()));
        setMoneyInput('');
    }

    useEffect(() => {
        getCustomer("");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Wrapper>
                <Title className="mb-4">Lưu mới thông tin thanh toán</Title>
                <FormControl sx={{ width: '450px' }} variant="standard">
                    <LabelControl>Khách hàng</LabelControl>
                    <HAutocomplete
                        placeholder="Chọn số điện thoại khách hàng dể tạo lịch hẹn"
                        options={listCustomer}
                        getOptionLabel={(option: any) => `${option.PhoneNumber} ${option.Fullname}`}
                        renderOption={renderOption}
                        onChange={handleOnChange}
                    />
                </FormControl>
                {
                    selectedCustomer &&
                    <div className="mt-4">
                        <RowFlex>
                            <div style={styles.textLeft}>Họ tên khách hàng:</div>
                            <div style={{ color: COLORS.YankeesBlue, fontWeight: '500' }} className="ml-3">{selectedCustomer?.Fullname}</div>
                        </RowFlex>
                        <RowFlex>
                            <div style={styles.textLeft}>Số điện thoại:</div>
                            <div className="ml-3">{selectedCustomer?.PhoneNumber}</div>
                        </RowFlex>
                        <RowFlex>
                            <div style={styles.textLeft}>Hạng khách hàng:</div>
                            <div style={selectedCustomer?.MembershipClass ? { color: COLORS.Tertiary } : {}} className="ml-3">{selectedCustomer?.MembershipClass || "Standard"}</div>
                        </RowFlex>
                    </div>
                }
            </Wrapper>
            {
                selectedCustomer &&
                <>
                    <TableWrapper>
                        <Title className="mb-4">Liệu trình của khách hàng</Title>
                        <HTable
                            columns={columns}
                            // loading={tableLoading}
                            listData={listServiceUser}
                            onRowHandler={onHandler}
                        ></HTable>
                    </TableWrapper>
                    {/* Popup Cập nhật thanh toán */}
                    <HDialog
                        PaperProps={{
                            sx: {
                                width: "450px",
                            }
                        }}
                        title="Cập nhật thanh toán"
                        open={isOpenUpdatePaymentDialog}
                        handleClose={closeUpdatePaymentDialog}
                        dialogFooter={
                            <>
                                <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={closeUpdatePaymentDialog}>Hủy</HButton>
                                <HButton startIcon={<DoneOutlinedIcon />} onClick={savePaymentInfo}>Xác nhận</HButton>
                            </>
                        }
                    >
                        <FormControl fullWidth sx={{ paddingRight: '8px', marginTop: '16px' }} variant="standard">
                            <LabelControl required>Số tiền</LabelControl>
                            <HTextField
                                fullWidth
                                value={moneyInput}
                                onChange={(event: any) => handleTextInputChange(event, 'money')}
                                placeholder="Nhập số tiền thanh toán"
                                iconEnd={<VNDIcon />}
                            />
                        </FormControl>
                        <FormControl sx={{ width: '100%', paddingRight: '8px', marginTop: '16px' }} variant="standard">
                            <LabelControl required>Ngày thanh toán</LabelControl>
                            <HDatePicker
                                value={paymentDate}
                                ampm={false}
                                inputFormat="DD/MM/YYYY"
                                disablePast
                                sx={{ width: '100%' }}
                                onChange={(newValue: any) => {
                                    setPaymentDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </HDialog>
                </>
            }
        </>)
}

const styles = {
    textLeft: {
        width: '125px',
        color: COLORS.Gray,
        fontSize: '14px',
        lineHeight: '20px'
    }
}