import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import { Sidebar } from '@layout/sidebar';
import { AppToolbar } from '@layout/toolbar';
import { SIDE_BAR_COLLAPSE_WIDTH, SIDE_BAR_WIDTH, TOP_BAR_HEIGHT } from '@shared/constants/layout';
import { Outlet } from 'react-router-dom';

interface AppBarProps extends MuiAppBarProps {
    open?: boolean;
}

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
    boxShadow: 'none',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: SIDE_BAR_WIDTH,
        width: `calc(100% - ${SIDE_BAR_WIDTH}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
    ...(!open && {
        width: `calc(100% - ${SIDE_BAR_COLLAPSE_WIDTH}px)`
    })
}));

const MainContentWrapper = styled('div')(() => ({
    height: `calc(100vh - ${TOP_BAR_HEIGHT}px)`,
    flexGrow: 1,
    marginTop: `${TOP_BAR_HEIGHT}px`,
    padding: '16px',
    overflow: 'auto'
}));


export default function MainContent() {
    const [isSidebarOpen, setOpen] = React.useState(true);

    const onToggleSidebar = (value: boolean) => {
        setOpen(value);
    }

    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar onToggleSidebar={onToggleSidebar} />
            <AppBar position="fixed" open={isSidebarOpen}>
                <AppToolbar />
            </AppBar>
            <MainContentWrapper>
                <Outlet />
            </MainContentWrapper>
        </Box>
    );
}