import { COLORS } from "@shared/constants/colors";

export const sharedStyles = {
    contentCenter: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    borderError: {
        borderColor: 'red'
    },
    borderPrimary: {
        border: `1px solid ${COLORS.YankeesBlue}`
    }
}