import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import { COLORS } from '@shared/constants/colors';

interface RootProps {
    btnType?: string;
}

const BootstrapButton = styled(Button, { shouldForwardProp: (prop) => prop !== 'btnType' })<RootProps>
    (({ btnType }) => ({
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 16,
        borderRadius: '6px',
        padding: '6px 12px',
        fontWeight: 'normal',
        ...((btnType === 'primary' || !btnType) && {
            color: COLORS.White,
            backgroundColor: COLORS.YankeesBlue,
            '&:hover': {
                backgroundColor: COLORS.Independence,
                boxShadow: 'none',
            },
            '&:active': {
                backgroundColor: COLORS.YankeesBlue,
            },
            '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
        }),
        ...((btnType === 'secondary') && {
            color: COLORS.Primary,
            backgroundColor: COLORS.White,
            border: `1px solid ${COLORS.OldSilver}`,
            '&:hover': {
                backgroundColor: COLORS.Greyscale,
                boxShadow: 'none',
            },
            '&:active': {
                backgroundColor: COLORS.Gray,
            },
            '&:focus': {
                boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
            },
        }),
    }));

export const HButton = (props: any) => {
    return (
        <BootstrapButton {...props}>
        </BootstrapButton>
    )
}
