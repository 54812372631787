// import { TextareaAutosize } from "@mui/material"
import { COLORS } from "@shared/constants/colors";
import { styled } from '@mui/material/styles';

const StyledTextareaAutosize = styled('textarea', { shouldForwardProp: (prop) => prop !== 'disabled' })(({ disabled, theme }) => ({
    height: '100px',
    borderRadius: 8,
    backgroundColor: theme.palette.mode === 'light' ? COLORS.White : '#2b2b2b',
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '8px 16px',
    borderColor: COLORS.LightSilver,
    '&:hover': {
        borderColor: COLORS.Gray,
    },
    '&:focus': {
        borderColor: COLORS.Gray,
        outline: 'none'
    },
    ...(disabled === true &&
    {
        color: COLORS.Gray,
        backgroundColor: COLORS.Greyscale,
        cursor: 'not-allowed'
    }
    ),
    ...(
        (!disabled && {
            backgroundColor: theme.palette.mode === 'light' ? COLORS.White : '#2b2b2b',
        })
    ),
}));

export const HTextArea = (props: any) => {
    return (
        <StyledTextareaAutosize
            {...props}
        />
    )
}