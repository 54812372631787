import { styled } from '@mui/material/styles';
import { COLORS } from '@shared/constants/colors';
import emotionStyled from '@emotion/styled';

export const Title = styled('div')(() => ({
    fontSize: '18px',
    fontWeight: 'bold',
    color: COLORS.Primary
}));

export const LabelControl = emotionStyled.div((props: any) => ({
    fontSize: '12px',
    fontWeight: '500',
    lineHeight: '14px',
    marginBottom: '4px',
    color: COLORS.Gray,
    ...((props.required) && {
        '&::after': {
            content: '" *"',
            color: "red"
        }
    })
}))

export const ErrorValidation = styled('div')(() => ({
    color: 'red',
    fontSize: '12px',
    marginTop: '4px'
}));

export const FlexEndWrapper = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'flex-end'
}));

export const ContentCenterWrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}));

export const HDialogTitle = styled('div')(() => ({
    fontSize: '20px',
    fontWeight: 'bold',
    lineHeight: '24px'
}));

export const RadioLabel = styled('div')(() => ({
    fontSize: '14px'
}));