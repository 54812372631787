import { HDatePicker } from "@components/DateTimePicker";
import { Input } from "@components/Input";
import { HSelect } from "@components/Select";
import { HTable } from "@components/Table";
import { rowCustomHandlerType } from "@components/Table/constant";
import { FormControl, Grid, MenuItem, SvgIcon, Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import { CUSTOMER_FEEDBACK_DATA, customerFeedback } from "@shared/constants/common";
import { LabelControl, Title } from "@shared/styledComponents";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Column, PaginatedResult } from "types/common";
import { ReactComponent as ExclamationIcon } from '@assets/icons/icon-exclamation.svg';
import { storeOpenSnackbar } from "@store/globalReducer";
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import { errorMessages } from "@shared/constants/messages";
import { ChipStatus } from "@components/ChipStatus";
import { FeedbackDetailForm } from "./feedbackDetailForm";

const Wrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    padding: "16px 0px"
}));

const columns: readonly Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    {
        id: 'CustomerName',
        label: 'Họ và tên',
        minWidth: 130,
        transform: (data: any) => (<div style={{ fontWeight: 'bold' }}>{data.value}</div>)
    },
    {
        id: 'CustomerPhone',
        label: 'Số điện thoại',
        minWidth: 120,
        align: 'left',
    },
    {
        id: 'MembershipClass',
        label: 'Hạng',
        align: 'left',
        minWidth: 120,
        transform: (data: any) => (data.value ? <span style={{ color: COLORS.Tertiary }}>{data.value}</span> : 'Standard')
    },
    {
        id: 'ServiceName',
        label: 'Tên dịch vụ',
        align: 'left',
        minWidth: 200,
    },
    {
        id: 'NoOfProcess',
        label: 'Buổi',
        align: 'right',
        minWidth: 30,
    },
    {
        id: 'CustomerRate',
        label: 'Mức độ hài lòng',
        align: 'left',
        minWidth: 140,
        transform: (data: any) => (data.value ? <ChipStatus type={getChipType(data.value)} text={getChipText(data.value)} /> : '---'),
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (
            <div className="flex justify-center items-center">
                <Tooltip title="Chi tiết" placement="top">
                    <div onClick={() => value.onCustomHandler(rowCustomHandlerType.DETAIL)} className='flex justify-center items-center cursor-pointer'>
                        <SvgIcon sx={{ width: 16, height: 16, color: COLORS.Green }} component={ExclamationIcon} inheritViewBox />
                    </div>
                </Tooltip>
            </div>
        )
    },
];

const getChipType = (data: Number) => {
    switch (data) {
        case customerFeedback.BINH_THUONG:
            return 4;
        case customerFeedback.HAI_LONG:
            return 2;
        case customerFeedback.KHONG_HAI_LONG:
            return 3;
    }
}

const getChipText = (data: Number) => {
    return CUSTOMER_FEEDBACK_DATA.find(x => x.Id === data)?.Name;
}

export const CustomerFeedbackAdministration = () => {
    const dispatch = useDispatch();

    const [keyword, setKeyword] = useState('');
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [toDate, setToDate] = useState<Dayjs | null>(null);
    const [tableLoading, setTableLoading] = useState(false);
    const [selectedRow, setSelectedRow] = useState({} as any);
    const [page, setPage] = useState(0);
    const [selectedCustomerFeedback, setSelectedCustomerFeedback] = useState("-1");
    const [isOpenDetail, setIsOpenDetail] = useState(false);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);

    const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const getObjectFilter = () => {
        var fromDateVNTime = dayjs(fromDate).add(7, 'hours') || null;
        var toDateVNTime = dayjs(toDate).add(7, 'hours') || null;

        return {
            Keyword: keyword,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            StartTime: fromDateVNTime,
            EndTime: toDateVNTime,
            CustomerRate: selectedCustomerFeedback === "-1" ? null : selectedCustomerFeedback
        };
    };

    const getCustomerFeedbackPaging = async () => {
        try {
            setTableLoading(true);
            var { AppCode, Data } = await apiClient.post(ApiConfig.GET_SERVICE_USER_PROCESS_PAGING, getObjectFilter());
            setTableLoading(false);
            if (AppCode === 200) {
                setPaginatedData((prevState: PaginatedResult) => ({
                    ...prevState,
                    ...Data
                }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
        catch (e) {
            setTableLoading(false);
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    const onRowCustomHandler = (row: any, handlerType: any) => {
        setSelectedRow(row);
        if (handlerType === rowCustomHandlerType.DETAIL) {
            setIsOpenDetail(true);
        }
    }

    const handleChangeCustomerFeedback = (event: { target: { value: any } }) => {
        setSelectedCustomerFeedback(event.target.value);
    };

    const handleCloseForm = () => {
        setIsOpenDetail(false);
    }

    useEffect(() => {
        getCustomerFeedbackPaging();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage, keyword, selectedCustomerFeedback]);

    return (
        <Wrapper>
            <Title>Danh sách FeedBack</Title>
            <ToolbarWrapper>
                <Grid container>
                    <Grid sx={{ paddingRight: '8px' }} item xs={3}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Số điện thoại hoặc tên sản phẩm</LabelControl>
                            <Input onChange={handleChangeKeyword} value={keyword} fullWidth placeholder="Tìm kiếm theo số điện thoại hoặc tên sản phẩm" />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingRight: '8px' }} item xs={3}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Từ ngày</LabelControl>
                            <HDatePicker
                                value={fromDate}
                                ampm={false}
                                inputFormat="DD/MM/YYYY"
                                onChange={(newValue: any) => {
                                    setFromDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingRight: '8px' }} item xs={3}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Đến ngày</LabelControl>
                            <HDatePicker
                                value={toDate}
                                placeholder="Từ ngày"
                                inputFormat="DD/MM/YYYY"
                                ampm={false}
                                onChange={(newValue: any) => {
                                    setToDate(newValue);
                                }}>
                            </HDatePicker>
                        </FormControl>
                    </Grid>
                    <Grid item xs={3}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Đánh giá của khách hàng</LabelControl>
                            <HSelect value={selectedCustomerFeedback}
                                onChange={handleChangeCustomerFeedback}>
                                <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                                {
                                    CUSTOMER_FEEDBACK_DATA.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                }
                            </HSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            </ToolbarWrapper>
            <HTable
                columns={columns}
                pagingInfo={{
                    rowsPerPage,
                    page,
                    totalCount: paginatedData.TotalCount,
                    handleChangePage,
                    handleChangeRowsPerPage
                }}
                loading={tableLoading}
                listData={paginatedData.ListOut}
                onRowCustomHandler={onRowCustomHandler}
            ></HTable>
            {
                isOpenDetail &&
                <FeedbackDetailForm
                    isOpen={isOpenDetail}
                    title={"Chi tiết Feedback"}
                    data={selectedRow}
                    handleOnClose={handleCloseForm}
                >
                </FeedbackDetailForm>
            }
        </Wrapper>
    )
}