import { HButton } from "@components/Button"
import { HDialog } from "@components/Dialog"
import { Input } from "@components/Input";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FormControl, Grid, MenuItem } from "@mui/material";
import { LabelControl } from "@shared/styledComponents";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { useDispatch, useSelector } from "react-redux";
import { ApiConfig } from "@config/index";
import { apiClient } from "@services/baseAxios";
import { errorMessages } from "@shared/constants/messages";
import { storeSetAllProductType } from "@store/directoryReducer";
import { HSelect } from "@components/Select";

export const ProductForm = ({ title, isOpen, productData, handleOnClose, handleOnConfirm, isCreateNew }: any) => {
    const dispatch = useDispatch();
    const [product, setProduct] = useState({} as any);
    const { allProductType } = useSelector((state: any) => state.directory);

    const handleTextInputChange = (event: any, field: string) => {
        const value = event.target.value;
        switch (field) {
            case 'name':
                setProduct((prevState: any) => ({
                    ...prevState,
                    ...{ Name: value }
                }));
                break;
            case 'code':
                setProduct((prevState: any) => ({
                    ...prevState,
                    ...{ Code: value }
                }));
                break;
            case 'instruction':
                setProduct((prevState: any) => ({
                    ...prevState,
                    ...{ Instruction: value }
                }));
                break;
        }
    }

    const getAllProductType = async () => {
        if (allProductType?.length > 0) return;

        const { AppCode, Data } = await apiClient.get(`${ApiConfig.PRODUCT_TYPE}/all`);
        if (AppCode === 200) {
            dispatch(storeSetAllProductType(Data));
        }
        else {
            dispatch(storeSetAllProductType([]));
        }
    }

    const handleChangeProductType = (event: { target: { value: string } }) => {
        setProduct((prevState: any) => ({
            ...prevState,
            ...{ ProductTypeId: event.target.value }
        }));
    };

    const handleSubmit = () => {
        createOrUpdateProduct();
    }

    const createOrUpdateProduct = async () => {
        if (!product.Name || !product.Code || !product.ProductTypeId) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vùi lòng nhập đủ thông tin", type: "warning" }));
            return;
        }

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.post(`${ApiConfig.PRODUCT}`, product);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                handleOnConfirm();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else if (error?.response?.data?.AppCode === 400) {
                dispatch(storeOpenSnackbar({ open: true, message: "Thông tin đẩy lên sai định dạng", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    useEffect(() => {
        setProduct(productData || {});
        getAllProductType();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <HDialog
            PaperProps={{
                sx: {
                    width: "450px",
                }
            }}
            title={title}
            open={isOpen}
            handleClose={handleOnClose}
            dialogFooter={
                <>
                    <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={handleOnClose}>Hủy</HButton>
                    {
                        isCreateNew ?
                            <HButton startIcon={<AddIcon />} onClick={handleSubmit}>Tạo sản phẩm</HButton>
                            :
                            <HButton startIcon={<DoneOutlinedIcon />} onClick={handleSubmit}>Lưu</HButton>
                    }
                </>
            }
        >
            <Grid container>
                <Grid sx={{ pt: 0, pl: 0 }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Tên</LabelControl>
                        <Input onChange={(event: any) => handleTextInputChange(event, 'name')} value={product.Name} sx={{ width: '100%', marginTop: '4px' }} placeholder="Nhập tên sản phẩm" />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Mã</LabelControl>
                        <Input onChange={(event: any) => handleTextInputChange(event, 'code')} value={product.Code} sx={{ width: '100%', marginTop: '4px' }} placeholder="Nhập mã sản phẩm" />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Hướng dẫn sử dụng</LabelControl>
                        <Input onChange={(event: any) => handleTextInputChange(event, 'instruction')} value={product.Instruction} sx={{ width: '100%', marginTop: '4px' }} placeholder="Nhập hướng dẫn sẩn phẩm" />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Loại sản phẩm</LabelControl>
                        <HSelect sx={{ marginTop: '4px' }} value={product.ProductTypeId || "-1"}
                            onChange={handleChangeProductType}>
                            <MenuItem key={-1} disabled value="-1">
                                <em>Chọn loại sản phẩm</em>
                            </MenuItem>
                            {
                                allProductType.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                            }
                        </HSelect>
                    </FormControl>
                </Grid>
            </Grid>
        </HDialog>
    )
}