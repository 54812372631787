import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { COLORS } from '@shared/constants/colors';
import { DatePicker } from '@mui/x-date-pickers';

export const HDateTimePicker = ({ value, onChange, ...props }: any) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateTimePicker
                {...props}
                InputProps={{
                    sx:
                    {
                        border: `1px solid ${COLORS.LightSilver}`,
                        borderRadius: '8px',
                        outline: 'none',
                        backgroundColor: COLORS.White
                    }
                }}
                renderInput={(params) => <TextField sx={{
                    '.MuiInputBase-input': {
                        borderRadius: '8px',
                        padding: '8px 16px',
                        '&:hover': {
                            borderColor: COLORS.YankeesBlue
                        },
                        '&:active': {
                            borderColor: COLORS.YankeesBlue,
                        },
                        '&:focus': {
                            borderColor: COLORS.YankeesBlue
                        }
                    },
                }} {...params} />}
                value={value}
                onChange={onChange}
            />
        </LocalizationProvider>
    );
}

export const HDatePicker = ({ value, onChange, ...props }: any) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
                {...props}
                InputProps={{
                    sx:
                    {
                        border: `1px solid ${COLORS.LightSilver}`,
                        borderRadius: '8px',
                        outline: 'none',
                        backgroundColor: COLORS.White
                    }
                }}
                renderInput={(params) => <TextField sx={{
                    '.MuiInputBase-input': {
                        borderRadius: '8px',
                        padding: '8px 16px',
                        '&:hover': {
                            borderColor: COLORS.YankeesBlue
                        },
                        '&:active': {
                            borderColor: COLORS.YankeesBlue,
                        },
                        '&:focus': {
                            borderColor: COLORS.YankeesBlue
                        }
                    },
                }} {...params} />}
                value={value}
                onChange={onChange}
            />
        </LocalizationProvider>
    );
}