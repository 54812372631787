import { HButton } from "@components/Button"
import { HDialog } from "@components/Dialog"
import { Input } from "@components/Input";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { FormControl, Grid } from "@mui/material";
import { LabelControl } from "@shared/styledComponents";
import { useEffect, useState } from "react";
import AddIcon from '@mui/icons-material/Add';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { useDispatch } from "react-redux";
import { ApiConfig } from "@config/index";
import { apiClient } from "@services/baseAxios";
import { errorMessages } from "@shared/constants/messages";

export const ServiceTypeForm = ({ title, isOpen, serviceTypeData, handleOnClose, handleOnConfirm, isCreateNew }: any) => {
    const dispatch = useDispatch();
    const [serviceType, setServiceType] = useState({} as any);

    const handleTextInputChange = (event: any, field: string) => {
        const value = event.target.value;
        switch (field) {
            case 'name':
                setServiceType((prevState: any) => ({
                    ...prevState,
                    ...{ Name: value }
                }));
                break;
            case 'code':
                setServiceType((prevState: any) => ({
                    ...prevState,
                    ...{ Code: value }
                }));
                break;
            case 'description':
                setServiceType((prevState: any) => ({
                    ...prevState,
                    ...{ Description: value }
                }));
                break;
        }
    }

    const handleSubmit = () => {
        createOrUpdateServiceType();
    }

    const createOrUpdateServiceType = async () => {
        if (!serviceType.Name || !serviceType.Code || !serviceType.Description) {
            dispatch(storeOpenSnackbar({ open: true, message: "Vùi lòng nhập đủ thông tin", type: "warning" }));
            return;
        }

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.post(`${ApiConfig.SERVICE}/types`, serviceType);
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                handleOnConfirm();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        } catch (error: any) {
            dispatch(storeSetGlobalLoading(false));
            if (error?.response?.data?.AppCode === 500) {
                dispatch(storeOpenSnackbar({ open: true, message: error?.response?.data?.Message, type: "error" }));
            }
            else if (error?.response?.data?.AppCode === 400) {
                dispatch(storeOpenSnackbar({ open: true, message: "Thông tin đẩy lên sai định dạng", type: "error" }));
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
            }
        }
    }

    useEffect(() => {
        setServiceType(serviceTypeData || {});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <HDialog
            PaperProps={{
                sx: {
                    width: "450px",
                }
            }}
            title={title}
            open={isOpen}
            handleClose={handleOnClose}
            dialogFooter={
                <>
                    <HButton startIcon={<CloseOutlinedIcon />} btnType="secondary" onClick={handleOnClose}>Hủy</HButton>
                    {
                        isCreateNew ?
                            <HButton startIcon={<AddIcon />} onClick={handleSubmit}>Tạo loại dịch vụ</HButton>
                            :
                            <HButton startIcon={<DoneOutlinedIcon />} onClick={handleSubmit}>Lưu</HButton>
                    }
                </>
            }
        >
            <Grid container>
                <Grid sx={{ pt: 0, pl: 0 }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Tên</LabelControl>
                        <Input onChange={(event: any) => handleTextInputChange(event, 'name')} value={serviceType.Name} sx={{ width: '100%', marginTop: '4px' }} placeholder="Nhập tên loại dịch vụ" />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Mã</LabelControl>
                        <Input onChange={(event: any) => handleTextInputChange(event, 'code')} value={serviceType.Code} sx={{ width: '100%', marginTop: '4px' }} placeholder="Nhập mã loại dịch vụ" />
                    </FormControl>
                </Grid>
                <Grid sx={{ pt: 0, pl: 0, marginTop: '16px' }} item xs={12}>
                    <FormControl sx={{ width: '100%' }} variant="standard">
                        <LabelControl required>Mô tả</LabelControl>
                        <Input onChange={(event: any) => handleTextInputChange(event, 'description')} value={serviceType.Description} sx={{ width: '100%', marginTop: '4px' }} placeholder="Nhập mô tả loại dịch vụ" />
                    </FormControl>
                </Grid>
            </Grid>
        </HDialog>
    )
}