import { Input } from "@components/Input";
import { HSelect } from "@components/Select";
import { HTable } from "@components/Table";
import { FormControl, Grid, MenuItem, SvgIcon } from "@mui/material";
import { styled } from "@mui/material/styles";
import { COLORS } from "@shared/constants/colors";
import { SERVICE_USER_STATUS_MAPPING, serviceUserStatus } from "@shared/constants/common";
import { LabelControl, Title } from "@shared/styledComponents";
import { Column, PaginatedResult } from "types/common";
import { useEffect, useState } from "react";
import moment from "moment";
import { ColumnAction } from "@components/Table/ColumnAction";
import { ReactComponent as SwitchIcon } from '@assets/icons/icon-switch.svg';
import { apiClient } from "@services/baseAxios";
import { ApiConfig } from "@config/index";
import { HDialog } from "@components/Dialog";
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DoneOutlinedIcon from '@mui/icons-material/DoneOutlined';
import { HButton } from "@components/Button";
import { storeOpenSnackbar, storeSetGlobalLoading } from "@store/globalReducer";
import { errorMessages } from "@shared/constants/messages";
import { useDispatch } from "react-redux";
import { currencyFormat } from "@shared/helpers/common";
import { ChipStatus } from "@components/ChipStatus";

const TreatmentWrapper = styled('div')(() => ({
    width: '100%',
    height: '100%',
    backgroundColor: COLORS.GhostWhite,
    padding: '16px',
    display: 'flex',
    flexDirection: 'column'
}));

const ToolbarWrapper = styled('div')(() => ({
    padding: "16px 0px"
}));

const serviceUserMenu = [
    {
        Id: serviceUserStatus.DOING.code,
        Name: serviceUserStatus.DOING.name,
    },
    {
        Id: serviceUserStatus.COMPLETED.code,
        Name: serviceUserStatus.COMPLETED.name,
    },
    {
        Id: serviceUserStatus.CANCELED.code,
        Name: serviceUserStatus.CANCELED.name,
    },
    {
        Id: serviceUserStatus.DELETED.code,
        Name: serviceUserStatus.DELETED.name,
    },
]

const serviceUserDialogMenu =
    [
        {
            Id: serviceUserStatus.COMPLETED.code,
            Name: "Hoàn thành dịch vụ",
        },
        {
            Id: serviceUserStatus.CANCELED.code,
            Name: "Hủy dịch vụ",
        },
        {
            Id: serviceUserStatus.DELETED.code,
            Name: "Xóa dịch vụ",
        },
    ];

const getChipType = (status: string) => {
    switch (status) {
        case "Doing":
            return 1;
        case "Completed":
            return 2;
        case "Canceled":
        case "Deleted":
            return 3;
    }
}

const columns: readonly Column[] = [
    {
        id: 'STT',
        label: 'STT',
        align: 'left',
        minWidth: 30,
        transform: (data: any) => (data.index + 1)
    },
    { id: 'BuyerName', label: 'Họ tên khách hàng', align: 'left', minWidth: 150, transform: (data: any) => (<div style={{ fontWeight: 'bold' }}>{data.value}</div>) },
    {
        id: 'BuyerPhoneNumber',
        label: 'Số điện thoại',
        align: 'left',
        minWidth: 120,
    },
    {
        id: 'BuyerMembershipClass',
        label: 'Hạng khách hàng',
        minWidth: 140,
        align: 'left',
        transform: (data: any) => (data.value ? <span style={{ color: COLORS.Tertiary }}>{data.value}</span> : 'Standard')
    },

    {
        id: 'ServiceName',
        label: 'Tên liệu trình',
        minWidth: 150,
        align: 'left',
        transform: (data: any) => (data.value ?? 0)
    },
    {
        id: 'Status',
        label: 'Trạng thái',
        minWidth: 140,
        align: 'left',
        transform: (data: any) => (<ChipStatus type={getChipType(data.value)} text={SERVICE_USER_STATUS_MAPPING[data.value]} />),
    },
    {
        id: 'TimeStart',
        label: 'Ngày mua',
        minWidth: 100,
        align: 'center',
        transform: (data: any) => (data.value ? moment(data.value).format("DD/MM/YYYY") : '--/--/----')
    },
    {
        id: 'ActualPaidAmount',
        label: 'Tiền thanh toán',
        minWidth: 140,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.MagicPotion }}>{currencyFormat(data.value)}đ</span>)
    },
    {
        id: 'Price',
        label: 'Giá dịch vụ',
        minWidth: 100,
        align: 'right',
        transform: (data: any) => (<span style={{ color: COLORS.Eucalyptus }}>{currencyFormat(data.value)}đ</span>)
    },
    {
        id: 'Actions',
        align: 'center',
        label: 'Tác vụ',
        minWidth: 80,
        transform: (value: any) => (<ColumnAction tooltip="Chuyển trạng thái liệu trình" onHandler={value.onHandler}><SvgIcon sx={{ width: 16, height: 16 }} component={SwitchIcon} inheritViewBox /></ColumnAction>)
    },
];

export const TreatmentManagement = () => {
    const dispatch = useDispatch();

    const [keyword, setKeyword] = useState('');
    const [selectedFilterStatus, setSelectedFilterStatus] = useState('-1');
    const [selectedDialogStatus, setSelectedDialogStatus] = useState('-1');
    const [tableLoading, setTableLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [paginatedData, setPaginatedData] = useState<PaginatedResult>({
        ListOut: [],
        TotalCount: 0,
        PageStart: 1,
        PageEnd: 1,
        TotalPage: 0
    } as PaginatedResult);
    const [isOpenDialog, setIsOpenDialog] = useState(false);
    const [selectedRow, setSelectedRow] = useState({} as any);

    const handleChangeKeyword = (event: React.ChangeEvent<HTMLInputElement>) => {
        setKeyword(event.target.value);
    };

    const handleChangeFilterStatus = (event: { target: { value: string } }) => {
        setSelectedFilterStatus(event.target.value);
    };

    const handleChangeDialogStatus = (event: { target: { value: string } }) => {
        setSelectedDialogStatus(event.target.value);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const getObjectFilter = () => {
        return {
            Keyword: keyword,
            PageIndex: page + 1,
            PageSize: rowsPerPage,
            Status: selectedFilterStatus === '-1' ? null : selectedFilterStatus
        };
    }

    const getData = async () => {
        setTableLoading(true);
        const res = await apiClient.post(ApiConfig.GET_SERVICE_USER_PAGING, getObjectFilter());
        setTableLoading(false);
        if (res?.AppCode === 200) {
            setPaginatedData((prevState: PaginatedResult) => ({
                ...prevState,
                ...res.Data
            }));
        }
    }

    const closeDialog = () => {
        setIsOpenDialog(false);
    }

    const onHandler = (item: any) => {
        setSelectedRow(item);
        setIsOpenDialog(true);
    }

    const handleAfterUpdateSucess = () => {
        closeDialog();
        setSelectedDialogStatus("-1");
        getData();
    }

    const updateServiceUserStatus = async () => {
        if (!selectedDialogStatus || selectedDialogStatus === '-1') {
            dispatch(storeOpenSnackbar({ open: true, message: "Vui lòng chọn trạng thái liệu trình", type: "warning" }));
            return;
        }

        try {
            dispatch(storeSetGlobalLoading(true));
            var { AppCode } = await apiClient.put(`${ApiConfig.SERVICE_USER}/${selectedRow.Id}/status`, { Status: selectedDialogStatus });
            dispatch(storeSetGlobalLoading(false));
            if (AppCode === 200) {
                dispatch(storeOpenSnackbar({
                    open: true, message: "Đổi trạng thái liệu trình thành công", type: "success"
                }));
                handleAfterUpdateSucess();
            }
            else {
                dispatch(storeOpenSnackbar({ open: true, message: "Đổi trạng thái liệu trình thất bại, vui lòng thử lại", type: "error" }));
            }
        }
        catch (e) {
            dispatch(storeSetGlobalLoading(false));
            dispatch(storeOpenSnackbar({ open: true, message: errorMessages.SYSTEM_ERROR, type: "error" }));
        }
    }

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFilterStatus, keyword, page, rowsPerPage])

    return (
        <TreatmentWrapper>
            <Title>Quản lý liệu trình</Title>
            <ToolbarWrapper>
                <Grid container>
                    <Grid sx={{ paddingRight: '6px' }} item xs={6}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Số điện thoại</LabelControl>
                            <Input onChange={handleChangeKeyword} value={keyword} fullWidth placeholder="Tìm kiếm theo số điện thoại" />
                        </FormControl>
                    </Grid>
                    <Grid sx={{ paddingLeft: '6px' }} item xs={6}>
                        <FormControl sx={{ width: '100%' }} variant="standard">
                            <LabelControl>Trạng thái</LabelControl>
                            <HSelect value={selectedFilterStatus}
                                onChange={handleChangeFilterStatus}>
                                <MenuItem key={-1} value={"-1"}>Tất cả</MenuItem>
                                {
                                    serviceUserMenu.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                                }
                            </HSelect>
                        </FormControl>
                    </Grid>
                </Grid>
            </ToolbarWrapper>
            <HTable
                columns={columns}
                pagingInfo={{
                    rowsPerPage,
                    page,
                    totalCount: paginatedData.TotalCount || 0,
                    handleChangePage,
                    handleChangeRowsPerPage
                }}
                loading={tableLoading}
                listData={paginatedData.ListOut}
                onRowHandler={onHandler}
            ></HTable>
            {/* Dialog */}
            <HDialog
                PaperProps={{
                    sx: {
                        width: "450px",
                    }
                }}
                title="Chuyển trạng thái liệu trình"
                open={isOpenDialog}
                handleClose={closeDialog}
                dialogFooter={
                    <>
                        <HButton startIcon={< CloseOutlinedIcon />} btnType="secondary" onClick={closeDialog}>Hủy</HButton >
                        <HButton startIcon={<DoneOutlinedIcon />} onClick={updateServiceUserStatus}>Xác nhận</HButton>
                    </>
                }
            >
                <FormControl sx={{ width: '100%', paddingRight: '8px' }} variant="standard">
                    <LabelControl required>Trạng thái liệu trình</LabelControl>
                    <HSelect value={selectedDialogStatus}
                        onChange={handleChangeDialogStatus}>
                        <MenuItem key={-1} disabled value="-1">
                            <em>Chọn trạng thái liệu trình</em>
                        </MenuItem>
                        {
                            serviceUserDialogMenu.map((data: any) => (<MenuItem key={data.Id} value={data.Id}>{data.Name}</MenuItem>))
                        }
                    </HSelect>
                </FormControl>
            </HDialog>
        </TreatmentWrapper>
    )
}