import { Autocomplete, Box, TextField } from "@mui/material";
import { sharedStyles } from "@shared/styles/common";

const defaultGetOptionLabel = (option: any) => option.label || "";
const defaultRenderOption = (props: any, option: any) => (
    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
        {option.label} ({option.code}) +{option.phone}
    </Box>
);

export const HAutocomplete = ({
    noOptionsText = "Không có dữ liệu",
    placeholder,
    onChange,
    onInputChange,
    getOptionLabel = defaultGetOptionLabel,
    renderOption = defaultRenderOption,
    options = [] }: any) => {
    return (
        <Autocomplete
            id="country-select-demo"
            sx={{
                '& .MuiAutocomplete-inputRoot': {
                    padding: '0',
                    borderRadius: '8px'
                },
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                    border: sharedStyles.borderPrimary
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: sharedStyles.borderPrimary
                }
            }}
            noOptionsText={noOptionsText}
            options={options}
            autoHighlight
            getOptionLabel={getOptionLabel}
            renderOption={renderOption}
            onChange={onChange}
            onInputChange={onInputChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={{
                        '& .MuiInputBase-input': {
                            padding: '0'
                        }
                    }}
                    placeholder={placeholder}
                    inputProps={{
                        ...params.inputProps,
                        style: {
                            padding: '0',
                            paddingLeft: '16px',
                            height: '40px',
                        },
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                />
            )}
        />
    );
}
